import moment from 'moment'
// import LobConstants from "../constants/LobConstants";
// import serverCredintials from "../constants/serverCredintials";
import serverCredintials from './serverCredintials'
import masterServices from './services/masterServices'
import Cookies from 'universal-cookie'
// const cookies = new Cookies()
const SERVICE_URL = serverCredintials.BuildURL.getServiceURL()
// const MASTER_SERVICE_URL = serverCredintials.BuildURL.getMasterServiceURL();
// const PORT_SERVICE_URL = serverCredintials.BuildURL.getPortServiceURL();
// const EMAIL_SERVICE = serverCredintials.BuildURL.getEmailServiceEndpoint();
// const SAP_CONNECTION_DETAILS =
//     serverCredintials.BuildURL.getSapServiceEndpointConfig();
const cookies = new Cookies()
const TIMESHEET_UAT_SERVICE_URL = serverCredintials.BuildURL.getTimesheetUatURL()
const TIMESHEET_SERVICE_URL = serverCredintials.BuildURL.getTimesheetServiceURL()
const SEARCH_SERVICE_URL = serverCredintials.BuildURL.getEmployeeServiceURL()
const TIMESHEET_Third_SERVICE_URL = serverCredintials.BuildURL.getTimesheetServicethirdpartyURL()
const TIMESHEET_UAT_URL = serverCredintials.BuildURL.getTimesheetServiceUatthirdpartyURL()
const Net_Suite_Token_URL = serverCredintials.BuildURL.getNetSuiteServiceTokenURL()
const TIMESHEET_Third_SERVICE_URL_Logout =
  serverCredintials.BuildURL.getTimesheetServicethirdpartyURLLogout()
const TIMESHEET_Third_SERVICE_URL_Config =
  serverCredintials.BuildURL.getTimesheetServicethirdpartyURLConfig()
const TIMESHEET_Third_SERVICE_CONTINUE_URL =
  serverCredintials.BuildURL.getTimesheetServicethirdpartycontinueURL()
const TOKEN_URL = serverCredintials.BuildURL.getTokenURL()
const PLACEMENT_URL = serverCredintials.BuildURL.getPlacementURL()
const PLACEMENT_URL_GetPlacement = serverCredintials.BuildURL.getPLACEMENT_URL_GetPlacement()
const Placement_Completed = serverCredintials.BuildURL.getPlacement_Completed()
const Placement_Cancel = serverCredintials.BuildURL.getPlacement_Cancel()

const PLACEMENT_URL_GetProjectByPlacementCode =
  serverCredintials.BuildURL.getPLACEMENT_URL_GetProjectByPlacementCode()
const VERIFY_URL = serverCredintials.BuildURL.getVerifyURL()
const ADDUSER_URL = serverCredintials.BuildURL.getAddUserURL()
const ADDUSERSubmit_URL = serverCredintials.BuildURL.getAddUserSubmitURL()
const CHECKEMAIL_URL = serverCredintials.BuildURL.getCheckEmailURL()
const ONBOARDING_URL = serverCredintials.BuildURL.getOnBoardingURL()
// const getPROSURLSEARCH = serverCredintials.BuildURL.PROS_URL_EMAIL_SEARCH_LOCAL()
const ECDB_URL = serverCredintials.BuildURL.getECDBURL()
const ECDB_User_Search_URL = serverCredintials.BuildURL.getECDBUSERSEARCHURL()
const ECDB_STATUS_URL = serverCredintials.BuildURL.getECDBSTATUSURL()
const TICKETINGTOOLACCESSURL = serverCredintials.BuildURL.getTICKETINGTOOLACCESSURL()
const USER_URL = serverCredintials.BuildURL.getUSERURL()
const VISA_URL = serverCredintials.BuildURL.getVISAURL()
const VISASTATUS_URL = serverCredintials.BuildURL.getVISASTATUSURL()
const PROS_URL = serverCredintials.BuildURL.getPROSURL()
const SearchCandidateURL = serverCredintials.BuildURL.getSearchCandidateURL()
const UpdateSearchCandidateURL = serverCredintials.BuildURL.getUpdateSearchCandidateURL()

let organization = localStorage.getItem('org_id')
let anil = localStorage.getItem('bearer')

const CONFIG_HEADERS = {
  Authorization: localStorage.getItem('Authorization'),
  user_email: localStorage.getItem('user_email'),
}

const CONFIG_HEADERS1 = {
  token: localStorage.getItem('token'),
  user_email: localStorage.getItem('user_email'),
}

export async function getAllEmployeeData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/getallemployees',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function reportViewData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/project_wise_time_management_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function employeereportViewData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/employee_wise_time_management_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function employeeComplianceReport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/get_employee_wise_compliance_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function employee_wise_yearly_compliance_report(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/get_employee_wise_yearly_compliance_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function employee_wise_yearly_compliance_report_total(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/get_employee_wise_yearly_compliance_report_total',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function employee_wise_yearly_compliance_report_week_wise(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/get_employee_wise_yearly_compliance_report_week_wise_format',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function get_all_active_project_by_user_id(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects2/get_all_active_project_by_user_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function projectComplianceReport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/get_project_wise_compliance_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function employeesStatusReport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/get_employees_status_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function employeereportViewDataProject(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/project_wise_employee_timesheet_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

// export async function getEmployeeReports(queryparams?: any) {
//   let CONFIG_HEADERS2 = {
//     Authorization: localStorage.getItem('Authorization'),
//     user_email: localStorage.getItem('user_email'),
//   }
//   let responseData = await masterServices.listCommodityData(
//     TIMESHEET_SERVICE_URL + 'reports/get_employees_report',
//     queryparams ? queryparams : null,
//     CONFIG_HEADERS2
//   )
//   return responseData
// }
export async function getEmployeeReports(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/timesheet_submission_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getProjectReports(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/get_projects_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getProjectReports1(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/project_timesheet_submission_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function employeeSubmissionReports(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/timesheet_submission_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function timeSheeteportViewData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/project_wise_employee_timesheet_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function projectreportViewData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/project_wise_time_management_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function userLogReportData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/get_user_logs_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function projectexportreportViewData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_project_wise_time_management_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function exportAllUserLogs(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_user_logs_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function employeeExportreportViewData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_employee_wise_time_management_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function employeeExportComplianceReport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_employee_wise_compliance_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function export_employee_wise_yearly_compliance_report(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_employee_wise_yearly_compliance_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function export_employee_wise_yearly_compliance_report_week_wise(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL +
      'reports/export_employee_wise_yearly_compliance_report_week_wise_format',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function projectExportComplianceReport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_project_wise_compliance_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function exportEmployeesStatusReport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_employees_status_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function employeeExportQBreportViewData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/get_qb_logs_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function employeeExportQBreportViewReports(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_qb_logs_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function employeeExportreportViewData1(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_project_wise_employee_timesheet_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

// export async function exportEmployeeReports(queryparams?: any) {
//   let CONFIG_HEADERS2 = {
//     Authorization: localStorage.getItem('Authorization'),
//     user_email: localStorage.getItem('user_email'),
//   }
//   let responseData = await masterServices.listCommodityData(
//     TIMESHEET_SERVICE_URL + 'reports/export_employees_report',
//     queryparams ? queryparams : null,
//     CONFIG_HEADERS2
//   )
//   return responseData
// }
export async function exportEmployeeReports(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_timesheet_submission_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function exportProject1Reports(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_project_timesheet_submission_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function timeSheetSubmissionData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_timesheet_submission_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function timeSheetExportreportViewData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_project_wise_employee_timesheet_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function allUsersExport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/export_all_users',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function allBusinessUnitExport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'business_units/export_all_business_units',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function allBranchesExport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'branches/export_all_branches',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function allDepartmentsExport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'departments/export_all_departments',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function allProjectExport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects2/export_all_projects',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function allCustomerExport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'customers2/export_all_customers',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function allConsultantExport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/export_all_employees',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
// export async function employeeWiseProject(queryparams?: any) {
//   let CONFIG_HEADERS2 = {
//     Authorization: localStorage.getItem('Authorization'),
//     user_email: localStorage.getItem('user_email'),
//   }
//   let responseData = await masterServices.listCommodityData(
//     TIMESHEET_SERVICE_URL + 'reports/employee_wise_projects_time_management_report',
//     queryparams ? queryparams : null,
//     CONFIG_HEADERS2
//   )
//   return responseData
// }
export async function employeeWiseProject(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'reports/export_timesheet_submission_report',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function refreshToken(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'token/refreshToken',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function timeSheetDataList(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_all_timesheets_by_user',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function employee_leaves_by_user_id(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_employee_leaves_by_user_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function forgotPassword(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'index/check_user_by_email',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getFilterUser(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/getalluser',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function get_all_business_units(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'business_units/get_all_business_units',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function get_all_branches(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'branches/get_all_branches',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function get_all_departments(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'departments/get_all_departments',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getAllMobileForceUpdate(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'mobile/get_all_mobile_force_update',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getQuickbookConfig(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'quickbook/get_quickbook_config',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function qbSettings(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'index/savetoken',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function updateQuickbookConfig(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'quickbook/update_quickbook_config',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getallAnnoucements(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'annoucements/getallannoucements',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getemailconfigbyOrgid(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'emailconfig/getemailconfig_by_orgid',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function QBTransactionNames(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'organizations/get_qb_transaction_names',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function QBTransactionsSetting(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'organizations/get_qb_transactions_setting',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getQBTransactionsSettingByName(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'organizations/get_qb_transactions_setting_by_name',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getLatestAnnouncement(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'annoucements/get_latest_announcement ',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getTopLatestAnnouncement(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'annoucements/get_latest_announcement_by_user',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getAllUserData1(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/getallemployees',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function get_business_units_list(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'business_units/get_business_units_list',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function update_classification_to_netSuite(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'business_units/update_classification_to_netsuite',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function update_department_to_netSuite(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'departments/update_department_to_netsuite',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_relation_managers_list(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/get_relation_managers_list_by_org_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function get_branches_list(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'branches/get_branches_list',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function get_departments_list(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'departments/get_departments_list',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function get_approvers_list_by_business_unit_id(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/get_approvers_list_by_business_unit_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getHoursOfTimesheet(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/gethouroftimesheetstatusbyweek',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

/******bharat */
export async function getWeekDays(queryparams?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'index/weeks',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getMonths(queryparams?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'index/monthnames',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
// export async function getTotalHours(queryparams?: any) {
//   const CONFIG_HEADERS2 = {
//     Authorization: localStorage.getItem('Authorization'),
//     user_email: localStorage.getItem('user_email'),
//   }
//   let responseData = await masterServices.listCommodityData(
//     TIMESHEET_SERVICE_URL + 'timeactivity/gethourofapprovertimesheetstatus',
//     queryparams ? queryparams : null,
//     CONFIG_HEADERS2
//   )
//   return responseData
// }
export async function getTotalHours(queryparams?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_status_wise_total_hours_by_month',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getWeekDaysSingleDate(queryparams?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'index/weekdates',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function allApproverWeekandStatus(queryparams?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/getallapprovertimeactivitybyweekandstatus',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getEmployeeProjectList(queryparams?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_single_week_timesheet',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getSingleWeekTimesheet(queryparams?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_single_week_timesheet',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getCopyLastWeekHoursData(queryparams?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_copy_last_week_hours_data',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function weekWiseData(queryparams?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/getalltimeactivitybyweek',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getDataBySelectedDate(queryparams?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/getalltimeactivitybydate',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
/******bharat xxxxxxxxxxxxxxxxxxxxxxxx */

export async function getFilterProject(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects2/get_all_projects',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function customerNetSuite(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    Net_Suite_Token_URL + '/customer',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function projectNetSuite(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    Net_Suite_Token_URL + '/project',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function serviceitemNetSuite(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    Net_Suite_Token_URL + '/serviceitem',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function employeeNetSuite(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    Net_Suite_Token_URL + '/employee/byName',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function invoiceNetSuite(invoice: any, queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    Net_Suite_Token_URL + '/invoice' + `/${invoice}`,
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function get_consultants_mapped_to_relation_managers(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/get_consultants_mapped_to_relation_managers',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getOrganizationsList(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'organizations/get_organizations_list',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function get_all_employee_document_types(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'employee_document_types/get_all_employee_document_types',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function get_employee_document_types_by_id(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'employee_document_types/get_employee_document_types_by_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function get_employee_document_types_list(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'employee_document_types/get_employee_document_types_list',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function insertEmployeeDocumentType(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'employee_document_types/insert_employee_document_type',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function insert_employee_document(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'employee_documents/insert_employee_document',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getEmployeeDocumentsbyid(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'employee_documents/get_employee_documents_by_user_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function deleteEmployeeDocId(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'employee_documents/delete_employee_document_by_id',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function update_employee_document_types(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'employee_document_types/update_employee_document_types',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function delete_employee_document_type(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'employee_document_types/delete_employee_document_type',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getDefaultOrgAsCustomer(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'customers2/get_default_org_as_customer',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getPlacementUrl(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    PLACEMENT_URL_GetPlacement,
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getPlacementCompleted(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    Placement_Completed,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getPlacementCancel(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    Placement_Cancel,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function getProjectByPlacementCode(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    PLACEMENT_URL_GetProjectByPlacementCode,
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

// export async function timeSheetListData(queryparams?: any) {
//     const CONFIG_HEADERS2 = {
//         Authorization: localStorage.getItem("Authorization"),
//         user_email: localStorage.getItem("user_email"),
//     };
//     let responseData = await masterServices.listCommodityData(
//         TIMESHEET_SERVICE_URL + "timeactivity/getalltimeactivityweekwise",
//         queryparams ? queryparams : null,
//         CONFIG_HEADERS2
//     )
//     return responseData;
// }

export async function getAllClientsData(queryparams?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'customers2/get_all_customers',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
// export async function getFilterEmployee(queryparams?: any) {
//     let CONFIG_HEADERS2 = {
//         Authorization: localStorage.getItem('Authorization'),
//         user_email: localStorage.getItem('user_email'),
//     }
//     let responseData = await masterServices.listCommodityData(
//         TIMESHEET_SERVICE_URL + 'employees/filteremployee',
//         queryparams ? queryparams : null,
//         CONFIG_HEADERS2
//     )
//     return responseData
// }

export async function getFilterClient(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'customers2/get_all_customers',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getFilterTimesheet(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_all_timesheets_by_user',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getcustomerdatabyname(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'quickbook/getcustomerdatabyname',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
// export async function getcustomerdatabyname1(queryparams?: any) {
//   let CONFIG_HEADERS2 = {
//     Authorization: localStorage.getItem('Authorization'),
//     user_email: localStorage.getItem('user_email'),
//   }
//   let responseData = await masterServices.listCommodityData(
//     TIMESHEET_SERVICE_URL + 'quickbook/getemployeedatabyname',
//     queryparams ? queryparams : null,
//     CONFIG_HEADERS2
//   )
//   return responseData
// }
export async function getcustomerdatabyname1(business: any, postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'quickbook/getemployeedatabyname?business_unit_id' + `=${business}`,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getvendordatabyname1(business: any, postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'quickbook/getvendordatabyname?business_unit_id' + `=${business}`,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getNetSuiteConfig(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'netsuite/get_netsuite_config',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getSubsidaryNetSuite(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'netsuite/get_netsuite_subsidary',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getEmployee(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    Net_Suite_Token_URL + '/employee',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getCustomer(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    Net_Suite_Token_URL + '/customer',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function classificationNetSuite(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    Net_Suite_Token_URL + '/classification',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function subsidaryNetSuite(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    Net_Suite_Token_URL + '/classification',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

// export async function departmentNetSuite(postData?: any) {
//   let CONFIG_HEADERS2 = {
//     Authorization: localStorage.getItem('NetSuiteToken'),
//     user_email: localStorage.getItem('user_email'),
//   }
//   return await masterServices.postMasterData(
//     Net_Suite_Token_URL + '/department',
//     postData ? postData : null,
//     CONFIG_HEADERS2
//   )
// }
export async function departmentNetSuite(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    Net_Suite_Token_URL + '/department',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

// http://localhost:8080/serviceitem

export async function getService(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    Net_Suite_Token_URL + '/serviceitem',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getProject(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    Net_Suite_Token_URL + '/project',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getNetSuiteToken(postData?: any) {
  const CONFIG_HEADERS2 = {
    // Authorization: localStorage.getItem('Authorization'),
    // user_email: localStorage.getItem('user_email'),
  }

  let responseData = await masterServices.postMasterData(
    Net_Suite_Token_URL + '/user/token',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function updateNetSuite(employee_id: any, postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    // user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.patchMasterData(
    Net_Suite_Token_URL + '/employee' + `/${employee_id}`,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateNetSuiteCustomer(customer_id: any, postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    // user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.patchMasterData(
    Net_Suite_Token_URL + '/customer' + `/${customer_id}`,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateNetSuiteProject(project_id: any, postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('NetSuiteToken'),
    // user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.patchMasterData(
    Net_Suite_Token_URL + '/project' + `/${project_id}`,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateOrganizationNetSuiteSetting(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'organizations/update_organization_netsuite_setting',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function NetSuiteMapProjectEmployee(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'projects2/map_project_and_employee',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getclientbyname(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'client/getclientbyname',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getcustomerbyname(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects/getprojectsbyname',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getconsultantbyname(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/get_users_list',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getAllEmployesData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/get_employees_list',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getAllClientNameData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'customers2/get_all_customers_list',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getAllProjectNameData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects2/get_all_projects_list',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getQbbyname(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'quickbook/get_qb_logs_api_names',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getprojectbyname(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects2/get_projects_list',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function get_overall_active_projects_count_in_selected_months(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_overall_active_projects_count_in_selected_months',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function getCustomersList(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'customers2/get_customers_list',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getcountriesbyname(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'countrystatecity/countries_by_name',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getstatebyname(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'countrystatecity/get_states_by_country_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getcitybyname(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'countrystatecity/get_cities_by_state_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

// /countrystatecity/get_cities_by_state_id

export async function getcountriesbyname1(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'countrystatecity/countries_by_name',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getitemdatabyname(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'quickbook/getitemdatabyname',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getSearchEmployee(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }

  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/filteremployees',

    queryparams ? queryparams : null,

    CONFIG_HEADERS2
  )

  return responseData
}
export async function getSearchEmployeeConsultant(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }

  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/getallemployees',

    queryparams ? queryparams : null,

    CONFIG_HEADERS2
  )

  return responseData
}

export async function getSearchEmployee1(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }

  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/filteremployees',

    queryparams ? queryparams : null,

    CONFIG_HEADERS2
  )

  return responseData
}
export async function getVerifyEmail(queryparams?: any) {
  const CONFIG_HEADERS3 = {
    Authorization: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }
  let anil = localStorage.getItem('logged_in_id')

  let responseData = await masterServices.listCommodityData(
    VERIFY_URL + `${anil}`,

    queryparams ? queryparams : null,

    CONFIG_HEADERS3
  )

  return responseData
}
export async function getAuthUri(queryparams?: any) {
  const CONFIG_HEADERS3 = {
    token: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }

  let responseData = await masterServices.listCommodityData(
    // TIMESHEET_SERVICE_URL + `${anil}`,
    TIMESHEET_SERVICE_URL + 'index/authUri',

    queryparams ? queryparams : null,

    CONFIG_HEADERS3
  )

  return responseData
}

export async function getemployeebyid(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/getuserbyid',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function getemployeebyemail(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/getuserbyemail',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function getemployeebyemailId(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/getuserbyemailexcludingid',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
// export async function manageUserData(queryparams?: any) {
//   let CONFIG_HEADERS2 = {
//     Authorization: localStorage.getItem('Authorization'),
//     user_email: localStorage.getItem('user_email'),
//   }
//   return await masterServices.listCommodityData(
//     TIMESHEET_SERVICE_URL + 'index/getUserData',
//     queryparams ? queryparams : null,
//     CONFIG_HEADERS2
//   )
// }

export async function manageUserData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData2(
    TIMESHEET_SERVICE_URL + 'index/getUserData',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function syncBusinessUnits(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    // Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData2(
    TIMESHEET_SERVICE_URL + 'index/syncBusinessUnits',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function syncBranches(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData2(
    TIMESHEET_SERVICE_URL + 'index/syncBranches',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function syncDepartments(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData2(
    TIMESHEET_SERVICE_URL + 'index/syncDepartments',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function projectUserData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData2(
    TIMESHEET_SERVICE_URL + 'index/getProjectsData',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function consultantUpdateTime(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'synclogs/get_sync_logs_by_sync_type',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function projectUpdateTime(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'synclogs/get_sync_logs_by_sync_type',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function refreshTokenapi(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'index/refreshToken',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

// http://192.168.92.54/api/v1/synclogs/get_sync_logs_by_sync_type

// export async function projectUserData(queryparams?: any) {
//   let CONFIG_HEADERS2 = {
//     Authorization: localStorage.getItem('Authorization'),
//     user_email: localStorage.getItem('user_email'),
//   }
//   return await masterServices.listCommodityData(
//     TIMESHEET_SERVICE_URL + 'index/getProjectsData',
//     queryparams ? queryparams : null,
//     CONFIG_HEADERS2
//   )
// }
export async function projectUserData1(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects2/sync_projects_by_user_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function getprojectbyid(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects2/get_project_by_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function consultantsMappedtorelationManagersExport(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/export_all_employees_mapped_to_relation_managers',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function insertRmComment(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'rm_comments/insert_rm_comment',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateComment(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'rm_comments/update_rm_comment_by_id',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getHistoryCommentsbyUserid(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'rm_comments/get_rm_comments_by_user_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function getHistoryCommentsbyCommentid(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'rm_comments/get_rm_comments_by_comment_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function getMobileForceUpdateAccessbyEmail(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'mobile/get_mobile_force_update_access_by_email',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function getmobileforceupdatebyid(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'mobile/get_mobile_force_update_by_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function getannoucementbyid(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'annoucements/get_annoucement_by_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function getuserbyid(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/getuserbyid',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function getclientbyid(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'customers2/get_customer_by_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function pendingTimeSheet1(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_status_wise_emp_timesheet_by_month',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function pendingWeekWiseTimeSheet1(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_status_wise_weekly_emp_timesheet_by_week',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function pendingWeekWiseTimeSheet2(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_status_wise_project_timesheet_by_week',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function getTotalHoursByWeek(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_status_wise_total_hours_by_week',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function monthViewOpen(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_status_wise_monthly_emp_timesheet_by_month',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function monthViewOpenProject(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_status_wise_monthly_project_timesheet_by_month',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function pendingTimeSheet2(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_status_wise_project_timesheet_by_month',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function projectWiseData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_status_wise_project_timesheet_by_month',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function projectDataView(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/getapprovertimesheetsbyprojectname',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

// export async function editTimesheetData(queryparams?: any) {
//   let CONFIG_HEADERS2 = {
//     Authorization: localStorage.getItem('Authorization'),
//     user_email: localStorage.getItem('user_email'),
//   }
//   return await masterServices.listCommodityData(
//     TIMESHEET_SERVICE_URL + 'timeactivity/getapprovertimesheetsbyempname',
//     queryparams ? queryparams : null,
//     CONFIG_HEADERS2
//   )
// }
export async function editTimesheetData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_single_week_approver_timesheet',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function approverTimeweekEmpName(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/getapprovertimesheetweekbyempname',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function timesheetHistory(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_timesheet_history',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function timesheetHistoryPerDay(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_per_day_timesheet_history',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function approverTimesheethistoryperday(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/getapprovertimesheethistoryperday',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function approverTimesheetstatusperday(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/getapprovertimesheetstatusperday',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function timesheetHistoryperday(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/gettimesheethistoryperday',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function timesheetStatusperday(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/gettimesheetstatusperday',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function approverTimesheethistory(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/getapprovertimesheethistorybyweek',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function prosLocalEmail(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/get_user_by_prospective_user_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function prosLocalEmailUpdate(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/get_user_by_prospective_user_id_excluding_id',
    queryparams,
    CONFIG_HEADERS2
  )
}

export async function ECDBLocalEmail(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/get_user_by_ecdb_user_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function ECDBLocalUpdateEmail(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/get_user_by_ecdb_user_id_excluding_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function insertEmployee(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'user2/insertemployee',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function addUserPost(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    ADDUSER_URL,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function addUserSubmitPost(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    ADDUSERSubmit_URL,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function getTicketingToolAccess(queryparams?: any) {
  const CONFIG_HEADERS3 = {
    Authorization: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }

  let responseData = await masterServices.listCommodityData(
    TICKETINGTOOLACCESSURL,
    queryparams ? queryparams : null,
    CONFIG_HEADERS3
  )
  return responseData
}

export async function checkEmail(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    CHECKEMAIL_URL,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function validateEmployee(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    ONBOARDING_URL + '/' + queryparams.orgId,
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function validateEmployee1(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    ONBOARDING_URL + '/' + queryparams.orgId,
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function adpAssociateIdData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    // ONBOARDING_URL + '/' + queryparams.orgId,
    TIMESHEET_SERVICE_URL + 'user2/get_adpworkerid_details',

    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function adpAssociateIdDataCheck(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    // ONBOARDING_URL + '/' + queryparams.orgId,
    TIMESHEET_SERVICE_URL + 'user2/get_user_by_adp_associate_id',

    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function adpAssociateIdDataUpdateCheck(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    // ONBOARDING_URL + '/' + queryparams.orgId,
    TIMESHEET_SERVICE_URL + 'user2/get_user_by_adp_associate_id_excluding_id',

    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function employeeId(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'user2/get_new_employee_id',

    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function timeSheetInsert(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timeactivity/inserttimeactivity',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function allTimeSheetInsert(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/approve_project_wise_timesheet',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function revertProjectWiseTimeSheet(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/revert_project_wise_timesheet',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function revertProjectWiseSingleDay(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/revert_project_wise_single_day_timesheet',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function rejectProjectWiseSingleDay(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/reject_project_wise_single_day_timesheet',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function rejectProjectWiseTimeSheet(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/reject_project_wise_timesheet',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function allTimeSheetInsertSingle(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/approve_project_wise_single_day_timesheet',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function allApproverTimeSheetData(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/approve_all_project_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function allApproverMonthlyTimeSheetData(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/approve_monthly_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function allApproverMonthlyTimeSheetDataProject(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/approve_project_wise_monthly_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function allRejectMonthlyTimeSheetData(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/reject_monthly_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function allRevertMonthlyTimeSheetData(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/revert_monthly_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function allRejectMonthlyTimeSheetDataProject(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/reject_project_wise_monthly_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function allRevertMonthlyTimeSheetDataProject1(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/revert_project_wise_monthly_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function rejectAllProjectTimesheets(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/reject_all_project_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function revertAllProjectTimesheets(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/revert_all_project_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function timeSheetSaveInsert(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/save_project_wise_timesheet',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function copyTimesheetDocs(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheet_documents/copy_timesheet_docs',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function timeSheetSaveAllInsert(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/save_all_project_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function timeSheetSingleProjectSubmit(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/submit_project_wise_timesheet',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function timeSheetSubmitAllProject(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/submit_all_project_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function timeSheetUnSubmitSingleProject(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/unsubmit_project_wise_timesheet',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function timeSheetUnSubmitAllProject(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/unsubmit_all_project_timesheets',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function allStatusEmails(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timeactivity/sendstatusemail',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function quickBooks(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/send_project_wise_timesheet_to_qb',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function netSuite(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/send_project_wise_timesheet_to_netsuite',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function netSuiteUpdate(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/update_project_wise_timesheet_to_netsuite',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function quickBooksDayLevel(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/send_project_wise_day_timesheet_to_qb',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function quickBooksProjectWiseMonthly(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/send_project_wise_monthly_timesheets_to_qb',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function approverCommentsTextBox(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'fileupload/inserttimedoc',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function insertDocument(postData?: any) {
  const CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheet_documents/inserttimedoc',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function saveTokenDetails(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'index/savetoken',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function insertProject(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'projects2/insert_project',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_invoices_history(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/export_invoices_history',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_timesheet_entries_to_create_invoice(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_timesheet_entries_to_create_invoice',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_timesheet_entries_to_create_netsuite_invoice(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_timesheet_entries_to_create_netsuite_invoice',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function create_invoice_project_wise_timesheet(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/create_invoice_project_wise_timesheet',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function create_NetSuite_invoice_project_wise_timesheet(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/send_project_wise_invoice_to_netsuite',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_invoice_details_by_invoice_id(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_invoice_details_by_invoice_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function downloadInvoice(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'quickbook/downloadInvoice',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function getProjectWiseCreatedHistory(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_latest_project_wise_created_invoices_history',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getProjectWiseNetSuiteCreatedHistory(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_netsuite_invoice_by_project_id',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function insertComplianceComment(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'compliance_comments/insert_compliance_comment',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getComplianceComments(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'compliance_comments/get_compliance_comments_by_user_id_month_and_year',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function get_bench_project_based_on_start_date(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'projects2/get_bench_project_based_on_start_date',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function update_C2C_user_project_status(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'projects2/update_C2C_user_project_status',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_project_wise_yearly_non_compliance_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_project_wise_yearly_non_compliance_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function get_project_wise_yearly_non_compliance_report_total(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_project_wise_yearly_non_compliance_report_total',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function get_yearly_active_employees_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_yearly_active_employees_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_yearly_active_projects_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_yearly_active_projects_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function get_active_employees_report(employee_id: any, postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_active_employees_report?response_type=' + `${employee_id}`,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_active_projects_report(employee_id: any, postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_active_projects_report?response_type=' + `${employee_id}`,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_active_employees_report(employee_id: any, postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL +
      'reports/export_active_employees_report?response_type=' +
      `${employee_id}`,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_active_projects_report(employee_id: any, postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL +
      'reports/export_active_projects_report?response_type=' +
      `${employee_id}`,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_yearly_active_employees_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_yearly_active_employees_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_yearly_active_projects_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_yearly_active_projects_report ',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_yearly_revenue_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_year_selection_revenue_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_yearly_utilization_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_yearly_utilization_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_yearly_cogs_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_year_selection_cogs_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_yearly_margin_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_year_selection_margin_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_project_wise_revenue_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_project_wise_revenue_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_project_wise_unbilled_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_project_wise_unbilled_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_project_wise_unbilled_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_project_wise_unbilled_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_project_wise_utilization_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_project_wise_utilization_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_location_wise_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_location_wise_revenue_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_location_wise_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_location_wise_revenue_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_project_wise_cogs_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_project_wise_cogs_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_project_wise_margin_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/get_project_wise_margin_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_project_wise_revenue_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_project_wise_revenue_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_project_wise_utilization_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_project_wise_utilization_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_project_wise_cogs_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_project_wise_cogs_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_project_wise_margin_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_project_wise_margin_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_yearly_revenue_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_year_selection_revenue_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_yearly_utilization_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_yearly_utilization_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_yearly_cogs_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_year_selection_cogs_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function export_yearly_margin_report(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_year_selection_margin_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function ExportProjectWiseYearlyNonComplianceReport(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'reports/export_project_wise_yearly_non_compliance_report',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function applyLeavesHolidays(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'timesheets/apply_leaves_holidays',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function insertMobileForceUpdate(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'mobile/insert_mobile_force_update',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function insertAnnoucement(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'annoucements/insert_annoucement',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function markAsReadAnnouncement(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'annoucements/mark_as_read',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateEmailConfig(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'emailconfig/update_email_config',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateGlobalConfig(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'global_config/update_global_config',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function updateOrganizationSetting(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'organizations/edit_organization_setting',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateOrganizationMainQBSetting(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'organizations/update_organization_main_qb_setting',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function getOrganizationSetting(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'organizations/get_organization_by_org_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getQBAuthUri(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'qbauth/getAuthUri',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getQBCallBack(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'qbauth/callback',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function getSearchCandidate(employee_id: any, queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    SearchCandidateURL + `/${employee_id}`,
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getUpdateSearchCandidate(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let orgid = localStorage.getItem('logged_in_id')

  let responseData = await masterServices.listCommodityData(
    // UpdateSearchCandidateURL + `/${orgid}`,
    TIMESHEET_SERVICE_URL + 'user2/search_users',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getGlobalConfigData(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'global_config/get_global_config',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function updateEmployee(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'user2/editemployee',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateProjectNames(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'projects2/update_project_names',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function updateOrganizationAdpsetting(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'organizations/update_organization_adp_setting',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateOrganizationQBSetting(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'organizations/update_organization_qb_setting',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function qbTransastion(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'organizations/get_qb_transactions_setting_by_name',

    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function updateUserEmpId(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects2/get_projects_by_user_id',

    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function ecdbUserStatusUpdate(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'api/userECDB/editUserStatus',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateUser(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(ECDB_URL, postData ? postData : null, CONFIG_HEADERS2)
}

export async function ECDB_UserSearch_URL(employee_id: any, queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    ECDB_User_Search_URL + `/${employee_id}`,
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function updateUserStatusData(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    ECDB_STATUS_URL,
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateUserStatus(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(USER_URL, postData ? postData : null, CONFIG_HEADERS2)
}

export async function updateVisaStatus(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    VISA_URL,
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function getallcountries(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'countrystatecity/getallcountries ',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function updateVisaStatusData(visaStaeId: any, queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    // VISASTATUS_URL + `${CountryId}`,
    VISASTATUS_URL + `${visaStaeId}`,
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}
export async function updateUserPros(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(PROS_URL, postData ? postData : null, CONFIG_HEADERS2)
}

export async function updateProject(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'projects2/edit_project',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function otProductProject(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'projects2/set_ot_product',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function editmobileforceupdate(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'mobile/edit_mobile_force_update',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateAnnoucement(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'annoucements/update_annoucement',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function get_all_monthly_hours_settings(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'monthly_hours_setting/get_all_monthly_hours_settings',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function insert_monthly_hours_setting(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'monthly_hours_setting/insert_monthly_hours_setting',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function reset_yearly_monthly_hours_setting(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'monthly_hours_setting/reset_yearly_monthly_hours_setting',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function updateOrganizationWorkLocationSetting(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'organizations/update_organization_work_location_setting',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function update_organization_hours_setting(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'organizations/update_organization_hours_setting',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function update_organization_email_setting(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'organizations/update_organization_email_setting',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function update_maintenance_config(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'maintenance_config/update_maintenance_config',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function EmailRemainderSetting(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'organizations/update_organization_weekly_reminder_setting',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}
export async function deleteAnnoucement(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'annoucements/delete_annoucement',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function AnnouncementStatusUpdate(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'annoucements/inactive_annoucement',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function deleteMobileForceUpdate(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.postMasterData(
    TIMESHEET_SERVICE_URL + 'mobile/delete_mobile_force_update',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function deleteDocId(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'timesheet_documents/delete_timesheet_document',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function updateClient(postData?: any, isEdit?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.putMasterData(
    TIMESHEET_SERVICE_URL + 'customers2/edit_customer',
    postData ? postData : null,
    CONFIG_HEADERS2
  )
}

export async function docNameIdProjectList(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheet_documents/get_timesheet_docs_by_week_and_project',
    queryparams,
    CONFIG_HEADERS2
  )
}
export async function docNameIdProjectList1(queryparams: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheet_documents/get_timesheet_documents_by_week',
    queryparams,
    CONFIG_HEADERS2
  )
}

// export async function dateOneWeek(queryparams?: any) {
//     let CONFIG_HEADERS2 = {
//         Authorization: localStorage.getItem("Authorization"),
//         user_email: localStorage.getItem("user_email"),
//     };
//     return await masterServices.listCommodityData(
//         TIMESHEET_SERVICE_URL + "timeactivity/getallapprovertimeactivitybyweekandstatus",
//         queryparams ? queryparams : null,
//         CONFIG_HEADERS2
//     );
// }

export async function dateOneWeek(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/getallapprovertimeactivitybyweekandstatus',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function getUserToken(postedData?: any) {
  const CONFIG_HEADERS3 = {
    token: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }
  let config = {
    user_email: postedData.user_email,
  }
  let responseData = await masterServices.getUserTokenByUserName(
    TIMESHEET_Third_SERVICE_URL,
    postedData,
    CONFIG_HEADERS3
  )

  return responseData
}

export async function getSSO(queryparams?: any) {
  var Token
  if (cookies.get('JWT-TOkEN')) {
    Token = cookies.get('JWT-TOkEN')
  } else {
    Token = cookies.get('JWT_TOKEN')
  }
  const CONFIG_HEADERS2 = {
    Authorization: 'Bearer' + ' ' + Token,
  }

  let responseData = await masterServices.listCommodityData(
    TIMESHEET_Third_SERVICE_URL_Logout + 'user/validate',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )

  return responseData
}

export async function getLogoDetails(queryparams?: any) {
  const CONFIG_HEADERS3 = {
    Authorization: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }
  let orgId = localStorage.getItem('org_id')

  let responseData = await masterServices.listCommodityData(
    TIMESHEET_Third_SERVICE_URL_Logout + 'organization/getLogoDetails/' + `${orgId}`,
    queryparams ? queryparams : null,
    CONFIG_HEADERS3
  )

  return responseData
}

export async function getConfig(postedData?: any) {
  const CONFIG_HEADERS3 = {
    token: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }

  let responseData = await masterServices.listCommodityData(
    TIMESHEET_Third_SERVICE_URL_Config,
    postedData,
    CONFIG_HEADERS3
  )

  return responseData
}
export async function getValidate(queryparams?: any) {
  const CONFIG_HEADERS3 = {
    Authorization: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }

  let responseData = await masterServices.listCommodityData(
    TIMESHEET_Third_SERVICE_URL_Logout + 'user/login/token/validate',
    queryparams ? queryparams : null,
    CONFIG_HEADERS3
  )

  return responseData
}

export async function shareUser(queryparams?: any) {
  const CONFIG_HEADERS3 = {
    Authorization: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }
  let orgId = localStorage.getItem('org_id')

  let responseData = await masterServices.listCommodityData(
    TIMESHEET_Third_SERVICE_URL_Logout + 'userECDB/shareUser',
    queryparams ? queryparams : null,
    CONFIG_HEADERS3
  )

  return responseData
}

export async function getUserLogout(queryparams?: any) {
  const CONFIG_HEADERS3 = {
    Authorization: localStorage.getItem('token'),
  }

  let responseData = await masterServices.listCommodityData(
    TIMESHEET_Third_SERVICE_URL_Logout + 'user/logout',
    queryparams ? queryparams : null,

    CONFIG_HEADERS3
  )

  return responseData
}

export async function getUserLogout1(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }

  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'index/logout',

    queryparams ? queryparams : null,

    CONFIG_HEADERS2
  )

  return responseData
}

export async function checkEmailForForgetPassword(postedData?: any) {
  const CONFIG_HEADERS3 = {
    token: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }
  let config = {
    user_email: postedData.user_email,
  }

  let responseData = await masterServices.getUserTokenByUserName(
    TIMESHEET_Third_SERVICE_URL_Logout + 'list/checkEmailForForgetPassword',
    postedData,
    CONFIG_HEADERS3
  )

  return responseData
}
export async function resendMail(postedData?: any) {
  const CONFIG_HEADERS3 = {
    token: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }
  let config = {
    user_email: postedData.user_email,
  }

  let responseData = await masterServices.getUserTokenByUserName(
    TIMESHEET_Third_SERVICE_URL_Logout + 'userECDB/resendMail',
    postedData,
    CONFIG_HEADERS3
  )
  return responseData
}
export async function get_primary_project_by_user_id(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects2/get_primary_project_by_user_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function get_active_local_projects_by_user_id(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects2/get_active_local_projects_by_user_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}
export async function get_active_placement_projects_by_user_id(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'projects2/get_active_placement_projects_by_user_id',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function sendingTokenForMail(postedData?: any) {
  const CONFIG_HEADERS3 = {
    token: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }
  let config = {
    user_email: postedData.user_email,
  }

  let responseData = await masterServices.getUserTokenByUserName(
    TIMESHEET_Third_SERVICE_URL_Logout + 'password/sendingTokenForMail',
    postedData,
    CONFIG_HEADERS3
  )

  return responseData
}
export async function getUserToken1(queryparams?: any) {
  const CONFIG_HEADERS3 = {
    token: localStorage.getItem('token'),
    user_email: localStorage.getItem('user_email'),
  }
  let anil = localStorage.getItem('logged_in_id')

  let responseData = await masterServices.listCommodityData(
    TIMESHEET_Third_SERVICE_CONTINUE_URL,

    queryparams ? queryparams : null,

    CONFIG_HEADERS3
  )
  return responseData
}
export async function orgListToken(postedData?: any) {
  let config = {
    user_email: postedData.user_email,
  }
  let responseData = await masterServices.getUserTokenByUserName(
    TIMESHEET_Third_SERVICE_URL,
    postedData,
    config
  )

  return responseData
}

export async function tokenData(postData?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let responseData = await masterServices.postMasterData(
    TOKEN_URL,
    postData ? postData : null,
    CONFIG_HEADERS2
  )

  return responseData
}
export async function approverTimesheetsbyyearandmonth(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timeactivity/getapprovertimesheetsbyyearandmonth',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function consultanttimesheetsbyyearandmonth(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  return await masterServices.listCommodityData(
    TIMESHEET_SERVICE_URL + 'timesheets/get_monthly_timesheet_by_user',
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
}

export async function getReasons(queryparams?: any) {
  let CONFIG_HEADERS2 = {
    Authorization: localStorage.getItem('Authorization'),
    user_email: localStorage.getItem('user_email'),
  }
  let org_id = localStorage.getItem('logged_in_id')

  let responseData = await masterServices.listCommodityData(
    PLACEMENT_URL + `/${org_id}`,

    // PLACEMENT_URL + `/${organization}`,
    queryparams ? queryparams : null,
    CONFIG_HEADERS2
  )
  return responseData
}

export async function getEmployeeData(postData?: any) {
  var Token
  if (cookies.get('JWT-TOkEN')) {
    Token = cookies.get('JWT-TOkEN')
  } else {
    Token = cookies.get('JWT_TOKEN')
  }
  const CONFIG_HEADERS2 = {
    Authorization: 'Bearer' + ' ' + Token,
  }
  return await masterServices.getEmployeeData(
    TIMESHEET_UAT_URL + '/organization/' + `${postData}`,
    CONFIG_HEADERS2
  )
}

export default {
  getAllEmployeeData,
  getSearchEmployee,
  insertEmployee,
  updateEmployee,
  updateProjectNames,
  getemployeebyid,
  getEmployeeDocumentsbyid,
  getUserToken,
  getEmployee,
  getCustomer,
  classificationNetSuite,
  departmentNetSuite,
  getService,
  getProject,
  getNetSuiteToken,
  updateNetSuite,
  updateNetSuiteCustomer,
  updateNetSuiteProject,
  getNetSuiteConfig,
  getSubsidaryNetSuite,
  updateOrganizationNetSuiteSetting,
  NetSuiteMapProjectEmployee,
  getSSO,
  getLogoDetails,
  getConfig,
  getValidate,
  shareUser,
  getUserToken1,
  orgListToken,
  tokenData,
  // getFilterEmployee,
  getPlacementUrl,
  getPlacementCompleted,
  getPlacementCancel,
  getProjectByPlacementCode,
  getFilterProject,
  subsidaryNetSuite,
  customerNetSuite,
  projectNetSuite,
  serviceitemNetSuite,
  employeeNetSuite,
  invoiceNetSuite,
  get_consultants_mapped_to_relation_managers,
  get_all_monthly_hours_settings,
  insert_monthly_hours_setting,
  reset_yearly_monthly_hours_setting,
  getOrganizationsList,
  get_all_employee_document_types,
  get_employee_document_types_list,
  get_employee_document_types_by_id,
  insertEmployeeDocumentType,
  insert_employee_document,
  update_employee_document_types,
  delete_employee_document_type,
  getDefaultOrgAsCustomer,
  getcustomerdatabyname,
  insertProject,
  export_invoices_history,
  get_timesheet_entries_to_create_invoice,
  get_timesheet_entries_to_create_netsuite_invoice,
  create_invoice_project_wise_timesheet,
  create_NetSuite_invoice_project_wise_timesheet,
  get_invoice_details_by_invoice_id,
  downloadInvoice,
  getProjectWiseCreatedHistory,
  getProjectWiseNetSuiteCreatedHistory,
  insertComplianceComment,
  getComplianceComments,
  get_bench_project_based_on_start_date,
  update_C2C_user_project_status,
  get_project_wise_yearly_non_compliance_report,
  get_yearly_active_employees_report,
  get_yearly_active_projects_report,
  get_active_employees_report,
  get_active_projects_report,
  export_active_employees_report,
  export_active_projects_report,
  export_yearly_active_employees_report,
  export_yearly_active_projects_report,
  get_yearly_revenue_report,
  get_yearly_utilization_report,
  get_yearly_cogs_report,
  get_yearly_margin_report,
  get_project_wise_revenue_report,
  get_project_wise_unbilled_report,
  export_project_wise_unbilled_report,
  get_project_wise_utilization_report,
  get_location_wise_report,
  export_location_wise_report,
  get_project_wise_cogs_report,
  get_project_wise_margin_report,
  export_project_wise_revenue_report,
  export_project_wise_utilization_report,
  export_project_wise_cogs_report,
  export_project_wise_margin_report,
  export_yearly_revenue_report,
  export_yearly_utilization_report,
  export_yearly_cogs_report,
  export_yearly_margin_report,
  ExportProjectWiseYearlyNonComplianceReport,
  applyLeavesHolidays,
  insertMobileForceUpdate,
  insertAnnoucement,
  markAsReadAnnouncement,
  updateEmailConfig,
  updateOrganizationSetting,
  updateGlobalConfig,
  getOrganizationSetting,
  getQBAuthUri,
  getQBCallBack,
  getSearchCandidate,
  getUpdateSearchCandidate,
  getGlobalConfigData,
  getAllClientsData,
  getFilterClient,
  getFilterTimesheet,
  getprojectbyid,
  insertRmComment,
  updateComment,
  getHistoryCommentsbyUserid,
  getHistoryCommentsbyCommentid,
  consultantsMappedtorelationManagersExport,
  getMobileForceUpdateAccessbyEmail,
  getmobileforceupdatebyid,
  getannoucementbyid,
  getuserbyid,
  getitemdatabyname,
  updateProject,
  otProductProject,
  editmobileforceupdate,
  updateAnnoucement,
  updateOrganizationWorkLocationSetting,
  update_organization_hours_setting,
  update_organization_email_setting,
  update_maintenance_config,
  EmailRemainderSetting,
  deleteAnnoucement,
  deleteMobileForceUpdate,
  getclientbyname,
  getcustomerbyname,
  getconsultantbyname,
  getCustomersList,
  getcountriesbyname,
  getReasons,
  getclientbyid,
  updateClient,
  getVerifyEmail,
  saveTokenDetails,
  getallAnnoucements,
  getemailconfigbyOrgid,
  QBTransactionNames,
  QBTransactionsSetting,
  getQBTransactionsSettingByName,
  getLatestAnnouncement,
  getFilterUser,
  get_all_business_units,
  get_all_branches,
  get_all_departments,
  getAllMobileForceUpdate,
  getQuickbookConfig,
  updateQuickbookConfig,
  getWeekDays,
  getMonths,
  getWeekDaysSingleDate,
  getEmployeeProjectList,
  getSingleWeekTimesheet,
  getCopyLastWeekHoursData,
  timeSheetInsert,
  allTimeSheetInsert,
  revertProjectWiseTimeSheet,
  revertProjectWiseSingleDay,

  timeSheetSaveInsert,
  copyTimesheetDocs,
  timeSheetSaveAllInsert,
  timeSheetSingleProjectSubmit,
  timeSheetSubmitAllProject,
  timeSheetUnSubmitSingleProject,
  timeSheetUnSubmitAllProject,

  weekWiseData,
  getHoursOfTimesheet,
  pendingTimeSheet1,
  pendingWeekWiseTimeSheet1,
  pendingWeekWiseTimeSheet2,
  getTotalHoursByWeek,
  pendingTimeSheet2,
  timeSheetDataList,
  employee_leaves_by_user_id,
  forgotPassword,
  editTimesheetData,
  getDataBySelectedDate,
  insertDocument,
  getTotalHours,
  allApproverWeekandStatus,
  approverTimeweekEmpName,
  dateOneWeek,
  projectDataView,
  approverCommentsTextBox,
  approverTimesheetsbyyearandmonth,
  timesheetHistory,
  timesheetHistoryPerDay,
  timesheetHistoryperday,
  approverTimesheetstatusperday,
  approverTimesheethistoryperday,
  timesheetStatusperday,
  approverTimesheethistory,
  quickBooks,
  netSuite,
  netSuiteUpdate,
  quickBooksDayLevel,
  quickBooksProjectWiseMonthly,
  addUserPost,
  addUserSubmitPost,
  getTicketingToolAccess,
  qbSettings,
  refreshToken,
  manageUserData,
  syncBusinessUnits,
  syncBranches,
  syncDepartments,
  projectUserData,
  getcustomerdatabyname1,
  getvendordatabyname1,
  docNameIdProjectList,
  consultanttimesheetsbyyearandmonth,
  deleteDocId,
  deleteEmployeeDocId,
  getAllUserData1,
  get_business_units_list,
  update_classification_to_netSuite,
  update_department_to_netSuite,
  get_relation_managers_list,
  get_branches_list,
  get_departments_list,
  get_approvers_list_by_business_unit_id,
  getSearchEmployee1,
  getUserLogout,
  getUserLogout1,
  checkEmailForForgetPassword,
  resendMail,
  get_primary_project_by_user_id,
  get_active_local_projects_by_user_id,
  get_active_placement_projects_by_user_id,
  sendingTokenForMail,
  updateUser,
  ECDB_UserSearch_URL,
  allStatusEmails,
  consultantUpdateTime,
  validateEmployee,
  checkEmail,
  getemployeebyemail,
  getemployeebyemailId,
  employeeId,
  updateUserPros,
  getcountriesbyname1,
  getstatebyname,
  prosLocalEmail,
  ECDBLocalEmail,
  validateEmployee1,
  prosLocalEmailUpdate,
  ECDBLocalUpdateEmail,
  getcitybyname,
  refreshTokenapi,
  projectUpdateTime,
  docNameIdProjectList1,
  projectWiseData,
  allTimeSheetInsertSingle,
  allApproverTimeSheetData,
  rejectProjectWiseTimeSheet,
  rejectProjectWiseSingleDay,
  rejectAllProjectTimesheets,
  revertAllProjectTimesheets,
  allApproverMonthlyTimeSheetData,
  allRejectMonthlyTimeSheetData,
  getSearchEmployeeConsultant,
  reportViewData,
  projectreportViewData,
  employeereportViewData,
  employeeComplianceReport,
  employee_wise_yearly_compliance_report,
  employee_wise_yearly_compliance_report_total,
  employee_wise_yearly_compliance_report_week_wise,
  get_all_active_project_by_user_id,
  projectComplianceReport,
  employeesStatusReport,
  employeeWiseProject,
  employeeExportreportViewData,
  employeeExportComplianceReport,
  export_employee_wise_yearly_compliance_report,
  export_employee_wise_yearly_compliance_report_week_wise,
  projectExportComplianceReport,
  exportEmployeesStatusReport,
  projectexportreportViewData,
  allUsersExport,
  allBusinessUnitExport,
  allBranchesExport,
  allDepartmentsExport,
  allConsultantExport,
  allProjectExport,
  allCustomerExport,
  timeSheeteportViewData,
  timeSheetExportreportViewData,
  employeeSubmissionReports,
  timeSheetSubmissionData,
  ecdbUserStatusUpdate,
  updateUserStatus,
  projectUserData1,
  updateUserEmpId,
  adpAssociateIdData,
  updateUserStatusData,
  updateOrganizationAdpsetting,
  updateOrganizationQBSetting,
  updateOrganizationMainQBSetting,
  userLogReportData,
  getEmployeeReports,
  exportEmployeeReports,
  getProjectReports,
  exportAllUserLogs,
  updateVisaStatus,
  getallcountries,
  updateVisaStatusData,
  adpAssociateIdDataCheck,
  adpAssociateIdDataUpdateCheck,
  getprojectbyname,
  get_overall_active_projects_count_in_selected_months,
  exportProject1Reports,
  employeereportViewDataProject,
  employeeExportreportViewData1,
  employeeExportQBreportViewData,
  employeeExportQBreportViewReports,
  getQbbyname,
  getProjectReports1,
  qbTransastion,
  monthViewOpen,
  monthViewOpenProject,
  allRevertMonthlyTimeSheetData,
  allApproverMonthlyTimeSheetDataProject,
  allRejectMonthlyTimeSheetDataProject,
  allRevertMonthlyTimeSheetDataProject1,
  getAllEmployesData,
  getAllClientNameData,
  getAllProjectNameData,
  // timeSheetListData,
  getTopLatestAnnouncement,
  AnnouncementStatusUpdate,
  getEmployeeData,
  get_project_wise_yearly_non_compliance_report_total,
}
