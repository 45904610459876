import axios from 'axios'

export async function execGetRequestsWithParams(endpoint: any, param: any, configheaders: any) {
  // headers: { 'Authorization': configheaders.Authorization, 'user_email': configheaders.user_email, 'Access-Control-Allow-Origin': '*' },

  let config = {
    headers: configheaders,
    params: param,
  }
  // console.log(endpoint, '===', config);
  try {
    // console.log('TRY BLOCK');
    const apiResponse: any = await axios.get(endpoint, config)
    if (apiResponse.issuccess === 0) {
      return 'error'
    } else {
      return apiResponse
    }
  } catch (err) {
    return err
    // console.log('CATCH BLOCK');
    // console.log(err);
  }
}
export async function execGetRequestsWithParams2(endpoint: any, param: any, configheaders: any) {
  // headers: { 'Authorization': configheaders.Authorization, 'user_email': configheaders.user_email, 'Access-Control-Allow-Origin': '*' },
  let config = {
    timeout: 1000 * 60 * 30,
    headers: configheaders,
    params: param,
  }
  //   console.log(endpoint, '== endpoint =', config)
  try {
    // console.log('TRY BLOCK');
    const apiResponse: any = await axios.get(endpoint, config)
    if (apiResponse.issuccess === 0) {
      return 'error'
    } else {
      return apiResponse
    }
  } catch (err) {
    // console.log('CATCH BLOCK');
    // console.log(err);
  }
}
// export async function execGetRequestsWithParamsBody(endpoint: any, postdata: any, configheaders: any,) {
//     console.log(postdata, "postdata")
//     let config = {
//         headers: configheaders,
//         data: postdata,
//     };
//     try {
//         const apiResponse: any = axios({
//             method: 'GET',
//             url: `${endpoint}`,
//             headers: config.headers,
//             data: config.data
//         })
//         //const apiResponse: any = await axios.get(endpoint, config);
//         if (apiResponse.issuccess === 0) {
//             return "error";
//         } else {
//             return apiResponse;
//         }
//     } catch (err) {
//         console.log(err);
//     }
// }

export async function execGetRequestsWithoutParams(endpoint: any, configheaders: any) {
  let config = {
    headers: configheaders,
  }
  try {
    const apiResponse: any = await axios.get(endpoint, config)
    if (apiResponse.issuccess === 0) {
      return 'error'
    } else {
      return apiResponse
    }
  } catch (err) {
    console.log(err)
  }
}

export function execGetRequests(endpoint: any) {
  axios
    .get(endpoint)
    .then((res: any) => {
      if (res.issuccess === 0) {
        return 'error'
      } else {
        return res.data
      }
    })
    .catch((err) => console.log(err))
}

export function execGetRequestsDownloadFile(endpoint: any, filename: any, configheaders: any) {
  axios({
    url: endpoint, // Interface name
    method: 'get',
    headers: configheaders,
    responseType: 'blob',
  })
    .then(function (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      })
      const aEle = document.createElement('a') // Create a label
      const href = window.URL.createObjectURL(blob) // Create downloaded link
      aEle.href = href
      aEle.download = filename // File name after download
      document.body.appendChild(aEle)
      aEle.click() // Click to download
      document.body.removeChild(aEle) // Download complete remove element
      window.URL.revokeObjectURL(href) // Release blob object
    })
    .catch((err) => console.log(err))
}

export function execPostRequestsDownloadFile(
  endpoint: any,
  postdata: any,
  filename: any,
  configheaders: any
) {
  axios({
    url: endpoint, // Interface name
    method: 'post',
    headers: configheaders,
    responseType: 'blob',
    data: postdata,
  })
    .then(function (response) {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      })
      const aEle = document.createElement('a') // Create a label
      const href = window.URL.createObjectURL(blob) // Create downloaded link
      aEle.href = href
      aEle.download = filename // File name after download
      document.body.appendChild(aEle)
      aEle.click() // Click to download
      document.body.removeChild(aEle) // Download complete remove element
      window.URL.revokeObjectURL(href) // Release blob object
    })
    .catch((err) => console.log(err))
}

export async function execPostRequest(endpoint: any, postdata: any, configheaders: any) {
  try {
    const apiResponse = await axios({
      url: endpoint, // Interface name
      method: 'post',
      headers: configheaders,
      data: postdata,
    })
    // return apiResponse.data
    if (apiResponse.data.issuccess === 0) {
      return apiResponse.data.message || 'error'
    } else {
      return apiResponse.data
    }
  } catch (error: any) {
    console.log(error)
    return error.response.data
  }
}

export async function execPutRequest(endpoint: any, postdata: any, configheaders: any) {
  try {
    const apiResponse = await axios({
      url: endpoint, // Interface name
      method: 'put',
      headers: configheaders,
      data: postdata,
    })
    // return apiResponse.data
    if (apiResponse.data.issuccess === 0) {
      return apiResponse.data.message || 'error'
    } else {
      return apiResponse.data
    }
  } catch (error: any) {
    console.log(error)
    return error.response.data
  }
}

export async function execPatchRequest(endpoint: any, postdata: any, configheaders: any) {
  try {
    const apiResponse = await axios({
      url: endpoint, // Interface name
      method: 'patch',
      headers: configheaders,
      data: postdata,
    })
    // return apiResponse.data
    if (apiResponse.data.issuccess === 0) {
      return apiResponse.data.message || 'error'
    } else {
      return apiResponse.data
    }
  } catch (error: any) {
    console.log(error)
    return error.response.data
  }
}

export async function execPutRequestWithParams(
  endpoint: any,
  param: any,
  postdata: any,
  configheaders: any
) {
  try {
    const apiResponse = await axios({
      url: endpoint, // Interface name
      method: 'put',
      headers: configheaders,
      data: postdata,
      params: param,
    })
    return apiResponse.data
  } catch (err) {
    console.log(err)
  }
}

export async function execDeleteRequestWithParams(
  endpoint: any,
  param: any,
  postdata: any,
  configheaders: any
) {
  try {
    const apiResponse = await axios({
      url: endpoint, // Interface name
      method: 'delete',
      headers: configheaders,
      data: postdata,
      params: param,
    })
    return apiResponse.data
  } catch (err) {
    console.log(err)
  }
}

export async function execMultiPartRequestWithParams(
  endpoint: any,
  param: any,
  postdata: any,
  configheaders: any
) {
  try {
    const apiResponse = await axios({
      url: endpoint, // Interface name
      method: 'post',
      headers: configheaders,
      data: postdata,
      params: param,
      // "content-type": "multipart/form-data",
    })
    return apiResponse.data
  } catch (err) {
    console.log(err)
  }
}

export async function execMultiPartRequest(endpoint: any, postdata: any, configheaders: any) {
  try {
    const apiResponse = await axios({
      url: endpoint, // Interface name
      method: 'post',
      headers: configheaders,
      data: postdata,
      // "content-type": "multipart/form-data",
    })
    return apiResponse.data
  } catch (err) {
    console.log(err)
  }
}

export async function execPostRequestWithParams(
  endpoint: any,
  postdata: any,
  param: any,
  configheaders: any
) {
  try {
    const apiResponse = await axios({
      url: endpoint, // Interface name
      method: 'post',
      headers: configheaders,
      params: param,
      data: postdata,
    })
    // return apiResponse.data
    if (apiResponse.data.issuccess === 0) {
      return apiResponse.data.message || 'error'
    } else {
      return apiResponse.data
    }
  } catch (error: any) {
    console.log(error)
    return error.response.data
  }
}

export default {
  execGetRequestsWithParams,
  execGetRequestsWithParams2,
  execGetRequestsWithoutParams,
  execGetRequests,
  execGetRequestsDownloadFile,
  execPostRequest,
  execPutRequestWithParams,
  execPutRequest,
  execPatchRequest,
  execPostRequestsDownloadFile,
  execDeleteRequestWithParams,
  // execMultiPartRequestWithParams,
  // execMultiPartRequest,
  execPostRequestWithParams,
  // execGetRequestsWithParamsBody
}
