import React, {useMemo, useState, useEffect, useContext, useRef} from 'react'
import ThingsContext from '../../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../../modules/apps/masters/commonicons/ValidateContext'
import {default as Apiservices} from '../../../../common/index'
import {useIntl} from 'react-intl'
import ReactPaginate from 'react-paginate'
import {useForm} from 'react-hook-form'
import {Container, Row, Col} from 'react-bootstrap'
import 'reactjs-popup/dist/index.css'
import clsx from 'clsx'
import {KTCardBody} from '../../../../_metronic/helpers'
import {Toolbar1} from '../../../../_metronic/layout/components/toolbar/Toolbar1'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useTable, ColumnInstance} from 'react-table'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ManageListClients} from '../../../modules/apps/user-management/users-list/table/columns/_columns'
import {UsersListLoading} from '../../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import {UsersListPagination} from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Chart from 'react-apexcharts'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Modal from 'react-bootstrap/Modal'

type FormData = {
  client: string
  dba: string
  ccdb_id: string
  qb_client_name: string
  qb_client_id: string
  id: number
  user_id: number
  user: number
  select_Year: any
  org_name: any
  employee_type: string
  project_type: any
  work_city: string
}

const TimesheetComplianceReports = (props: any) => {
  const {
    register,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>()
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)
  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => ManageListClients, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    // props.view_open(false)
    // props.setViewOpen(false)
  }
  const intl = useIntl()
  const [fromclient, setFromClient] = useState<any | []>(true)
  const [perPage, setPerPage] = useState<any>(25)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [disableQuickBooks, setDisableQuickBooks] = useState(false)
  const [disablecustomerdatabynameQuickBooks, setDisablecustomerdatabynameQuickBooks] =
    useState(false)
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [selected, setSelected] = useState([])
  const [selectConsultant, setSelectConsultant] = useState([])
  const [select_consultant, setSelectconsultant] = useState<any | []>([])
  const typeaheadRef: any = useRef<HTMLInputElement>(null)
  const typeaheadRef1: any = useRef<HTMLInputElement>(null)
  const typeaheadRef2: any = useRef<HTMLInputElement>(null)
  const typeaheadRef3: any = useRef<HTMLInputElement>(null)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)
  const typeaheadRef7: any = useRef<HTMLInputElement>(null)
  const typeaheadRef8: any = useRef<HTMLInputElement>(null)
  const [flag, setFlag] = useState(false)
  const [validatedescription, setValidateDescription] = useState<any>(false)

  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnitsList2, setBusinessUnitsList2] = useState<any | []>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [ComplianceBusinessUnit, setComplianceBusinessUnit] = useState<any | []>([])
  const [country1, setCountry1] = useState<any | []>([])
  const [candidateList, setCandidateList] = useState<any | []>([])
  // ref.current.close()

  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [order7, setOrder7] = useState('ASC')
  const [order8, setOrder8] = useState('ASC')
  const [order9, setOrder9] = useState('ASC')

  const [order12, setOrder12] = useState('ASC')

  const [sort, setSort] = useState<any | []>(false)
  // const typeaheadRef = useRef(null)
  // const [sort, setSort] = useState<any | []>(false)
  const [filterstatus, setFilterStatus] = useState<any | []>('ACTIVE')
  const [error, setError] = useState([])
  const [filterReportStaus, setFilterReportStaus] = useState<any | []>([])
  const [orgList, setOrgList] = useState<any | []>([])
  const [employeeList, setEmployeeList] = useState<any | []>([])
  const [clientlist_data, setClient_Data] = useState<any | []>([])
  const [search, setSearch] = useState('')
  const [clientfilteropen, setClientFilterOpen] = useState<any | []>(false)
  const [resetvalues, setResetvalues] = useState(true)
  const [date, setDate] = useState('')
  const [selectYear, setSelectYear] = useState<any>(new Date())
  const [selectYear1, setSelectYear1] = useState<any>(new Date())
  const [years, setYears] = useState<any>([])
  const [OrganizationReportData, setOrganizationReportData] = useState<any | []>([])
  const [ProjectReportData, setProjectReportData] = useState<any | []>([])
  const [ProjectReportDataCount, setProjectReportDataCount] = useState<any | []>([])
  const [chartData, setChartData] = useState<any | []>([])
  const [org_name, setOrgName] = useState('')
  const [candidate, setCandidate] = useState<any | []>([])
  const [selectMonth, setSelectMonth] = useState<any>([])
  const [countries1, setCountries1] = useState<any | []>([])
  const [ProjectReportCountData, setProjectReportCountData] = useState<any | []>([])
  const [ProjectReportProjectsData, setProjectReportProjectsData] = useState<any | []>([])
  const [AllOptions, setAllOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ])
  const [AllMonthOptions, setAllMonthOptions] = useState<any | []>([
    // {
    //   label: 'Select All',
    //   value: 'All',
    // },
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
    {
      label: 'Deselect All',
      value: 'empty',
    },
  ])
  const [selectProjectType, setSelectProjectType] = useState<any>([])
  const [AllProjectTypeOptions, setAllProjectTypeOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'Placement',
      value: '1',
    },
    {
      label: 'Bench',
      value: '2',
    },
    {
      label: 'Internal',
      value: '3',
    },
  ])
  const [AllOptions2, setAllOptions2] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ])
  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }

  const tokenHeader1: any = useContext(ValidContext)
  const renderThings1 = async () => {
    tokenHeader1.getValidate()
  }

  useEffect(() => {
    renderThings()
    AllOrgList()
    // handleConsultant()
    setSelectYear(selectYear)
    // setSelectYear1(selectYear1)
    setSelectYear1(new Date(moment().subtract(1, 'month').format('YYYY')))
    // setSelectYear(new Date(moment().subtract(1, 'month').format('YYYY')))
    const MonthNumberDefault = [
      {
        label: moment(new Date()).subtract(1, 'month').format('MMMM'),
        value: moment(new Date()).subtract(1, 'month').format('M'),
      },
    ]
    setSelectMonth(MonthNumberDefault)
    // let currYr: any = new Date().getFullYear()
    // setSelectYear(currYr)
    // let pastYr = 2013
    // while (currYr >= pastYr) {
    //   years.push(currYr)
    //   currYr -= 1
  }, [])
  useEffect(() => {
    //get_business_units_list()
  }, [])
  const get_business_units_list = async () => {
    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      setBusinessUnitsList(res.data.data)
    }
  }
  const handleConsultant = async (consult_Name?: any) => {
    const response: any = await Apiservices.getconsultantbyname()
    setCandidate(response?.data?.data)
  }
  const sorting5 = (col: any) => {
    console.log(ProjectReportData)

    if (order5 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => (a[col] > b[col] ? 1 : -1))
      console.log(sorted, 'asc')

      setProjectReportData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      console.log(ProjectReportData)
      const sorted = [...ProjectReportData]?.sort((a, b) => (a[col] < b[col] ? 1 : -1))

      console.log(sorted, 'dsc')

      setProjectReportData(sorted)

      setOrder5('ASC')
      setSort(true)
    }
  }

  const sorting6 = (col: any) => {
    if (order6 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        const numA = parseInt(a[col].match(/\d+/), 10) || 0
        const numB = parseInt(b[col].match(/\d+/), 10) || 0
        // (a[col] > b[col] ? 1 : -1)
        // if (numA === numB) {
        //   // If numeric parts are equal, compare the whole strings
        //   return a[col].localeCompare(b[col])
        // } else {
        //   return numA - numB
        // }
        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerA.localeCompare(lowerB)
      })
      // const sorted = [...mainTableData].sort((a, b) => {
      //   return a[col].localeCompare(b[col], undefined, {
      //     numeric: true,
      //     sensitivity: 'base',
      //   })
      // })
      setProjectReportData(sorted)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        const numA = parseInt(a[col].match(/\d+/), 10) || 0
        const numB = parseInt(b[col].match(/\d+/), 10) || 0
        // (a[col] < b[col] ? 1 : -1)
        // if (numA === numB) {
        //   // If numeric parts are equal, compare the whole strings
        //   return b[col].localeCompare(a[col])
        // } else {
        //   return numB - numA
        // }
        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerB.localeCompare(lowerA)
      })

      // const sorted = [...mainTableData].sort((a, b) => {
      //   return b[col].localeCompare(a[col], undefined, {
      //     numeric: true,
      //     sensitivity: 'base',
      //   })
      // })
      setProjectReportData(sorted)
      setOrder6('ASC')
      setSort(true)
    }
  }

  const sorting7 = (col1: any, col2: any) => {
    if (order7 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() > val2?.toLowerCase() ? 1 : -1
      })

      setProjectReportData(sorted)
      setOrder7('DSC')
      setSort(false)
    }

    if (order7 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() < val2?.toLowerCase() ? 1 : -1
      })

      setProjectReportData(sorted)
      setOrder7('ASC')
      setSort(true)
    }
  }
  const sorting8 = (col: any) => {
    if (order8 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => a[col] - b[col])

      setProjectReportData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order8 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => b[col] - a[col])
      setProjectReportData(sorted)
      setOrder8('ASC')
      setSort(true)
    }
  }
  const sorting12 = (col: any, col1: any, col2: any) => {
    //status,cancel,end
    if (order12 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => {
        let a1 = a[col] == 'CANCELLED' ? a[col1] : a[col2]
        let b1 = b[col] == 'CANCELLED' ? b[col1] : b[col2]

        let dateA = new Date(a1),
          dateB = new Date(b1)
        if (dateB > dateA) {
          return -1
        } else {
          return 1
        }
      })

      setProjectReportData(sorted)
      setOrder12('DSC')
      setSort(false)
    }

    if (order12 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => {
        let a1 = a[col] == 'CANCELLED' ? a[col1] : a[col2]
        let b1 = b[col] == 'CANCELLED' ? b[col1] : b[col2]

        let dateA = new Date(a1),
          dateB = new Date(b1)
        if (dateB < dateA) {
          return -1
        } else {
          return 1
        }
      })

      setProjectReportData(sorted)
      setOrder12('ASC')
      setSort(true)
    }
  }
  const sorting9 = (col: any, col1: any) => {
    //join,start
    if (order9 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => {
        let a1 = a[col] ? a[col] : a[col1]
        let b1 = b[col] ? b[col] : b[col1]

        let dateA = new Date(a1),
          dateB = new Date(b1)
        if (dateB > dateA) {
          return -1
        } else {
          return 1
        }
      })

      setProjectReportData(sorted)
      setOrder9('DSC')
      setSort(false)
    }

    if (order9 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => {
        let a1 = a[col] ? a[col] : a[col1]
        let b1 = b[col] ? b[col] : b[col1]

        let dateA = new Date(a1),
          dateB = new Date(b1)
        if (dateB < dateA) {
          return -1
        } else {
          return 1
        }
      })

      setProjectReportData(sorted)
      setOrder9('ASC')
      setSort(true)
    }
  }

  const AllOrgList = async () => {
    setShowBackDrop(true)

    // const res: any = await Apiservices.get_business_units_list()
    // if (res && res.status == 200) {
    //   if (res.data.data.length > 0) {
    //     // setBusinessUnitsList(res.data.data)
    //     let obj = {name: 'Deselect All', value: 'empty'}
    //     let obj2 = {name: 'Select All', value: 'All'}

    //     setBusinessUnitsList([obj, ...res.data.data])
    //     setBusinessUnitsList2([obj2, ...res.data.data])
    //   }
    // }
    let y = JSON.parse(localStorage.getItem('bu_list') || '[]')

    let n = y.filter(
      (element: any) =>
        element.roleName?.toLowerCase() == 'accounts' ||
        element.roleName?.toLowerCase() == 'approver'
      // element.roleName?.toLowerCase() == 'timesheetadmin'
    )
    let business: any = localStorage.getItem('role_Name')
    let numArray: any = business?.split(',')
    let uniqueArr: any = []

    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        let obj = {name: 'Deselect All', value: 'empty'}
        let obj2 = {name: 'Select All', value: 'All'}

        if (numArray?.includes('admin') || numArray?.includes('timesheetadmin')) {
          let x = res.data.data.filter((test: any) => uniqueArr.push(test))
          setBusinessUnitsList([obj, ...uniqueArr])
          setBusinessUnitsList2([obj2, ...uniqueArr])
        } else {
          for (let each of res.data.data) {
            for (let item of n) {
              if (each.business_unit_id == item.buId) {
                uniqueArr.push(each)
              }
            }
          }
          setBusinessUnitsList([obj, ...uniqueArr])
          setBusinessUnitsList2([obj2, ...uniqueArr])
        }
      }
    }

    // let business1 = res?.data?.data?.map((val: any) => {
    //   return val.business_unit_id
    // })
    let business1 = uniqueArr?.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1?.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    // setComplianceBusinessUnit(res?.data?.data)
    setComplianceBusinessUnit(uniqueArr)

    const article1 = {
      org_id: Number(localStorage.getItem('org_id')),
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      // year: selectYear ? moment(selectYear).subtract(1, 'month').format('YYYY') : '',
      month: [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: null,
      user_id: null, // optional
      project_id: null, // optional
      // is_placement_project: getValues('project_type') ? Number(getValues('project_type')) : null,
      is_placement_project: project_type2 ? project_type1 : null,
      business_unit_id: null,
    }
    const employeeResponse: any = await Apiservices.employee_wise_yearly_compliance_report_total({
      org_id: localStorage.getItem('org_id'),
      // year: selectYear ? moment(selectYear).format('YYYY') : '',
      // year: selectYear ? moment(selectYear).subtract(1, 'month').format('YYYY') : '',
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      employee_type: '',
      // business_unit_id: null,
      business_unit_id: business2 ? business2 : '',
    })
    // const response: any = await Apiservices.get_project_wise_yearly_non_compliance_report(article1)
    // if (response && response.isError == false) {
    //   setProjectReportData(response.data)
    // }
    if (employeeResponse && employeeResponse.data && employeeResponse.data.isError == false) {
      // let orgReport = employeeResponse.data.data //with business data
      let test = employeeResponse.data.total_data // without business unit
      let AllData = [test]

      if (employeeResponse.data && employeeResponse.data.data?.length > 0) {
        for (let each of business1) {
          let x = employeeResponse.data.data.filter((temp: any) => temp.business_unit_id == each)
          AllData.push(x)
        }
      }
      let finalData = []
      for (let each of AllData) {
        let obj: any = {}
        let data1: any = []
        for (let item of each) {
          obj.name = item.business_unit_name
            ? item.business_unit_name
            : localStorage.getItem('org_name')
          obj.color = item.color ? item.color : '#0000ff'
          data1.push(item.compliance_percentage)
        }
        obj.data = data1
        finalData.push(obj)
      }
      let AllMonths = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      setOrganizationReportData({
        options: {
          chart: {
            height: 350,
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          xaxis: {
            title: {
              text: 'Month',
              floating: false,
            },
            categories: AllMonths,
          },
          // dataLabels: {
          //   enabled: true,
          // enabledOnSeries: [0],
          // },

          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            formatter: function (val: any, opts: any) {
              return val
            },
          },
          stroke: {
            curve: 'straight',
          },
          markers: {
            size: 1,
          },

          // title: {
          //   text: 'Timesheet Compliance graphs',
          //   align: 'left',
          // },
          yaxis: {
            title: {
              text: '% Compliance ',
              style: {
                fontSize: '15px',
                fontWeight: 600,
              },
            },
          },
          legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 40,
            itemMargin: {
              horizontal: 10,
            },
            markers: {
              width: 10, // Adjusts legend marker size
              height: 10,
              offsetX: -7,
            },
          },
          tooltip: {
            theme: false,
          },
        },
        // series: [
        //   {
        //     name: '% Compliance ',
        //     type: 'line',
        //     data: orgReport?.map((item: any) => item.compliance_percentage),
        //   },
        // ],
        series: finalData,
      })
    }
    setShowBackDrop(false)
  }

  const yearSelect = (year: any) => {
    setSelectYear(year)
    setDate('')
  }
  //To get all organizations
  // const OrganizationList = async () => {
  //   const data: any = await Apiservices.getOrganizationsList()
  //   if (data?.data?.isError == false) {
  //     setOrgList(data.data.data)
  //   }
  // }

  const handleClick = async () => {
    // setFlag(true)
    let yesterday = moment(new Date()).subtract(1, 'month').format('M')

    let stat1 = selected.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    const MonthNumberDefault = [
      {
        label: moment(new Date()).subtract(1, 'month').format('MMMM'),
        value: moment(new Date()).subtract(1, 'month').format('M'),
      },
    ]
    let business1 = ComplianceBusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    setSelectMonth(MonthNumberDefault)
    setSelectConsultant(selected)
    // setBusinessUnit(ComplianceBusinessUnit)
    setValue('project_type', '')
    setSelectconsultant([])
    setCountries1([])

    const article = {
      org_id: Number(localStorage.getItem('org_id')),
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      month: [Number(yesterday)], // (1=January, 12 = December )Pass null for all months data
      // employee_type: stat2 ? [stat2] : '',
      employee_type: stat2 ? stat1 : '',
      user_id: null,
      project_id: null,
      // is_placement_project: getValues('project_type') ? Number(getValues('project_type')) : null,
      is_placement_project: project_type2 ? project_type1 : null,
      business_unit_id: business2 ? business1 : '',
    }
    setShowBackDrop(true)
    const employeeResponse: any = await Apiservices.employee_wise_yearly_compliance_report_total({
      org_id: localStorage.getItem('org_id'),
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      employee_type: stat2 ? stat2 : '',
      business_unit_id: business2 ? business2 : '',
    })
    // const response: any = await Apiservices.get_project_wise_yearly_non_compliance_report(article)
    // if (response && response.isError == false) {
    //   setProjectReportData(response.data)
    // }

    if (employeeResponse && employeeResponse.data && employeeResponse.data.isError == false) {
      // let orgReport = employeeResponse.data.data //with business data
      let test = employeeResponse.data.total_data // without business unit
      let AllData = [test]

      if (employeeResponse.data && employeeResponse.data.data?.length > 0) {
        for (let each of business1) {
          let x = employeeResponse.data.data.filter((temp: any) => temp.business_unit_id == each)
          AllData.push(x)
        }
      }
      let finalData = []
      for (let each of AllData) {
        let obj: any = {}
        let data1: any = []
        for (let item of each) {
          obj.name = item.business_unit_name
            ? item.business_unit_name
            : localStorage.getItem('org_name')
          obj.color = item.color ? item.color : '#0000ff'
          data1.push(item.compliance_percentage)
        }
        obj.data = data1
        finalData.push(obj)
      }
      let AllMonths = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]

      setOrganizationReportData({
        options: {
          chart: {
            height: 350,
            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          xaxis: {
            title: {
              text: 'Month',
              floating: false,
            },
            categories: AllMonths,
          },
          // dataLabels: {
          //   enabled: true,
          // enabledOnSeries: [0],
          // },

          dataLabels: {
            enabled: true,
            enabledOnSeries: undefined,
            formatter: function (val: any, opts: any) {
              return val
            },
          },
          stroke: {
            curve: 'straight',
          },
          markers: {
            size: 1,
          },

          // title: {
          //   text: 'Timesheet Compliance graphs',
          //   align: 'left',
          // },

          yaxis: {
            title: {
              text: '% Compliance ',
              style: {
                fontSize: '15px',
                fontWeight: 600,
              },
            },
          },

          tooltip: {
            theme: false,
          },
        },
        // series: [
        //   {
        //     name: '% Compliance ',
        //     type: 'line',
        //     data: orgReport.map((item: any) => item.compliance_percentage),
        // },
        // ],

        series: finalData,
      })
    }
    setShowBackDrop(false)
  }

  const exporthandleClick = async (e: any) => {
    let stat1 = selected.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = ComplianceBusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    setShowBackDrop(true)
    const res: any = await Apiservices.export_employee_wise_yearly_compliance_report({
      org_id: localStorage.getItem('org_id'),
      year: selectYear ? moment(selectYear).format('YYYY') : '',
      employee_type: stat2 ? stat2 : '',
      business_unit_id: business2 ? business2 : '',
    })
    var link = document.createElement('a')
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', 'Downloaded File')
    link.href = res?.data?.data?.blobUrl
    document.body.appendChild(link)
    link.click()
    link.remove()
    setShowBackDrop(false)
  }
  const options2: any = [
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ]

  const options3: any = [
    {
      label: 'January',
      value: '1',
    },
    {
      label: 'February',
      value: '2',
    },
    {
      label: 'March',
      value: '3',
    },
    {
      label: 'April',
      value: '4',
    },
    {
      label: 'May',
      value: '5',
    },
    {
      label: 'June',
      value: '6',
    },
    {
      label: 'July',
      value: '7',
    },
    {
      label: 'August',
      value: '8',
    },
    {
      label: 'September',
      value: '9',
    },
    {
      label: 'October',
      value: '10',
    },
    {
      label: 'November',
      value: '11',
    },
    {
      label: 'December',
      value: '12',
    },
  ]

  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const singleDateSelection = async (date: any) => {
    setSelectYear(date)
    setDate('')
  }

  const singleDateSelection1 = async (date: any) => {
    setSelectYear1(date)
    setDate('')
  }

  const handleOrgName = (org: any) => {
    setValue('org_name', org)
    setOrgName(org)
  }

  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    setPage(selected)
    // getallprojects(mainTableData, selected, perPage)
    handleClick1(selected, perPage)
  }

  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      handleClick1(1, value)
    }
  }

  // const handleClick1 = async () => {
  const handleClick1 = async (page: any, perPage: any) => {
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      setValidateDescription(true)
      setProjectReportData([])
      setFlag(false)
    } else {
      setValidateDescription(false)
    }

    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)

    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      console.log('if')
    } else {
      setShowBackDrop(true)
      setFlag(true)
      let article1 = {
        org_id: Number(localStorage.getItem('org_id')),
        // year: selectYear ? moment(selectYear).format('YYYY') : '',
        year: selectYear1 ? moment(selectYear1).format('YYYY') : '',
        month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : '',
        keyword: search ? search : null,
        user_id: employee1 ? employee : null,
        project_id: project1 ? project : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : '',
        pagenumber: null,
        pagesize: null,
      }
      if (perPage == null || perPage == '') {
        const res1: any = await Apiservices.get_overall_active_projects_count_in_selected_months(
          article1
        )
        if (res1.isError == false) {
          setProjectReportCountData(res1.count[0].count)
          var count_All_id = res1.count[0].count
          setProjectReportProjectsData(res1.data)
          var project_All_id = res1.data
        }
      }

      let article = {
        org_id: Number(localStorage.getItem('org_id')),
        // year: selectYear ? moment(selectYear).format('YYYY') : '',
        year: selectYear1 ? moment(selectYear1).format('YYYY') : '',
        month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : '',
        keyword: search ? search : null,
        user_id: employee1 ? employee : null,
        project_id: project1 ? project : project_All_id ? project_All_id : null,
        // is_placement_project: getValues('project_type') ? Number(getValues('project_type')) : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : '',
        pagenumber: page,
        pagesize: perPage == null || perPage == '' ? Number(count_All_id) : Number(perPage),
      }

      const response: any = await Apiservices.get_project_wise_yearly_non_compliance_report(article)
      if (response && response.isError == false) {
        setProjectReportData(response.data)
        // setPages(Math.ceil(response?.count[0]?.count / Number(perPage)))
        if (perPage == null || perPage == '') {
          setPages(Math.ceil(response.count[0].count / Number(count_All_id)))
        } else {
          setPages(Math.ceil(response.count[0].count / Number(perPage)))
        }
        // setProjectReportCountData(response?.count[0]?.count)
      }
      setShowBackDrop(false)
    }
  }
  const exporthandleClick1 = async (e: any) => {
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      setValidateDescription(true)
      setProjectReportData([])
      setFlag(false)
    } else {
      setValidateDescription(false)
    }

    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)

    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')
    if (
      selectMonth == null ||
      selectMonth == [] ||
      selectMonth == '' ||
      selectMonth?.length == 0 ||
      selectMonth == undefined
    ) {
      console.log('if')
    } else {
      setShowBackDrop(true)
      let article1 = {
        org_id: Number(localStorage.getItem('org_id')),
        // year: selectYear ? moment(selectYear).format('YYYY') : '',
        year: selectYear1 ? moment(selectYear1).format('YYYY') : '',
        month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : '',
        user_id: employee1 ? employee : null,
        project_id: project1 ? project : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : null,
      }
      // if (perPage == null || perPage == '') {
      const res1: any = await Apiservices.get_overall_active_projects_count_in_selected_months(
        article1
      )
      if (res1.isError == false) {
        setProjectReportCountData(res1.count[0].count)
        var count_All_id = res1.count[0].count
        setProjectReportProjectsData(res1.data)
        var project_All_id = res1.data
      }
      // }
      let article = {
        org_id: Number(localStorage.getItem('org_id')),
        // year: selectYear ? moment(selectYear).format('YYYY') : '',
        year: selectYear1 ? moment(selectYear1).format('YYYY') : '',
        month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
        employee_type: stat2 ? stat1 : '',
        user_id: employee1 ? employee : null,
        project_id: project1 ? project : project_All_id ? project_All_id : null,
        // is_placement_project: getValues('project_type') ? Number(getValues('project_type')) : null,
        is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : '',
      }
      const response: any = await Apiservices.ExportProjectWiseYearlyNonComplianceReport(article)

      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = response?.data?.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()

      setShowBackDrop(false)
    }
  }
  const handleCountries1 = async (country1: any) => {
    // if (country1 && country1.length >= 2) {
    const response: any = await Apiservices.getprojectbyname({country_name: country1})
    if (response?.data?.data !== null) {
      setCountry1(response && response.data && response.data.data ? response?.data?.data : '')
    }
    // }
  }

  const selectedCountries1 = async (state: any) => {
    setCountries1(state)
  }
  const handleFilterConsultant = async (consult_Name: any) => {
    // let filteredConsultant = candidate?.filter((each: any) =>
    //   each?.full_name?.toLowerCase()?.includes(consult_Name?.toLowerCase())
    // )
    // setCandidateList(filteredConsultant)
    const response: any = await Apiservices.getconsultantbyname()
    setCandidateList(response?.data?.data)
  }

  const selectedConsultant = async (consult_name: any) => {
    setSelectconsultant(consult_name)
  }
  // const handleProjectType = (e: any) => {
  //   setValue('project_type', e.target.value)
  // }

  const handleProjectType = (selected: any) => {
    setSelectProjectType(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllProjectTypeOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectProjectType(x)
          AllProjectTypeOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
        } else if (each.value == 'empty') {
          setSelectProjectType([])
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          AllProjectTypeOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectProjectType(selected)
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }

          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllProjectTypeOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllProjectTypeOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectProjectType(selected)
      AllProjectTypeOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })
    }
  }
  // const handleConsultantType = (select: any) => {
  //   setSelected(select)
  //   setSelectConsultant(select)
  // }

  const handleConsultantType = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelected(x)
          setSelectConsultant(x)
          AllOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }
        } else if (each.value == 'empty') {
          setSelected([])
          setSelectConsultant([])
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions(AllOptions)
          }
          AllOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelected(selected)
          setSelectConsultant(selected)
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            // setAllOptions(AllOptions)
          }
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions(AllOptions)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelected(selected)
      setSelectConsultant(selected)
      AllOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let consultantReset = AllOptions.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelected(selected)
    // setSelectConsultant(selected)
  }
  const resetData1 = async (e: any) => {
    setFlag(false)
    setValue('employee_type', '')
    setOrganizationReportData([])
    setSelectYear(null)
    setSelectconsultant([])
    setSelectConsultant([])
    setSelectProjectType([])
    setBusinessUnit([])
    setSelectMonth([])
    setSelected([])
    setSelectProjectType([])
    setProjectReportData([])
    setComplianceBusinessUnit([])
    // typeaheadRef.current.clear()
    // typeaheadRef1.current.clear() // Month
    setCountries1([])
    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let consultantReset2 = AllOptions2.find((item: any) => item.value == 'All')
    if (!consultantReset2) {
      AllOptions2.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    let consultantReset3 = AllOptions2.find((item: any) => item.value == 'empty')
    if (consultantReset3) {
      AllOptions2.splice(
        AllOptions2.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset2 = BusinessUnitsList2.find((item: any) => item.value == 'All')
    if (!businessReset2) {
      BusinessUnitsList2.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let businessReset3 = BusinessUnitsList2.find((item: any) => item.value == 'empty')
    if (businessReset3) {
      BusinessUnitsList2.splice(
        BusinessUnitsList2.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let monthReset2 = AllMonthOptions.find((item: any) => item.value == 'empty')
    if (monthReset2) {
      AllMonthOptions.splice(
        AllMonthOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectReset1) {
      AllProjectTypeOptions.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let projectReset2 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectReset2) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }

  const resetData2 = async (e: any) => {
    setFlag(false)
    setValue('employee_type', '')
    setValue('work_city', '')
    setSearch('')
    setSelectYear1(null)
    setSelectconsultant([])
    setSelectConsultant([])
    setSelectProjectType([])
    setBusinessUnit([])
    setSelectMonth([])
    setSelectProjectType([])
    setProjectReportData([])
    // typeaheadRef.current.clear()
    // typeaheadRef1.current.clear() // Month
    setCountries1([])

    let consultantReset2 = AllOptions2.find((item: any) => item.value == 'All')
    if (!consultantReset2) {
      AllOptions2.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    let consultantReset3 = AllOptions2.find((item: any) => item.value == 'empty')
    if (consultantReset3) {
      AllOptions2.splice(
        AllOptions2.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset2 = BusinessUnitsList2.find((item: any) => item.value == 'All')
    if (!businessReset2) {
      BusinessUnitsList2.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let businessReset3 = BusinessUnitsList2.find((item: any) => item.value == 'empty')
    if (businessReset3) {
      BusinessUnitsList2.splice(
        BusinessUnitsList2.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let monthReset2 = AllMonthOptions.find((item: any) => item.value == 'empty')
    if (monthReset2) {
      AllMonthOptions.splice(
        AllMonthOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let projectReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    if (!projectReset1) {
      AllProjectTypeOptions.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let projectReset2 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    if (projectReset2) {
      AllProjectTypeOptions.splice(
        AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }
  const handleChange1 = async (value: any) => {
    setSearch(value)
    // setSearch_Open(true)
    setSelectConsultant([])
    setBusinessUnit([])
    setSelectProjectType([])
    setSelectconsultant([])
    setCountries1([])

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let yesterday = moment(new Date()).subtract(1, 'month').format('M')
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let month1 = selectMonth.map((temp: any) => {
      return Number(temp.value)
    })
    month1.sort((a: any, b: any) => a - b)

    let month12 = month1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    let project_type1 = selectProjectType.map((temp: any) => {
      return Number(temp.value)
    })
    let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    let article1 = {
      org_id: Number(localStorage.getItem('org_id')),
      // year: selectYear ? moment(selectYear).format('YYYY') : '',
      year: selectYear1 ? moment(selectYear1).format('YYYY') : '',
      month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: stat2 ? stat1 : '',
      keyword: value,
      user_id: employee1 ? employee : null,
      project_id: project1 ? project : null,
      is_placement_project: project_type2 ? project_type1 : null,
      business_unit_id: business2 ? business1 : '',
      pagenumber: null,
      pagesize: null,
    }
    if (perPage == null || perPage == '') {
      const res1: any = await Apiservices.get_overall_active_projects_count_in_selected_months(
        article1
      )
      if (res1.isError == false) {
        setProjectReportCountData(res1.count[0].count)
        var count_All_id = res1.count[0].count
        setProjectReportProjectsData(res1.data)
        var project_All_id = res1.data
      }
    }

    let article = {
      org_id: Number(localStorage.getItem('org_id')),
      // year: selectYear ? moment(selectYear).format('YYYY') : '',
      year: selectYear1 ? moment(selectYear1).format('YYYY') : '',
      month: month12 ? month1 : [Number(yesterday)], // optional  // (1=January, 12 = December )Pass null for all months data
      employee_type: stat2 ? stat1 : '',
      keyword: value,
      user_id: employee1 ? employee : null,
      project_id: project1 ? project : project_All_id ? project_All_id : null,
      // is_placement_project: getValues('project_type') ? Number(getValues('project_type')) : null,
      is_placement_project: project_type2 ? project_type1 : null,
      business_unit_id: business2 ? business1 : '',
      pagenumber: page,
      pagesize: perPage == null || perPage == '' ? Number(count_All_id) : Number(perPage),
    }

    const response: any = await Apiservices.get_project_wise_yearly_non_compliance_report(article)
    if (response && response.isError == false) {
      setProjectReportData(response.data)
      // setPages(Math.ceil(response?.count[0]?.count / Number(perPage)))
      if (perPage == null || perPage == '') {
        setPages(Math.ceil(response.count[0].count / Number(count_All_id)))
      } else {
        setPages(Math.ceil(response.count[0].count / Number(perPage)))
      }
      // setProjectReportCountData(response?.count[0]?.count)
    }
  }
  const handleConsultantType2 = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions2.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelectConsultant(x)
          AllOptions2.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions2.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions2.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions2(AllOptions2)
          }
        } else if (each.value == 'empty') {
          setSelectConsultant([])
          let cde = AllOptions2.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions2.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions2(AllOptions2)
          }
          AllOptions2.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectConsultant(selected)
          let abc = AllOptions2.find((item: any) => item.value == 'empty')
          let cde = AllOptions2.find((item: any) => item.value == 'All')
          if (!abc) {
            AllOptions2.push({
              label: 'Deselect All',
              value: 'empty',
            })
            // setAllOptions2(AllOptions2)
          }
          if (!cde) {
            AllOptions2.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions2(AllOptions2)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions2.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions2.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectConsultant(selected)
      AllOptions2.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let consultantReset = AllOptions2.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions2.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelectConsultant(selected)
  }
  // const handleBusinessUnit = (select: any) => {
  //   setComplianceBusinessUnit(select)
  //   setBusinessUnit(select)
  // }

  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setComplianceBusinessUnit(x)
          // setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setComplianceBusinessUnit([])
          // setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          // BusinessUnitsList.pop({
          //   name: 'Deselect All',
          //   value: 'empty',
          // })
          let cde1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (cde1) {
            BusinessUnitsList.splice(
              BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
              1
            )
          }
        } else {
          setComplianceBusinessUnit(selected)
          // setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setComplianceBusinessUnit(selected)
      // setBusinessUnit(selected)
      // BusinessUnitsList.pop({
      //   label: 'Deselect All',
      //   value: 'empty',
      // })
      let cde1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
      if (cde1) {
        BusinessUnitsList.splice(
          BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
          1
        )
      }

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const handleBusinessUnit2 = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList2.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList2.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList2.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList2.push({
              name: 'Deselect All',
              value: 'empty',
            })
            // setBusinessUnitsList(BusinessUnitsList2)
            setBusinessUnitsList2(BusinessUnitsList2)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList2.find((item: any) => item.value == 'All')

          if (!cde) {
            BusinessUnitsList2.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList2)
          }
          // BusinessUnitsList2.pop({
          //   name: 'Deselect All',
          //   value: 'empty',
          // })
          let cde1 = BusinessUnitsList2.find((item: any) => item.value == 'empty')
          if (cde1) {
            BusinessUnitsList2.splice(
              BusinessUnitsList2.findIndex((item: any) => item.value == 'empty'),
              1
            )
          }
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList2.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList2.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList2.push({
              name: 'Deselect All',
              value: 'empty',
            })
            // setBusinessUnitsList(BusinessUnitsList2)
            setBusinessUnitsList2(BusinessUnitsList2)
          }
          if (!cde) {
            BusinessUnitsList2.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList2)
            setBusinessUnitsList2(BusinessUnitsList2)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList2.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList2.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      // BusinessUnitsList2.pop({
      //   label: 'Deselect All',
      //   value: 'empty',
      // })
      let cde1 = BusinessUnitsList2.find((item: any) => item.value == 'empty')
      if (cde1) {
        BusinessUnitsList2.splice(
          BusinessUnitsList2.findIndex((item: any) => item.value == 'empty'),
          1
        )
      }

      let businessReset = BusinessUnitsList2.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList2.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }

  const handleMonth = (selected: any) => {
    // setSelectMonth(selected)
    if (selected?.length > 0) {
      setValidateDescription(false)
    }

    if (selected.length > 0) {
      if (selected.length < 4 || selected.find((item: any) => item.value == 'empty')) {
        for (let each of selected) {
          // if (each.value == 'All') {
          //   let x = AllMonthOptions.filter(
          //     (temp: any) => temp.value != 'All' && temp.value != 'empty'
          //   )
          //   setSelectMonth(x)
          //   AllMonthOptions.shift({
          //     label: 'Select All',
          //     value: 'All',
          //   })
          //   let abc = AllMonthOptions.find((item: any) => item.value == 'empty')
          //   if (!abc) {
          //     AllMonthOptions.push({
          //       label: 'Deselect All',
          //       value: 'empty',
          //     })
          //     setAllMonthOptions(AllMonthOptions)
          //   }
          // }
          if (each.value == 'empty') {
            setSelectMonth([])
            let cde = AllMonthOptions.find((item: any) => item.value == 'All')
            // if (!cde) {
            //   AllMonthOptions.unshift({
            //     label: 'Select All',
            //     value: 'All',
            //   })
            //   // setAllMonthOptions(AllMonthOptions)
            // }
            AllMonthOptions.pop({
              label: 'Deselect All',
              value: 'empty',
            })
          } else {
            setSelectMonth(selected)
            let abc = AllMonthOptions.find((item: any) => item.value == 'empty')
            let cde = AllMonthOptions.find((item: any) => item.value == 'All')

            if (!abc) {
              AllMonthOptions.push({
                label: 'Deselect All',
                value: 'empty',
              })
              setAllMonthOptions(AllMonthOptions)
            }
            // if (!cde) {
            //   AllMonthOptions.unshift({
            //     label: 'Select All',
            //     value: 'All',
            //   })
            //   setAllMonthOptions(AllMonthOptions)
            // }
            let check_drop = selected.find((item: any) => item.value == 'All')
            let check_drop1 = selected.find((item: any) => item.value == 'empty')

            if (!check_drop && !check_drop1) {
              let test = AllMonthOptions.filter(
                (id: any) => !selected.some((x: any) => x.value == id.value)
              )
              if (test.length == 2) {
                let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
                // AllMonthOptions.shift({
                //   label: 'Select All',
                //   value: 'All',
                // })
              }
            }
          }
        }
      }
    } else {
      setSelectMonth(selected)
      let monthReset1 = AllMonthOptions.find((item: any) => item.value == 'empty')
      if (monthReset1) {
        AllMonthOptions.pop({
          label: 'Deselect All',
          value: 'empty',
        })
      }
    }
  }
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.COMPLIANCEREPORTS'})}</PageTitle> */}
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title> Compliance Report </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <div className='d-flex justify-content-between align-items-center'>
            <div> </div>
          </div>
          <BackDrop showBackDrop={showBackDrop} />
          <div className='py-1'>
            <div className='card card-custom card-flush '>
              <div className='card-body  py-1 px-5'>
                <div className='row gy-3 align-items-center py-2'>
                  <div className='col-xs-12 col-md-3 col-lg-3 '>
                    <label className='form-label '>Select Year</label>
                    {/* <select name='selectYear' onChange={yearSelect} value={selectYear}> */}
                    {/* <select
                className={clsx('form-select', errors.select_Year ? 'is-invalid' : '')}
                value={selectYear}
                {...register('select_Year', {
                  required: true,
                  onChange: (e) => {
                    yearSelect(e.target.value)
                  },
                })}
              >
                <option value=''>Select Year</option>
                {years &&
                  years.length > 0 &&
                  years.map((value: any) => {
                    return <option value={value}>{value}</option>
                  })}
              </select> */}
                    <DatePicker
                      selected={selectYear}
                      onChange={(date: any) => singleDateSelection(date)}
                      placeholderText='Select Year'
                      className={clsx('form-control')}
                      dateFormat='yyyy'
                      showYearPicker
                      required
                    />
                  </div>
                  {/* <div className='col-xs-12 col-md-3 col-lg '>
                <label className='form-label '>Select Organization</label>
                <select
                  className={clsx('form-select', errors.org_name ? 'is-invalid' : '')}
                  // {...register('org_name', {
                  //   required: true,
                  // })}
                  {...register('org_name', {
                    required: true,
                    onChange: (e) => {
                      handleOrgName(e.target.value)
                    },
                  })}
                >
                  <option value=''>Select Organization </option>
                  {orgList &&
                    orgList.length > 0 &&
                    orgList.map((item: any) => {
                      return <option value={item.org_id}>{item.org_name}</option>
                    })}
                </select>
              </div> */}
                  <div className='col-xs-12 col-md-3 col-lg-3'>
                    <label className='form-label '>Consultant Type</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        handleConsultantType(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef.current.toggleMenu()
                      }}
                      options={options2}
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef}
                      selected={selected}
                    /> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        handleConsultantType(selected)
                        // setSelectConsultant(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef.current.toggleMenu()
                      }}
                      options={AllOptions}
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef}
                      selected={selected}
                    />

                    <div className='invalid-feedback'>{errors.employee_type?.message}</div>
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg-3'>
                    <label className='form-label '>Business Unit</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(select: any) => {
                        handleBusinessUnit(select)
                        typeaheadRef8.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef8}
                      placeholder='Select Business Unit'
                      selected={ComplianceBusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    ></Typeahead> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        // setBusinessUnit(e)
                        handleBusinessUnit(e)
                        typeaheadRef8.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef8}
                      placeholder='Select Business Unit'
                      selected={ComplianceBusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    ></Typeahead>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      onClick={() => handleClick()}
                    >
                      Run
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={resetData1}
                    >
                      Reset
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      onClick={exporthandleClick}
                    >
                      {' '}
                      Export
                      {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {OrganizationReportData && OrganizationReportData?.series && (
            <div className='py-1'>
              <div id='chart'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-2 py-1'>
                    <div className='row justify-content-center'>
                      <div className='col-11'>
                        <Chart
                          options={OrganizationReportData.options}
                          series={OrganizationReportData.series}
                          type='line'
                          // width='100%'
                          height={450}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='py-1'>
            <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Non Compliance Data</h3>
            <div className='card card-custom card-flush '>
              <div className='card-body  py-1 px-5'>
                <div className='row gy-3 align-items-center py-2'>
                  <div className='col-xs-12 col-md-3 col-lg '>
                    <label className='form-label '> Year</label>
                    <DatePicker
                      selected={selectYear1}
                      onChange={(date: any) => singleDateSelection1(date)}
                      placeholderText='Select Year'
                      className={clsx('form-control')}
                      // disabled={true}
                      dateFormat='yyyy'
                      showYearPicker
                      required
                    />
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg '>
                    <label className='form-label '>Select Month *</label> (Maximum 3 months)
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        setSelectMonth(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef1.current.toggleMenu()
                      }}
                      options={options3}
                      placeholder='Select Month'
                      ref={typeaheadRef1}
                      selected={selectMonth}
                    /> */}
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        // setSelectMonth(selected)
                        handleMonth(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef1.current.toggleMenu()
                      }}
                      options={AllMonthOptions}
                      placeholder='Select Month'
                      ref={typeaheadRef1}
                      selected={selectMonth}
                      // inputProps={{
                      //   required: true,
                      // }}
                    />
                    {validatedescription && (
                      <span className='error text-danger mb-0'>Month is required</span>
                    )}
                    <div className='invalid-feedback'>{errors.employee_type?.message}</div>
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Consultant Type</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        setSelectConsultant(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef2.current.toggleMenu()
                      }}
                      options={options2}
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef2}
                      selected={selectConsultant}
                    /> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        handleConsultantType2(selected)
                        // setSelectConsultant(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef2.current.toggleMenu()
                      }}
                      options={AllOptions2}
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef2}
                      selected={selectConsultant}
                    />
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Business Unit</label>
                    {/* <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        setBusinessUnit(e)
                        typeaheadRef7.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef7}
                      placeholder='Select Business Unit'
                      selected={BusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    >
                    
                    </Typeahead> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        // setBusinessUnit(e)
                        handleBusinessUnit2(e)
                        typeaheadRef7.current.toggleMenu()
                      }}
                      options={BusinessUnitsList2}
                      ref={typeaheadRef7}
                      placeholder='Select Business Unit'
                      selected={BusinessUnit}
                      labelKey={(BusinessUnitsList2: any) => BusinessUnitsList2.name}
                      //   inputProps={{required: true}}
                    ></Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Project Type </label>
                    {/* <select
                      // value={selectedItem}
                      {...register('project_type')}
                      onChange={handleProjectType}
                      className={clsx('form-select')}
                    >
                      <option value=''>Select Project Type</option>
                      <option value='1'>Placement</option>
                      <option value='2'>Bench Project</option>
                      <option value='3'>Internal</option>
                    </select> */}

                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        // setSelectProjectType(selected)
                        handleProjectType(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef5.current.toggleMenu()
                      }}
                      options={AllProjectTypeOptions}
                      placeholder='Select Project Type'
                      ref={typeaheadRef5}
                      selected={selectProjectType}
                    />
                    {/* <div className='invalid-feedback'>{errors.user_status?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Employee Name</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        selectedConsultant(e)
                        typeaheadRef3.current.toggleMenu()
                      }}
                      onInputChange={(e) => handleFilterConsultant(e)}
                      options={candidateList}
                      ref={typeaheadRef3}
                      placeholder='Search Employee'
                      selected={select_consultant}
                      labelKey={(candidateList: any) => user(candidateList.full_name)}
                      //   inputProps={{required: true}}
                    >
                      {/* {({onClear, selected}) => (
                    <div className='rbt-aux'>
                      {!!selected.length && (
                        <ClearButton disabled={AllFieldsToDisabled} onClick={onClear} />
                      )}
                    </div>
                  )} */}
                    </Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Project Name</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(e) => {
                        selectedCountries1(e)
                        typeaheadRef4.current.toggleMenu()
                      }}
                      onInputChange={(e) => {
                        handleCountries1(e)
                      }}
                      placeholder='Please Enter Project Name'
                      ref={typeaheadRef4}
                      options={country1}
                      selected={countries1}
                      labelKey={(country1: any) => country1.project_name}
                    >
                      {/* {({onClear, selected}) => (
                            <div className='rbt-aux'>
                              {!!selected.length && <ClearButton onClick={onClear} />}
                            </div>
                          )} */}
                    </Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Search</label>
                    <input
                      type='text'
                      id='form1'
                      className='form-control'
                      // value={search}
                      {...register('work_city')}
                      placeholder='Search'
                      onChange={(e) => handleChange1(e.target.value)}
                    />
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      // onClick={() => handleClick1()}
                      onClick={() => handleClick1(page, perPage)}
                      disabled={selectMonth.length > 3 ? true : false}
                    >
                      Run
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={resetData2}
                    >
                      Reset
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      onClick={exporthandleClick1}
                      disabled={selectMonth.length > 3 ? true : false}
                    >
                      Export
                      {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {flag == true && (
            <>
              <KTCardBody className='py-1 '>
                <div className='pt-3'>
                  <div className='card card-custom card-flush '>
                    <div className='card-body p-3 py-3'>
                      <div className='table-responsive padding-down reportTab'>
                        <table
                          id='kt_table_users'
                          className='table table-row-bordered table-row-gray-300 gy-4'
                        >
                          <>
                            <thead>
                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                <th
                                  onClick={() => sorting6('user_business_unit_name')}
                                  className='  py-4'
                                >
                                  Business Unit{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting6('employee_id')} className='  py-4'>
                                  Consultant ID{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting7('display_name', 'full_name')}
                                  className='  py-4'
                                >
                                  Consultant Name{' '}
                                  <span>
                                    {order7 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting6('placement_code')} className='  py-4'>
                                  Placement Code{' '}
                                  <span>
                                    {order6 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('project_name')} className='  py-4'>
                                  Project Name{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting5('is_placement_project')}
                                  className='  py-4'
                                >
                                  Project Type{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting5('project_status_name')}
                                  className='  py-4'
                                >
                                  Project Status{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting9('project_joining_date', 'start_date')}
                                  className='  py-4'
                                >
                                  Start Date <span className='fs-9'>(DD-MMM-YYYY)</span>{' '}
                                  <span>
                                    {order9 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() =>
                                    sorting12('project_status_name', 'cancel_date', 'end_date')
                                  }
                                  className='  py-4'
                                >
                                  End Date <span className='fs-9'>(DD-MMM-YYYY)</span>{' '}
                                  <span>
                                    {order12 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('employee_type')} className='  py-4'>
                                  Consultant Type{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() => sorting5('timesheet_missed_date')}
                                  className='  py-4'
                                >
                                  Timesheet Missed Date{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('month_name')} className='  py-4'>
                                  {' '}
                                  Month{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting8('hours_submitted')} className='  py-4'>
                                  Hours Submitted{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting8('expected_hours')} className='  py-4'>
                                  Actual Hours to be submitted{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {ProjectReportData?.length > 0 ? (
                                <>
                                  {ProjectReportData?.map((each: any, index: any) => (
                                    <tr
                                      className='namepage text-dark fw-semibold  fs-6 '
                                      key={index}
                                    >
                                      <td className=''>
                                        {each.user_business_unit_name
                                          ? each.user_business_unit_name
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.employee_id ? each.employee_id : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {each.display_name
                                          ? each.display_name
                                          : each.full_name
                                          ? each.full_name
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.placement_code ? each.placement_code : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {each.project_name ? each.project_name : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {/* {each.is_placement_project == false ? 'Bench' : 'Placement'} */}
                                        {each.is_placement_project
                                          ? each.is_placement_project == 1
                                            ? 'Placement'
                                            : each.is_placement_project == 2
                                            ? 'Bench'
                                            : each.is_placement_project == 3
                                            ? 'Internal'
                                            : '-'
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.project_status_name ? each.project_status_name : '-'}
                                      </td>
                                      <td className='text-nowrap'>
                                        {each.project_joining_date
                                          ? moment(each.project_joining_date).format('DD-MMM-YYYY')
                                          : each.start_date
                                          ? moment(each.start_date).format('DD-MMM-YYYY')
                                          : '-'}
                                      </td>
                                      <td className='text-nowrap'>
                                        {each.project_status_name
                                          ? each.project_status_name?.toLowerCase() == 'cancelled'
                                            ? each.cancel_date
                                              ? moment(each.cancel_date).format('DD-MMM-YYYY')
                                              : '-'
                                            : each.project_status_name?.toLowerCase() == 'completed'
                                            ? each.end_date
                                              ? moment(each.end_date).format('DD-MMM-YYYY')
                                              : '-'
                                            : '-'
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.employee_type ? each.employee_type : '-'}
                                      </td>
                                      <td className='text-nowrap'>
                                        {each.timesheet_missed_date
                                          ? moment(each.timesheet_missed_date).format('DD-MMM-YYYY')
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.month_name
                                          ? moment(each.month_name, 'MMMM').format('MMM')
                                          : '-'}{' '}
                                        {moment(selectYear1).format('YYYY')}
                                      </td>
                                      {/* <td className=''>
                                    {each.hours_submitted ? each.hours_submitted : '-'}
                                  </td> */}
                                      <td className=''>
                                        {each.hours_submitted
                                          ? each.hours_submitted.toFixed(2)
                                          : '0.00'}
                                      </td>
                                      {/* <td className=''>
                                    <span>
                                      {each.expected_hours <= 9
                                        ? '0' + each.expected_hours
                                        : each.expected_hours}
                                      :{'00'}
                                    </span>
                                  </td> */}
                                      <td className=''>
                                        <span>
                                          {each.expected_hours
                                            ? each.expected_hours.toFixed(2)
                                            : '0.00'}
                                        </span>
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </>
                        </table>
                        {(ProjectReportData?.length == null || ProjectReportData?.length == 0) && (
                          <div className='text-center  fw-bold text-gray-800'>
                            No Records found{' '}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <UsersListPagination />
              </KTCardBody>
              <div className='d-flex flex-stack py-2'>
                {ProjectReportData?.length > 0 ? (
                  <div>
                    <Container className='p-0'>
                      <Row className='align-items-center'>
                        <Col xs='auto' className='pe-0'>
                          {' '}
                          <label className='form-label fw-semibold'>No. of projects</label>
                        </Col>

                        <Col>
                          <select
                            name='perPage'
                            className='form-select'
                            onChange={onChange}
                            value={perPage}
                          >
                            {/* <option value='10'>10</option> */}
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='75'>75</option>
                            {/* <option value={ProjectReportDataCount}>All</option> */}
                            <option value=''>All</option>
                          </select>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                ) : null}

                <div className='pr-150'>
                  {ProjectReportData?.length > 0 ? (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      pageCount={pages}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination justify-content-right'}
                      activeClassName={'active'}
                      breakLabel={'...'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select any one and click run to view the result
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TimesheetComplianceReports
