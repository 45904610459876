import React, {useState, useMemo, useEffect, useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ThingsContext from '../../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../../modules/apps/masters/commonicons/ValidateContext'
import {default as Apiservices} from '../../../../common/index'
import {useForm} from 'react-hook-form'
import 'reactjs-popup/dist/index.css'
import {PageTitle} from '../../../../_metronic/layout/core'
import {
  useQueryResponseData,
} from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import ProjectQBPop from '../../../modules/apps/masters/modal/ProjectQBPop'

type FormData = {
  client_name: string
  business_unit: string
  dba: string
  ccdb_id: string
  client_code: string
  qb_customer_name: string
  netSuite_customer_name: string
  qb_customer_id: string
  netSuite_customer_id: string
  id: number
  record_type_status: string
}

function UpdateClient(props: any) {
  const location = useLocation()
  const {
    register,
    setValue,
    reset,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>()

  const [userlist, setUserList] = useState<any | []>([])
  const users = useQueryResponseData()
  const data = useMemo(() => users, [users])
  const [client_name, setClientName] = useState<any | []>([])
  const [clientopen, setClientOpen] = useState(false)
  const [netopen, setnetOpen] = useState(false)
  const [disableQBClient, setDisableQBClient] = useState(false)
  const [disableNetSuiteClient, setDisableNetSuiteClient] = useState(false)
  const [QBCustomerPop, setQBCustomerPop] = useState(false)
  const [disableQuickBooks, setDisableQuickBooks] = useState(false)
  const [disableNetSuite, setDisableNetSuite] = useState(false)
  const [OldObj, setOldObj] = useState<any>({})
  const [disablecustomerdatabynameQuickBooks, setDisablecustomerdatabynameQuickBooks] =
    useState(false)
  const [IndividualBU, setIndividualBU] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const navigate = useNavigate()
  const intl = useIntl()

  const diffToast = () => {
    toast.success('Updated Successfully', {
      position: 'top-center',
    })
  }

  const FailedToast = () => {
    toast.warn('Failed update', {
      position: 'top-center',
    })
  }

  const onChange = async () => {
    const data: any = await Apiservices.getcustomerdatabyname({
      name: getValues('qb_customer_name'),
      business_unit_id: userlist?.business_unit_id,
    })
    if (data.data.isError == false) {
      toast.success('Client ID fetched Successfully')
      setValue('qb_customer_id', data.data.data.QueryResponse.Customer[0].Id)
      setClientOpen(true)
      setDisableQBClient(true)
    } else if (data.data.isError == true) {
      toast.error('Enter valid QB Client name')
    }
    // toast.error('Enter valid QB Customer name')
  }
  const onChange1 = async () => {
    let data5: any = {
      id: userlist?.id,
      client_name: getValues('netSuite_customer_name'),
      subsidiaryName: localStorage.getItem('subsidary_name'),
      entitystatus: 13,
      client_code: userlist?.client_code,
    }
    if (userlist.netsuite_customer_id) {
      let NewObj: any = {
        id: userlist?.id,
        client_name: getValues('netSuite_customer_name'),
        subsidiaryName: localStorage.getItem('subsidary_name'),
        entitystatus: 13,
        client_code: userlist?.client_code,
      }
      let keys1 = Object.keys(OldObj)
      let arr_obj = []
      let arr11: any = {}
      for (let key of keys1) {
        if (OldObj[key] !== NewObj[key]) {
          let arr = key
          arr11[arr] = NewObj[key]
        }
      }
      if (Object.keys(arr11).length > 0) {
        arr_obj.push(arr11) // Push arr11 into arr_obj if it contains changes
      } else {
        arr11['client_name'] = getValues('netSuite_customer_name')
        arr_obj.push(arr11)
      }
      if (arr_obj.length > 0) {
        const upda: any = await Apiservices.updateNetSuiteCustomer(userlist.netsuite_customer_id, {
          arr_obj,
        })
        if (upda.statusCode == 200) {
          if (upda.message == 'SUCCESS') {
            setnetOpen(true)
            setDisableNetSuiteClient(true)
            setValue('netSuite_customer_id', upda.netsuiteId)
            toast.success('NetSuite details are updated successfully')
          }
        }
      }
    } else {
      const res: any = await Apiservices.getCustomer(data5)
      if (res && res.statusCode == 200) {
        if (res && res.message == 'SUCCESS') {
          setValue('netSuite_customer_id', res.netsuiteId)
        } else if (res && res.message == 'This entity already exists') {
          setValue('netSuite_customer_id', res.data.netsuiteId)
        }

        toast.success('NetSuite ID fetched Successfully')

        setnetOpen(true)
        setDisableNetSuiteClient(true)
      } else {
        toast.error('Enter valid QB Client name')
      }
    }
  }
  // const EditQB = () => {
  //   setDisableQBClient(false)
  //   // setDisabled(false)
  //   setClientOpen(false)
  //   setValue('qb_customer_id', '')
  // }

  const EditQBCustomerPop = () => {
    setQBCustomerPop(true)
  }

  const EditQBCustomerOkPop = () => {
    setQBCustomerPop(false)
    setDisableQBClient(false)
    setDisableNetSuiteClient(false)
    setClientOpen(false)
    setnetOpen(false)
    setValue('qb_customer_id', '')
    setValue('netSuite_customer_id', '')
  }

  const EditQBCustomerCancelPop = () => {
    setQBCustomerPop(false)
  }

  const onSubmit = async (data: FormData) => {
    // let organization = localStorage.getItem('organization_job')
    let orgId = localStorage.getItem('logged_in_id')

    const article = {
      id: userlist.id,
      is_placement_client: userlist.is_placement_client,
      record_type_status: userlist.record_type_status,
      qb_vendor_name: userlist.qb_vendor_name,
      qb_vendor_id: userlist.qb_vendor_id,
      placement_client_id: userlist.placement_client_id,
      updatedate: userlist.updatedate,
      updatedby: userlist.updatedby,
      org_id: orgId,
      customer_id: userlist.customer_id,
      createdby: userlist.createdby,
      createdate: userlist.createdate,
      client_name: data.client_name,
      // qb_customer_name: data.client_name,
      dba: data.dba,
      ccdb_id: Number(data.ccdb_id),
      qb_customer_name: data.qb_customer_name,
      qb_customer_id: data.qb_customer_id ? Number(data.qb_customer_id) : null,
      netsuite_customer_name: data.netSuite_customer_name,
      netsuite_customer_id: getValues('netSuite_customer_id')
        ? getValues('netSuite_customer_id')
        : null,
      business_unit_id: userlist.business_unit_id,
      // org_id: orgId,
    }
    if (getValues('netSuite_customer_id')) {
      let NewObj: any = {
        id: userlist?.id,
        client_name: getValues('netSuite_customer_name'),
        subsidiaryName: localStorage.getItem('subsidary_name'),
        entitystatus: 13,
        client_code: data?.client_code,
      }
      let keys1 = Object.keys(OldObj)
      let arr_obj = []
      let arr11: any = {}
      for (let key of keys1) {
        if (OldObj[key] !== NewObj[key]) {
          let arr = key
          arr11[arr] = NewObj[key]
        }
      }
      if (Object.keys(arr11).length > 0) {
        arr_obj.push(arr11) // Push arr11 into arr_obj if it contains changes
      }

      if (arr_obj.length > 0) {
        const upda: any = await Apiservices.updateNetSuiteCustomer(
          getValues('netSuite_customer_id'),
          {
            arr_obj,
          }
        )
        if (upda.statusCode == 200) {
          toast.success('NetSuite details are updated successfully')
        }
      }
    }
    const Response: any = await Apiservices.updateClient(article)

    // axios.put('http://192.168.92.54:8899/api/v1/client/editclient', article)
    // .then((response) => {

    if (Response.message === 'Updated Successfully') {
      toast.success('Updated Successfully')
      navigate('/manageclients')
    } else if (Response.message === 'Failed to update') {
      FailedToast()
    } else if (Response.isError == true) {
      toast.warn('client name already exists')
    }
    // })
  }

  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }

  useEffect(() => {
    renderThings()
  }, [])

  const tokenHeader1: any = useContext(ValidContext)
  const renderThings1 = async () => {
    tokenHeader1.getValidate()
  }

  // useEffect(() => {
  //   renderThings1()
  // }, [])

  const getOrganizationSetting = async () => {
    let get_org_id = localStorage.getItem('org_id')
    const res: any = await Apiservices.getOrganizationSetting({org_id: get_org_id})
    if (res.data.isError == false) {
      setDisableQuickBooks(res.data.data[0].enable_quickbooks)
      setDisableNetSuite(res.data.data[0].enable_netsuite)
      localStorage.setItem('enable_quickbooks', res.data.data[0].enable_quickbooks)
      localStorage.setItem('enable_netsuite', res.data.data[0].enable_netsuite)
      localStorage.setItem('subsidary_name', res.data.data[0].subsidary_name)
    }
  }

  const getQBTransactionsSettingByName = async () => {
    const res: any = await Apiservices.getQBTransactionsSettingByName({
      name: 'getcustomerdatabyname',
    })
    setDisablecustomerdatabynameQuickBooks(res?.data?.data?.[0]?.is_enabled)
  }

  useEffect(() => {
    getOrganizationSetting()
    // getQBTransactionsSettingByName()
  }, [])

  useEffect(() => {
    let state: any = location.state
    const viewEmployee = async (id: any) => {
      if (location.state) {
        const response: any = await Apiservices.getclientbyid({id: location.state})
        if (response.data.isError == false) {
          const res: any = await Apiservices.getQBTransactionsSettingByName({
            name: 'getcustomerdatabyname',
            business_unit_id: response?.data?.data?.business_unit_id,
          })
          setDisablecustomerdatabynameQuickBooks(res?.data?.data?.[0]?.is_enabled)
          const business1: any = await Apiservices.get_business_units_list()
          if (business1 && business1.status == 200) {
            if (response?.data?.data?.business_unit_id) {
              let businessValueValidating = business1?.data?.data.filter(
                (bus: any) => bus.business_unit_id == response?.data?.data?.business_unit_id
              )
              if (businessValueValidating?.length > 0) {
                setIndividualBU(
                  businessValueValidating &&
                    businessValueValidating[0] &&
                    businessValueValidating[0].enable_quickbooks
                )
              }
            }
          }
          setUserList(response.data.data)
          if (response.data.data.qb_customer_id) {
            setClientOpen(true)
            setDisableQBClient(true)
            setDisabled(true)
          }

          let data5: any = {
            id: response?.data?.data?.id,
            client_name: response?.data?.data?.netsuite_customer_name
              ? response?.data?.data?.netsuite_customer_name
              : response?.data?.data?.client_name,
            subsidiaryName: localStorage.getItem('subsidary_name'),
            entitystatus: 13,
            client_code: response?.data?.data?.client_code,
          }
          setOldObj(data5)

          if (response.data.data.netsuite_customer_id) {
            setnetOpen(true)
            setDisableNetSuiteClient(true)
            setDisabled(true)
          }
          let dataSet = {
            ...response.data.data,
            qb_customer_name: response?.data?.data?.qb_customer_name
              ? response?.data?.data?.qb_customer_name
              : response?.data?.data?.client_name,
            business_unit: response?.data?.data?.business_unit_name,
            netSuite_customer_name: response?.data?.data?.netsuite_customer_name
              ? response?.data?.data?.netsuite_customer_name
              : response?.data?.data?.client_name,
            netSuite_customer_id: response?.data?.data?.netsuite_customer_id,
          }
          reset(dataSet)
        }
      }
    }
    if (location.state) {
      viewEmployee(state.id)
    }
  }, [])
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  return (
    <>
      {QBCustomerPop ? (
        <ProjectQBPop
          close={EditQBCustomerOkPop}
          cancel={EditQBCustomerCancelPop}
          QBName={'ProjectQB'}
        />
      ) : (
        ''
      )}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADDCLIENT'})}</PageTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='card card-custom card-flush mb-6'>
          <div className='card-body py-5'>
            <div className='row gy-4 align-items-end'>
              <div className='col-xs-12 col-md-3'>
                <label className='form-label fs-8 fw-semibold'>Business Unit</label>
                <input
                  type='text'
                  className='form-control  fs-6'
                  disabled={true}
                  //  value={item.client_name}
                  {...register('business_unit')}
                />
              </div>
              <div className='col-xs-12 col-md-3'>
                <label className='form-label fs-8 fw-semibold'>Client Name</label>
                <input
                  type='text'
                  className='form-control  fs-6'
                  disabled={disabled}
                  //  value={item.client_name}
                  {...register('client_name')}
                />
              </div>
              <div className='col-xs-12 col-md-3'>
                <label className='form-label fs-8 fw-semibold'>DBA Name</label>
                <input
                  type='text'
                  className='form-control  fs-6'
                  disabled={disabled}
                  // value={item.dba}

                  {...register('dba')}
                />
              </div>
              <div className='col-xs-12 col-md-3'>
                <label className='form-label fs-8 fw-semibold'>CCDB Reference ID</label>
                <input
                  type='text'
                  className='form-control  fs-6'
                  // value={item.ccdb_id}
                  disabled={disabled}
                  {...register('ccdb_id')}
                />
              </div>
              <div className='col-xs-12 col-md-3'>
                <label className='form-label fs-8 fw-semibold'>Client Code</label>
                <input
                  type='text'
                  className='form-control  fs-6'
                  disabled={true}
                  {...register('client_code')}
                />
              </div>

              {/* {localStorage.getItem('refresh_Token') == 'false' && (
                <>
                  {(numArray?.includes('superadmin') ||
                    numArray?.includes('admin') ||
                    numArray?.includes('accounts')) && (
                    <>
                      {disableQuickBooks && ( //Main Org Level
                        <>
                          {IndividualBU && (
                            <>
                              {disablecustomerdatabynameQuickBooks && (
                                <>
                                  <div className='col-xs-12 col-md-3'>
                                    <label className='form-label fs-8 fw-semibold'>
                                      QB Client Name
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control  fs-6'
                                      disabled={disableQBClient}
                                      // {...register('client_name')}
                                      onClick={(e: any) => {
                                        // setValue(`qb_customer_name`, `client_name`)
                                      }}
                                      {...register('qb_customer_name', {
                                        onChange: (e) => {
                                          setClientName(e.target.value)
                                        },
                                      })}
                                    />
                                  </div>
                                  {clientopen && (
                                    <div className='col-xs-12 col-md-3'>
                                      <label className='form-label fs-8 fw-semibold'>
                                        QB Client ID
                                      </label>
                                      <input
                                        type='text'
                                        className='form-control  fs-6'
                                        disabled={true}
                                        {...register('qb_customer_id')}
                                      />
                                    </div>
                                  )}

                                  {getValues('qb_customer_id') ? (
                                    <></>
                                  ) : (
                                    <div className='col-xs-12 col-md-3'>
                                      <button
                                        className='btn btn-secondary form-group '
                                        type='button'
                                        onClick={onChange}
                                        aria-disabled='true'
                                      >
                                        Get QB Client ID
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )} */}

              {/* {localStorage.getItem('refresh_Token') == 'false' && (
                <>
                  {(numArray?.includes('superadmin') ||
                    numArray?.includes('admin') ||
                    numArray?.includes('accounts')) && (
                    <>
                      {disableQuickBooks && (
                        <>
                          {IndividualBU && (
                            <>
                              {disablecustomerdatabynameQuickBooks && (
                                <>
                                  {getValues('qb_customer_id') ? (
                                    <div className='col-xs-12 col-md-3 '>
                                      <button
                                        className='btn btn-secondary form-group mt-8'
                                        type='button'
                                        onClick={() => EditQBCustomerPop()}
                                        aria-disabled='true'
                                      >
                                        Edit QB Client ID
                                      </button>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )} */}

              {/* {(numArray?.includes('superadmin') ||
                numArray?.includes('admin') ||
                numArray?.includes('accounts')) && (
                <>
                  {disableNetSuite && (
                    <>
                      <div className='col-12 m-0'></div>
                      <div className='col-xs-12 col-md-3'>
                        <label className='form-label fs-8 fw-semibold'>NetSuite Client Name</label>
                        <input
                          type='text'
                          className='form-control  fs-6'
                          disabled={disableNetSuiteClient}
                          // {...register('client_name')}
                          onClick={(e: any) => {
                            // setValue(`netSuite_customer_name`, `client_name`)
                          }}
                          {...register('netSuite_customer_name', {
                            onChange: (e) => {
                              setClientName(e.target.value)
                            },
                          })}
                        />
                      </div>
                      {netopen && (
                        <div className='col-xs-12 col-md-3'>
                          <label className='form-label fs-8 fw-semibold'>NetSuite Client ID</label>
                          <input
                            type='text'
                            className='form-control  fs-6'
                            disabled={true}
                            {...register('netSuite_customer_id')}
                          />
                        </div>
                      )}

                      {getValues('netSuite_customer_id') ? (
                        <></>
                      ) : (
                        <div className='col-xs-12 col-md-3'>
                          <button
                            className='btn btn-secondary form-group '
                            type='button'
                            onClick={onChange1}
                            aria-disabled='true'
                          >
                            Get NetSuite Client ID
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </>
              )} */}

              {/* {(numArray?.includes('superadmin') ||
                numArray?.includes('admin') ||
                numArray?.includes('accounts')) && (
                <>
                  {disableNetSuite && (
                    <>
                      {getValues('netSuite_customer_id') ? (
                        <div className='col-xs-12 col-md-3 '>
                          <button
                            className='btn btn-secondary form-group mt-8'
                            type='button'
                            onClick={() => EditQBCustomerPop()}
                            aria-disabled='true'
                          >
                            Edit NetSuite Client ID
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )} */}
            </div>
            {/* <div className='d-flex flex-stack  py-5 '>
              <div>
                {localStorage.getItem('refresh_Token') == 'false' && (
                  <>
                    {localStorage.getItem('role_Name') === 'admin' && (
                      <button
                        className='btn btn-light-danger form-group '
                        type='button'
                        onClick={() => EditQB()}
                        aria-disabled='true'
                      >
                        Do you want to Edit QB Fields
                      </button>
                    )}
                  </>
                )}
              </div>
            </div> */}
            {/* <div>
              <div className='d-flex  justify-content-end gap-3 py-1 pt-5'>
                <div>
                  <button
                    type='submit'
                    // onClick={() => reset()}
                    className='btn btn-primary'
                    // onClick={diffToast}
                  >
                    Update
                  </button>
                </div>
                <div>
                  <button type='button' onClick={() => navigate(-1)} className='btn btn-danger'>
                    Cancel
                  </button>
                </div>
              </div>
            </div> */}

            <ToastContainer />
          </div>
        </div>

        {localStorage.getItem('refresh_Token') == 'false' && (
          <>
            {/* {(localStorage.getItem('role_Name') === 'superadmin' ||
                    localStorage.getItem('role_Name') === 'admin' ||
                    localStorage.getItem('role_Name') === 'accounts') && ( */}
            {(numArray?.includes('superadmin') ||
              numArray?.includes('admin') ||
              numArray?.includes('accounts')) && (
              <>
                {disableQuickBooks && ( //Main Org Level
                  <>
                    {IndividualBU && (
                      <>
                        {disablecustomerdatabynameQuickBooks && (
                          <>
                            <div className='card card-custom card-flush mb-6'>
                              <div className='card-body py-5'>
                                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>
                                  QB Details{' '}
                                </h3>
                                <div className='row gy-4'>
                                  <div className='col-xs-12 col-md-3'>
                                    <label className='form-label fs-8 fw-semibold'>
                                      QB Client Name
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control  fs-6'
                                      disabled={disableQBClient}
                                      // {...register('client_name')}
                                      onClick={(e: any) => {
                                        // setValue(`qb_customer_name`, `client_name`)
                                      }}
                                      {...register('qb_customer_name', {
                                        onChange: (e) => {
                                          setClientName(e.target.value)
                                        },
                                      })}
                                    />
                                  </div>
                                  {clientopen && (
                                    <div className='col-xs-12 col-md-3'>
                                      <label className='form-label fs-8 fw-semibold'>
                                        QB Client ID
                                      </label>
                                      <input
                                        type='text'
                                        className='form-control  fs-6'
                                        disabled={true}
                                        {...register('qb_customer_id')}
                                      />
                                    </div>
                                  )}

                                  {getValues('qb_customer_id') ? (
                                    <div className='col-xs-12 col-md-3 '>
                                      <button
                                        className='btn btn-secondary form-group mt-8'
                                        type='button'
                                        onClick={() => EditQBCustomerPop()}
                                        aria-disabled='true'
                                      >
                                        Edit QB Client ID
                                      </button>
                                    </div>
                                  ) : (
                                    <div className='col-xs-12 col-md-3'>
                                      <button
                                        className='btn btn-secondary form-group '
                                        type='button'
                                        onClick={onChange}
                                        aria-disabled='true'
                                      >
                                        Get QB Client ID
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}

        {(numArray?.includes('superadmin') ||
          numArray?.includes('admin') ||
          numArray?.includes('accounts')) && (
          <>
            {disableNetSuite && (
              <>
                <div className='card card-custom card-flush mb-6'>
                  <div className='card-body py-5'>
                    <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>NetSuite Details </h3>
                    <div className='row gy-4'>
                      <div className='col-xs-12 col-md-3'>
                        <label className='form-label fs-8 fw-semibold'>NetSuite Client Name</label>
                        <input
                          type='text'
                          className='form-control  fs-6'
                          disabled={disableNetSuiteClient}
                          // {...register('client_name')}
                          onClick={(e: any) => {
                            // setValue(`netSuite_customer_name`, `client_name`)
                          }}
                          {...register('netSuite_customer_name', {
                            onChange: (e) => {
                              setClientName(e.target.value)
                            },
                          })}
                        />
                      </div>
                      {netopen && (
                        <div className='col-xs-12 col-md-3'>
                          <label className='form-label fs-8 fw-semibold'>NetSuite Client ID</label>
                          <input
                            type='text'
                            className='form-control  fs-6'
                            disabled={true}
                            {...register('netSuite_customer_id')}
                          />
                        </div>
                      )}

                      {getValues('netSuite_customer_id') ? (
                        <div className='col-xs-12 col-md-3 '>
                          <button
                            className='btn btn-secondary form-group mt-8'
                            type='button'
                            onClick={() => EditQBCustomerPop()}
                            aria-disabled='true'
                          >
                            Edit NetSuite Client ID
                          </button>
                        </div>
                      ) : (
                        <div className='col-xs-12 col-md-3'>
                          <button
                            className='btn btn-secondary form-group '
                            type='button'
                            onClick={onChange1}
                            aria-disabled='true'
                          >
                            Get NetSuite Client ID
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        <div>
          <div className='d-flex  justify-content-end gap-3 py-1 pt-5'>
            <div>
              <button
                type='submit'
                // onClick={() => reset()}
                className='btn btn-primary'
                // onClick={diffToast}
              >
                Update
              </button>
            </div>
            <div>
              <button type='button' onClick={() => navigate(-1)} className='btn btn-danger'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}
export default UpdateClient
