import React, {useState, useEffect, useRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {ToastContainer, toast} from 'react-toastify'
import clsx from 'clsx'
import {default as Apiservices} from '../../../../../src/common/index'
// import DatePicker from 'react-datepicker'
import DatePicker, {Calendar, getAllDatesInRange} from 'react-multi-date-picker'
import 'react-datepicker/dist/react-datepicker.css'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import EmployeePop from '../../../modules/apps/masters/modal/EmployeePop'
import {KTCardBody} from '../../../../_metronic/helpers'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import {UsersListPagination} from '../../../modules/apps/user-management/users-list/components/pagination/UsersListPagination'
import {Container, Row, Col} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'

export default function EmployeeSubmissionReport(props: any) {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)
  const [dates, setDates] = useState<any>([])
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [country, setCountry] = useState<any | []>([])
  const [dateValue, setDateValue] = useState(null)
  const [startDate1, setStartDate1] = useState(null)
  const [endDateData, setEndDateData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [flag, setFlag] = useState(false)
  const [countries, setCountries] = useState<any | []>([])
  const [useStateCountryId, setUseStateCountryId] = useState<any | []>([])
  const [search, setSearch] = useState('')
  const [selectedItem, setSelectedItem] = useState('')
  const [filteredMatch, setFilteredMatch] = useState('')
  const [selectedStatus, setSelectedStatus] = useState('')
  const [email, setEmail] = useState('')
  const [list, setList] = useState(false)
  // const [dateRange, setDateRange] = useState([null, null])
  // const [startDate, endDate] = dateRange
  const [dateRange, setDateRange] = useState<any | []>([])
  const [MonthYearDynamicallyChange, setMonthYearDynamicallyChange] = useState<any>([])
  const [error, setError] = useState<any | []>([])
  const [flag1, setFlag1] = useState(false)
  const [date, setDate] = useState(new Date())
  const [projectsData, setProjectsData] = useState<any | []>([])
  const [indexState, setindexState] = useState('')
  const [totalState, setTotalState] = useState('')
  const [announcement_open_popup, setAnnouncementOpenPoup] = useState(false)
  const [search_open, setSearch_open] = useState(false)
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [resetData1, setResetData] = useState<any | []>([])
  const [candidateList1, setCandidateList1] = useState<any | []>([])
  const [select_consultant, setSelectconsultant] = useState<any | []>([])
  const [selectConsultant, setSelectConsultant] = useState([])
  const [DateValidation, setDateValidation] = useState(false)
  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [order7, setOrder7] = useState('ASC')
  const [order8, setOrder8] = useState('ASC')
  const [perPage, setPerPage] = useState(25)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [sort, setSort] = useState<any | []>(false)
  const [account, setAccount] = useState({
    joining_date: '',
    leaving_date: '',
    // enddate: "",
    reason: '',
    leavetype: '',
  })
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  })
  const [userId, setUserId] = useState([])
  const [prospUpdateId, setProspUpdateId] = useState()
  const [ECDBUpdateId, setECDBUpdateId] = useState<any>()
  const [value1, setValue1] = useState<any>([])
  const [value2, setValue2] = useState<any>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])

  const typeaheadRef: any = useRef<HTMLInputElement>(null)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)

  const [AllOptions, setAllOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ])
  function handleShow(breakpoint: any) {
    setFullscreen(true)
    setShow(true)
  }
  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    // props.view_open(false)
    // props.setViewOpen(false)
  }
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required').nullable(),
  })

  type UserSubmitForm = {
    joining_date: Date
    work_country: string
    leaving_date: string
    timesheet_approver: string
    searchcandidate: string
    qb_employee_name: string
    work_city: string
    qb_employee_id: number
    prefix: string
    record_type_status: string
    first_name: string
    name: string
    phone_number: string
    phone: string
    maxLength: any
    // adp_associate_id: number
    last_name: string
    user_status: string
    employeeId: any
    toolName: any
    orgId: any
    domain: string
    roleName: any
    org_name: any
    userStatus: any
    ecdb_user_id: number
    role: string
    email: string
    firstName: string
    full_name: string
    employee_type: string
    employee_type1: string
    // user_status: string
    adp_associate_id: string
    status: string
    userId: any
    filteredMatch: any
    id: any
    employee_id: any
    candidateId: any
    org_id: any
    profileResponse: any
    work_state: string
    zipcode: string
    permanent_address: string
    work_location: string
    work_street_address: string
    work_address: string
    // business_unit_id: any
  }
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    getValues,
    formState: {errors},
  } = useForm<UserSubmitForm>({
    resolver: yupResolver(validationSchema),
  })
  const [mainTableData, setMainTableData] = React.useState<any | []>([])
  const [mainTableDataCount, setMainTableDataCount] = React.useState<any | []>([])
  // const ref = useRef(null);
  const ref: any = useRef<HTMLInputElement>(null)

  const SuccessToast = (message: any) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 2000,
    })
  }
  const handleEndDate = async (date: any) => {
    // setDateValue(date)
    setEndDateData(date)
  }
  const handleProjectStartDate = async (date: any) => {
    setDateValue(date)
    setStartDate1(date)
    setValue('leaving_date', date)
  }
  const handleConsultantType = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelectConsultant(x)
          AllOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }
        } else if (each.value == 'empty') {
          setSelectConsultant([])
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions(AllOptions)
          }
          AllOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectConsultant(selected)
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          let cde = AllOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }

          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions(AllOptions)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
          //  let test = AllOptions.filter((id: any) => selected.some((x: any) => x.value != id.value))
        }
      }
    } else {
      setSelectConsultant(selected)
      AllOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let consultantReset = AllOptions.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelectConsultant(selected)
  }
  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              console.log('length')

              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const ErrorToast = (message: any) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    })
  }
  function isValidEmail(email: any) {
    return /\S+@\S+\.\S+/.test(email)
  }

  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })
      return str
    }
  }
  const selectedCountries = async (state: any) => {
    setFlag1(false)
    // setCountries(state)
    setUseStateCountryId(state[0]?.id)
    // setResetData(state[0]?.full_name)
  }

  const handleCountries = async (country: any) => {
    if (country && country.length >= 2) {
      const response: any = await Apiservices.getAllEmployesData({country_name: country})
      let end_customer_id =
        response?.data?.data !== null &&
        response?.data?.data?.length > 0 &&
        response?.data?.data?.filter((each: any) => each.full_name)
      // setCountry(response && response.data && response.data.data ? response?.data?.data : '')
      // if (response?.data?.data !== null) {
      //   setCountry(response && response.data && response.data.data ? response?.data?.data : '')
      // }
      if (end_customer_id) {
        setCountry(end_customer_id)
      }
    }
  }

  const handleFilterConsultant1 = async (consult_Name: any) => {
    if (consult_Name && consult_Name.length >= 2) {
      const response: any = await Apiservices.getAllEmployesData({country_name: consult_Name})
      setCandidateList1(response?.data?.data)
    }
  }
  const selectedConsultant1 = async (consult_name: any) => {
    setFlag1(false)
    setSelectconsultant(consult_name)
  }

  const onSubmit = async (data: UserSubmitForm) => {
    setShowBackDrop(true)
    const employeeValid: any = await Apiservices.employeeId()
    let employeeIdData = employeeValid.data.data.employee_id
    setShowBackDrop(false)
  }
  useEffect(() => {
    get_business_units_list()
  }, [])
  const get_business_units_list = async () => {
    // const res: any = await Apiservices.get_business_units_list()
    // if (res && res.status == 200) {
    //   if (res.data.data.length > 0) {
    //     // setBusinessUnitsList(res.data.data)
    //     let obj = {name: 'Select All', value: 'All'}
    //     setBusinessUnitsList([obj, ...res.data.data])
    //   }
    // }

    let y = JSON.parse(localStorage.getItem('bu_list') || '[]')

    let n = y.filter(
      (element: any) =>
        element.roleName?.toLowerCase() == 'accounts' ||
        element.roleName?.toLowerCase() == 'approver'
      // element.roleName?.toLowerCase() == 'timesheetadmin'
    )
    let business: any = localStorage.getItem('role_Name')
    let numArray: any = business?.split(',')
    let uniqueArr: any = []

    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        let obj = {name: 'Select All', value: 'All'}
        if (numArray?.includes('admin') || numArray?.includes('timesheetadmin')) {
          let x = res.data.data.filter((test: any) => uniqueArr.push(test))
          setBusinessUnitsList([obj, ...uniqueArr])
        } else {
          for (let each of res.data.data) {
            for (let item of n) {
              if (each.business_unit_id == item.buId) {
                uniqueArr.push(each)
              }
            }
          }
          setBusinessUnitsList([obj, ...uniqueArr])
        }
      }
    }
  }
  const onChangeDate = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue1(newDate)
    props.setFilterDepartment(newDate)
  }
  const onChangeDate1 = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue2(newDate)
    props.setFilterStatus(newDate)
  }
  const searchHandleChange = async (value: any) => {
    setSearch(value)
    setSearch_open(true)
    setDateValidation(true)
    // setBusinessUnit([])
    // setSelectedItem('') //consultant
    // setValue('employee_type1', '')
    // setValue('business_unit_id', '')
    // setUseStateCountryId([])
    // ref.current.clear()
    // setSelectConsultant([])
    // setSelectconsultant([])
    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')

    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    if (dateRange?.length == 2) {
      setDateValidation(false)
      const data: any = await Apiservices.getEmployeeReports({
        // start_date: startDate2,
        // end_date: endDate2,
        start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
        end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
        keyword: value,
        user_id: employee1 ? employee1 : null,
        employee_type: stat2 ? stat2 : '',
        business_unit_id: business2 ? business2 : null,
        // pagenumber: page,
        pagenumber: 1,
        pagesize: perPage,
      })
      setPage(1)
      setMainTableData(data?.data?.data)
      setMainTableDataCount(data?.data?.count)
      setPages(Math.ceil(data?.data?.count / Number(perPage)))
    }
  }
  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    // setPage(selected)
    // getallprojects(mainTableData, selected, perPage)
    handleClick(selected, perPage, true)
  }

  const onChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      // getallprojects(mainTableData, 1, value)
      handleClick(1, value, false)
    }
  }

  // const handleClick = async (e: any) => {
  const handleClick = async (page: any, perPage: any, change: boolean) => {
    setSearch_open(false)
    setFlag(true)
    setDateValidation(true)
    // let anil = moment endDateData.slice(0,4)
    setShowBackDrop(true)
    let jOinedDate = moment(endDateData, 'YYYY/MM/DD').format('YYYY-MM-DD')
    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')

    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')

    // const data: any = await Apiservices.getEmployeeReports({
    //     // pagenumber: currentPage,
    //     // pagesize: itemsPerPage,
    //     employee_type: selectedItem,
    //     record_type_status: selectedStatus == "" ? "Active" : selectedStatus == "Active" ? "Active" : "Inactive",
    //     // record_type_status: selectedStatus == "undefined" ? true ? selectedStatus == "Active" ? "ACTIVE" : "InActive",
    //     joining_date: jOinedDate == "Invalid date" ? null : jOinedDate
    //     // joining_date: filteredMatch == "" ? null : filteredMatch
    // })
    // setMainTableData(data?.data?.data)
    if (dateRange?.length == 2) {
      setDateValidation(false)
      if (flag1) {
        const data: any = await Apiservices.getEmployeeReports({
          // pagenumber: currentPage,
          // pagesize: itemsPerPage,
          // start_date: startDate2,
          // end_date: endDate2,
          start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
          end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
          // user_id: useStateCountryId,
          employee_type: stat2 ? stat2 : '',
          business_unit_id: business2 ? business2 : null,
          pagenumber: page,
          pagesize: perPage,
        })
        setMainTableData(data?.data?.data)
        setMainTableDataCount(data?.data?.count)
        setPages(Math.ceil(data?.data?.count / Number(perPage)))
      } else {
        const data: any = await Apiservices.getEmployeeReports({
          // pagenumber: currentPage,
          // pagesize: itemsPerPage,
          // start_date: startDate2,
          // end_date: endDate2,
          start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
          end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
          user_id: employee1 ? employee1 : null,
          employee_type: stat2 ? stat2 : '',
          business_unit_id: business2 ? business2 : null,
          // pagenumber: page,
          pagenumber: change ? page : 1,
          pagesize: perPage,
        })
        setPage(change ? page : 1)
        setMainTableData(data?.data?.data)
        setMainTableDataCount(data?.data?.count)
        setPages(Math.ceil(data?.data?.count / Number(perPage)))
      }
    }
    setShowBackDrop(false)
  }

  const handleChange = (e: any) => {
    setSelectedItem(e.target.value)
  }

  const handleChange44 = (e: any) => {
    setFilteredMatch(e.target.value)
  }
  const handleChange1 = (e: any) => {
    setSelectedStatus(e.target.value)
  }

  const userName = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''
      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })
      return str
    }
  }

  const AnnouncePop = (value: any) => {
    setAnnouncementOpenPoup(false)
  }

  const resetData = async (e: any) => {
    setFlag1(true)
    setFlag(false)
    setMainTableData([])
    setSelectedItem('')
    setFilteredMatch('')
    setDateValidation(false)
    setBusinessUnit([])
    setValue('work_city', '')
    setSelectconsultant([])
    setValue('employee_type1', '')
    setSelectConsultant([])
    setUseStateCountryId([])
    // setValue('business_unit_id', '')
    // setDateRange([null])
    setDateRange(null)
    setMonthYearDynamicallyChange([])
    // ref.current.clear()

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }
    // BusinessUnitsList.pop({
    //   name: 'Deselect All',
    //   value: 'empty',
    // })
    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
    // const data: any = await Apiservices.employeereportViewData({
    //   // pagenumber: currentPage,
    //   // pagesize: itemsPerPage,
    //   start_date: dates,
    //   end_date: dates1,
    // })
    //     setMainTableData(data?.data?.data)
  }

  // const exporthandleClick = async (e: any) => {
  //   let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
  //   let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
  //   // var today = moment(date)
  //   // var startOfWeek = today.clone().startOf('isoWeek').format('YYYY-MM-DD')
  //   // var endOfWeek = today.clone().endOf('isoWeek').format('YYYY-MM-DD')
  //   // e.preventDefault();
  //   // if (flag3 == true) {
  //   const res: any = await Apiservices.exportEmployeeReports({
  //     // employee_type: selectedItem,
  //     // record_type_status: selectedStatus == "" ? "Active" : selectedStatus == "Active" ? "Active" : "Inactive",
  //     // // joining_date: filteredMatch,
  //     // joining_date: endDateData == "" ? null : endDateData,
  //     // joining_date: filteredMatch == "" ? null : filteredMatch,
  //     start_date: startDate2,
  //     end_date: endDate2,
  //     user_id: useStateCountryId,
  //     employee_type: selectedItem == '' ? null : selectedItem,
  //     keyword: search == '' ? null : search,
  //     // keyword: search ? search : '',
  //   })
  //   var link = document.createElement('a')
  //   // If you don't know the name or want to use
  //   // the webserver default set name = ''
  //   link.setAttribute('download', 'Downloaded File')
  //   link.href = res.data.data.blobUrl
  //   document.body.appendChild(link)
  //   link.click()
  //   link.remove()
  //   // }
  // }

  const exporthandleClick = async (e: any) => {
    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    setDateValidation(true)
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')

    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')

    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    setShowBackDrop(true)
    if (dateRange?.length == 2) {
      setDateValidation(false)
      if (search_open) {
        const res: any = await Apiservices.exportEmployeeReports({
          // start_date: startDate2,
          // end_date: endDate2,
          start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
          end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
          business_unit_id: business2 ? business2 : null,
          // user_id: useStateCountryId,
          // employee_type: selectedItem == '' ? null : selectedItem,
          keyword: search == '' ? null : search,
        })
        var link = document.createElement('a')
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', 'Downloaded File')
        link.href = res.data.data.blobUrl
        document.body.appendChild(link)
        link.click()
        link.remove()
      } else {
        const res: any = await Apiservices.exportEmployeeReports({
          // start_date: startDate2,
          // end_date: endDate2,
          start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
          end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
          user_id: employee1 ? employee1 : null,
          employee_type: stat2 ? stat2 : '',
          business_unit_id: business2 ? business2 : null,
          // keyword: search == '' ? null : search,
        })
        var link = document.createElement('a')
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', 'Downloaded File')
        link.href = res.data.data.blobUrl
        document.body.appendChild(link)
        link.click()
        link.remove()
      }
    }
    setShowBackDrop(false)
  }

  const sorting5 = (col: any) => {
    if (order5 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      )
      setMainTableData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      )
      setMainTableData(sorted)
      setOrder5('ASC')
      setSort(true)
    }
  }
  const sorting6 = (col: any, col2: any, col3: any) => {
    if (order6 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a, b) =>
        parseFloat(a[col][col2][col3]) > parseFloat(b[col][col2][col3]) ? 1 : -1
      )
      setMainTableData(sorted)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a, b) =>
        parseFloat(a[col][col2][col3]) < parseFloat(b[col][col2][col3]) ? 1 : -1
      )
      setMainTableData(sorted)
      setOrder6('ASC')
      setSort(true)
    }
  }
  const sorting7 = (col: any, col1: any, col2: any) => {
    if (order7 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a, b) => {
        let dateA = new Date(a[col][col1][col2]),
          dateB = new Date(b[col][col1][col2])
        if (dateB > dateA) {
          return -1
        } else {
          return 1
        }
      })

      setMainTableData(sorted)
      setOrder7('DSC')
      setSort(false)
    }

    if (order7 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a, b) => {
        let dateA = new Date(a[col][col1][col2]),
          dateB = new Date(b[col][col1][col2])
        if (dateB < dateA) {
          return -1
        } else {
          return 1
        }
      })

      setMainTableData(sorted)
      setOrder7('ASC')
      setSort(true)
    }
  }

  const sorting8 = (col: any) => {
    if (order8 === 'ASC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        const numA = parseInt(a[col].match(/\d+/), 10) || 0
        const numB = parseInt(b[col].match(/\d+/), 10) || 0
        // (a[col] > b[col] ? 1 : -1)
        // if (numA === numB) {
        //   // If numeric parts are equal, compare the whole strings
        //   return a[col].localeCompare(b[col])
        // } else {
        //   return numA - numB
        // }
        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerA.localeCompare(lowerB)
      })
      // const sorted = [...mainTableData].sort((a, b) => {
      //   return a[col].localeCompare(b[col], undefined, {
      //     numeric: true,
      //     sensitivity: 'base',
      //   })
      // })
      setMainTableData(sorted)
      setOrder8('DSC')
      setSort(false)
    }

    if (order8 === 'DSC') {
      const sorted = [...mainTableData]?.sort((a: any, b: any) => {
        const numA = parseInt(a[col].match(/\d+/), 10) || 0
        const numB = parseInt(b[col].match(/\d+/), 10) || 0
        // (a[col] < b[col] ? 1 : -1)
        // if (numA === numB) {
        //   // If numeric parts are equal, compare the whole strings
        //   return b[col].localeCompare(a[col])
        // } else {
        //   return numB - numA
        // }
        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerB.localeCompare(lowerA)
      })

      // const sorted = [...mainTableData].sort((a, b) => {
      //   return b[col].localeCompare(a[col], undefined, {
      //     numeric: true,
      //     sensitivity: 'base',
      //   })
      // })
      setMainTableData(sorted)
      setOrder8('ASC')
      setSort(true)
    }
  }
  const handleDateMulti = (date: any, val: any) => {
    let allDates: any = getAllDatesInRange(date, true)
    setDateRange(date)
    let monthYear: any = {
      d1: date[0]?.day < 10 ? '0' + date[0]?.day : date[0]?.day,
      d2: date[1]?.day < 10 ? '0' + date[1]?.day : date[1]?.day,
      // m1: date[0]?.month.number,
      // m1: date[0]?.month.name,
      m1: moment(date[0]?.month.name, 'MMMM').format('MM'),
      // m2: date[1]?.month.number,
      // m2: date[1]?.month.name,
      m2: moment(date[1]?.month.name, 'MMMM').format('MM'),
      y1: date[0]?.year,
      y2: date[1]?.year,
    }
    setDateValidation(false)
    setMonthYearDynamicallyChange(monthYear)
  }
  return (
    <>
      {announcement_open_popup ? (
        <EmployeePop
          close={AnnouncePop}
          indexState={indexState}
          totalState={totalState}
          role_name={'Admin'}
          setProjectsData={setProjectsData}
          projectsData={projectsData}
        />
      ) : (
        ''
      )}
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>
            Consultant Submission Report(Total No.of hours submitted by consultants)
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <BackDrop showBackDrop={showBackDrop} />
          {/* <form onSubmit={handleSubmit(onSubmit)}> */}
          {/* {loading ? ( */}
          <>
            <div className='card card-custom card-flush '>
              <div className='card-body p-2 py-3'>
                <div className='row gy-3 align-items-center py-2'>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label fw-semibold'>
                      Date Range <span className='fs-9'>(DD-MMM-YYYY)</span> *
                    </label>
                    {/* <DatePicker
                      placeholderText='Date Range'
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat='dd-MMM-yyyy'
                      onChange={(update: any) => {
                        setDateRange(update)
                      }}
                      isClearable={true}
                      monthsShown={2}
                      className={clsx('form-control')}
                      required
                    /> */}
                    <DatePicker
                      range
                      numberOfMonths={2}
                      dateSeparator=' - '
                      format='DD-MMM-YYYY'
                      // className={clsx('form-control')}
                      inputClass='form-control'
                      placeholder='Select Date Range'
                      value={dateRange}
                      onChange={(dateObjects: any) => {
                        handleDateMulti(dateObjects, true)
                      }}
                    />
                    {DateValidation && (
                      <span className='error text-danger mb-0'>Date Range is required</span>
                    )}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Consultant Type</label>
                    {/* <select
                          value={selectedItem}
                          className={clsx('form-select', errors.employee_type ? 'is-invalid' : '')}
                          {...register('employee_type')}
                          onChange={handleChange}
                        >
                          <option value=''>Select Consultant Type</option>
                          <option value='W2'>W2</option>
                          <option value='C2C'>C2C</option>
                          <option value='Internal'>Internal</option>
                          <option value='1099'>1099</option>
                          <option value='Offshore'>Offshore</option>
                        </select>

                        <div className='invalid-feedback'>{errors.first_name?.message}</div> */}
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        handleConsultantType(selected)
                        // setSelectConsultant(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef.current.toggleMenu()
                      }}
                      options={AllOptions}
                      placeholder='Select Consultant Type'
                      ref={typeaheadRef}
                      selected={selectConsultant}
                    />
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Consultant Name</label>
                    {/* <Typeahead
                          id='basic-typeahead-single'
                          {...register('work_country')}
                          onChange={(e) => selectedCountries(e)}
                          onInputChange={(e) => handleCountries(e)}
                          placeholder='Please Enter Employee Name'
                          options={country}
                          // selected={countries}
                          labelKey={(country: any) => country.full_name}
                          ref={ref}
                        >
                          {({onClear, selected}) => (
                            <div className='rbt-aux'>
                              {!!selected.length && <ClearButton onClick={onClear} />}
                            </div>
                          )}
                        </Typeahead>
                        <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(e) => {
                        selectedConsultant1(e)
                        typeaheadRef5.current.toggleMenu()
                      }}
                      onInputChange={(e) => handleFilterConsultant1(e)}
                      options={candidateList1}
                      ref={typeaheadRef5}
                      placeholder='Search Consultant'
                      selected={select_consultant}
                      labelKey={(candidateList1: any) => user(candidateList1.full_name)}
                    ></Typeahead>
                  </div>

                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Business Unit</label>
                    {/* <select
                          // value={selectedItem}
                          className={clsx('form-select', errors.employee_type ? 'is-invalid' : '')}
                          {...register('business_unit_id')}
                          onChange={(e: any) => setValue('business_unit_id', e.target.value)}
                        >
                          <option value=''>Select Business Unit</option>
                          {BusinessUnitsList &&
                            BusinessUnitsList.length > 0 &&
                            BusinessUnitsList.map((option: any) => {
                              return (
                                <option value={option.business_unit_id} className='text-capitalize'>
                                  {option.name}
                                </option>
                              )
                            })}
                        </select>
                        <div className='invalid-feedback'>{errors.first_name?.message}</div> */}
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        // setBusinessUnit(e)
                        handleBusinessUnit(e)
                        typeaheadRef4.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef4}
                      placeholder='Select Business Unit'
                      selected={BusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    ></Typeahead>
                  </div>

                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      className='btn btn-sm btn-primary'
                      // onClick={handleClick}
                      onClick={() => handleClick(page, perPage, false)}
                    >
                      Run
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={resetData}
                    >
                      Reset
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      onClick={exporthandleClick}
                    >
                      Export
                      {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* ) : (
              <h1>load</h1>
            )} */}
          {/* </form> */}
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select a date range and click run to view the report
            </div>
          )}
          {flag == true && (
            <>
              <KTCardBody className='py-1 '>
                <div className='pt-3'>
                  <div className='card card-custom card-flush '>
                    <div className='card-body p-3 py-3'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='text-gray-900 fs-5 fw-semibold text-transform-unset'>
                          Tabular Data{' '}
                        </div>
                        <div className='d-flex gap-3 '>
                          <div className='input-group mb-0'>
                            <input
                              type='text'
                              id='form1'
                              className='form-control smallTextField'
                              // value={search}
                              {...register('work_city')}
                              placeholder='Search'
                              onChange={(e) => searchHandleChange(e.target.value)}
                            />
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                      <div className='table-responsive padding-down reportTab'>
                        <table
                          id='kt_table_users'
                          className='table table-row-bordered table-row-gray-300 gy-4'
                        >
                          <>
                            <thead>
                              <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                                <th
                                  onClick={() => sorting8('business_unit_name')}
                                  className='  py-4'
                                >
                                  Business Unit{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting8('employee_id')} className='  py-4'>
                                  Consultant Id{' '}
                                  <span>
                                    {order8 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('display_name')} className='  py-4'>
                                  Consultant Name{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('employee_type')} className='  py-4'>
                                  Consultant Type{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th onClick={() => sorting5('project_name')} className='  py-4'>
                                  Project Name{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() =>
                                    sorting7('last_timesheet_data', 0, 'timesheet_date')
                                  }
                                  className='  py-4'
                                >
                                  Last timesheet date{' '}
                                  <span>
                                    {order7 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() =>
                                    sorting6('total_timesheet_data', 0, 'regular_hours_minutes')
                                  }
                                  className='  py-4'
                                >
                                  Regular Hours{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() =>
                                    sorting6('total_timesheet_data', 0, 'ot_hours_minutes')
                                  }
                                  className='  py-4'
                                >
                                  OT Hours{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                                <th
                                  onClick={() =>
                                    sorting6('total_timesheet_data', 0, 'total_hours_minutes')
                                  }
                                  className='  py-4'
                                >
                                  Total Hours{' '}
                                  <span>
                                    {order5 === 'ASC' ? (
                                      <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                    ) : (
                                      <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                    )}
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {mainTableData?.length > 0 ? (
                                <>
                                  {mainTableData
                                    // .filter(
                                    //   (each: any) =>
                                    //     each?.employee_id
                                    //       ?.toLowerCase()
                                    //       ?.includes(search?.toLowerCase()) ||
                                    //     each?.full_name
                                    //       ?.toLowerCase()
                                    //       ?.includes(search?.toLowerCase()) ||
                                    //     each?.employee_type
                                    //       ?.toLowerCase()
                                    //       ?.includes(search?.toLowerCase())
                                    //       ?.includes(search?.toLowerCase()) ||
                                    //     each?.project_name
                                    //       ?.toLowerCase()
                                    //       ?.includes(search?.toLowerCase())
                                    // )
                                    ?.map((each: any, index: any) => (
                                      <tr
                                        className='namepage text-dark fw-semibold  fs-6 text-nowrap'
                                        key={index}
                                      >
                                        <td className=''>
                                          {each.business_unit_name ? each.business_unit_name : '-'}
                                        </td>
                                        <td className=''>
                                          {each.employee_id ? each.employee_id : '-'}
                                        </td>
                                        <td className='text-capitalize'>
                                          {each.display_name
                                            ? each.display_name
                                            : each.full_name
                                            ? each.full_name
                                            : '-'}
                                        </td>
                                        {/* <td className=''>{each.email ? each.email : '-'}</td> */}

                                        <td className=''>
                                          {each.employee_type ? each.employee_type : '-'}
                                        </td>
                                        <td className='' style={{textTransform: 'capitalize'}}>
                                          {each.project_name ? each.project_name : '-'}
                                        </td>
                                        <td className=''>
                                          {each.last_timesheet_data &&
                                          each.last_timesheet_data[0] &&
                                          each.last_timesheet_data[0].timesheet_date
                                            ? moment(
                                                each.last_timesheet_data[0].timesheet_date
                                              ).format('DD-MMM-YYYY')
                                            : '-'}
                                        </td>
                                        <td className=''>
                                          {each.total_timesheet_data &&
                                          each.total_timesheet_data[0] &&
                                          each.total_timesheet_data[0].regular_hours_minutes
                                            ? each.total_timesheet_data[0].regular_hours_minutes
                                            : '-'}
                                        </td>
                                        <td className=''>
                                          {each.total_timesheet_data &&
                                          each.total_timesheet_data[0] &&
                                          each.total_timesheet_data[0].ot_hours_minutes
                                            ? each.total_timesheet_data[0].ot_hours_minutes
                                            : '-'}
                                        </td>
                                        <td className=''>
                                          {each.total_timesheet_data &&
                                          each.total_timesheet_data[0] &&
                                          each.total_timesheet_data[0].total_hours_minutes
                                            ? each.total_timesheet_data[0].total_hours_minutes
                                            : '-'}
                                        </td>
                                      </tr>
                                    ))}
                                </>
                              ) : (
                                ''
                              )}
                            </tbody>
                          </>
                        </table>
                        {(mainTableData?.length == null || mainTableData?.length == 0) && (
                          <div className='text-center  fw-bold text-gray-800'>
                            No Records found{' '}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <UsersListPagination />
              </KTCardBody>
              <div className='d-flex flex-stack py-2'>
                {mainTableData?.length > 0 ? (
                  <div>
                    <Container className='p-0'>
                      <Row className='align-items-center'>
                        <Col xs='auto' className='pe-0'>
                          {' '}
                          <label className='form-label fw-semibold'>Rows Per Page</label>
                        </Col>

                        <Col>
                          <select
                            name='perPage'
                            className='form-select'
                            onChange={onChange}
                            value={perPage}
                          >
                            {/* <option value='10'>10</option> */}
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                            <option value='75'>75</option>
                            <option value='100'>100</option>
                            {/* <option value={mainTableDataCount}>All</option> */}
                          </select>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                ) : null}

                <div className='pr-150'>
                  {mainTableData?.length > 0 ? (
                    <ReactPaginate
                      previousLabel={'<'}
                      nextLabel={'>'}
                      pageCount={pages}
                      onPageChange={handlePageClick}
                      containerClassName={'pagination justify-content-right'}
                      activeClassName={'active'}
                      breakLabel={'...'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      breakClassName={'page-item'}
                      breakLinkClassName={'page-link'}
                      forcePage={page - 1}
                    />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}
