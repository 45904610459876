import React, {useState, useRef, useEffect} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import ButtonCal from '../../../localMedia/buttonCal.svg'
import {useForm} from 'react-hook-form'
import 'reactjs-popup/dist/index.css'
import {ToastContainer, toast} from 'react-toastify'
import BackDrop from '../../modules/apps/timesheetentry/ComponentBackdrop'
import QuickBooksReports from '../../modules/apps/masters/modal/QuickBooksReports'
import UsersLogsReports from '../../modules/apps/masters/modal/UsersLogsReports'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import EmployeeReports from './reportModals/EmployeeReports'
import ProjectReports from './reportModals/ProjectReports'
import EmployeeStatusReport from './reportModals/EmployeeStatusReport'
import EmpComplianceReports from './reportModals/EmpComplianceReports'
import EmployeeSubmissionReport from './reportModals/EmployeeSubmissionReport'
import ProjectSubmissionReport from './reportModals/ProjectSubmissionReport'
import TimesheetSubmissionReport from './reportModals/TimesheetSubmissionReport'
import {default as Apiservices} from '../../../common/index'
import ProjectComplianceReports from './reportModals/ProjectComplianceReports'
import OrgComplianceReports from './reportModals/OrgComplianceReports'
import OrgNonComplianceReports from './reportModals/OrgNonComplianceReports'
import RevenueComplianceReports from './reportModals/RevenueComplianceReports'
import RevenueMonthlyComplianceReports from './reportModals/RevenueMonthlyComplianceReports'
import COGSComplianceReports from './reportModals/COGSComplianceReports'
import COGSMonthlyComplianceReports from './reportModals/COGSMonthlyComplianceReports'
import MarginComplianceReports from './reportModals/MarginComplianceReports'
import MarginMonthlyComplianceReports from './reportModals/MarginMonthlyComplianceReports'
import WorkLocationReport from './reportModals/WorkLocationReport'
import UnBilledReport from './reportModals/UnBilledReport'
import TimesheetComplianceReports from './reportModals/TimesheetComplianceReports'
import UtilizationComplianceReports from './reportModals/UtilizationComplianceReports'
import OrgWeeklyComplianceReport from './reportModals/OrgWeeklyComplianceReport'
import ConsultantCountReport from './reportModals/ConsultantCountReport'
import ProjectCountReport from './reportModals/ProjectCountReport'
import {Toolbar1} from '../../../_metronic/layout/components/toolbar/Toolbar1'
import MonthlyHoursSetting from '../../modules/apps/masters/modal/MonthlyHoursSetting'

type dat = {
  employee_name: any
  client_manager_name: any
  project_name: any
  client_manager_email: any
  document_files: any
  ts_client_manager_name: any
  sunday_notes: any
  approver_notes: any
  ts_approver_notes: any
  week_start_date: any
  rejected_comments_flag: any
  revert_CrossBtn: any
  revert_ApproveBtn: any
  status: any
  total_regular_hours: any
  approve: any
  reject: any
  submit: any
  client_name: any
  document_name: any
  allRevertData: any
  quickApprove: any
  qb_Name: any
  trueColor: any
}

type UserSubmitForm = {
  details: dat[]
  status: any
  rejected_hours: any
  regular_hours: any
}

const Reports: React.FC<any> = (props: any) => {
  const {
    register,
    setValue,
    reset,
    getValues,
    handleSubmit,
    formState: {errors},
  } = useForm<UserSubmitForm>()

  type ButtonProps = React.HTMLProps<HTMLButtonElement>

  const [showBackDrop, setShowBackDrop] = useState(false)
  const [list, setList] = useState(false)
  const [disableQuickBooks, setDisableQuickBooks] = useState(false)

  const modalPop = () => {
    setList(false)
  }
  const [openFilter, setOpenFilter] = useState<any | []>(false)
  const ExampleCustomInput = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({value, onClick}, ref: any) => (
      <button className='btn btn-icon btn-light btn-sm  ms-2 ' onClick={onClick} ref={ref}>
        <img src={ButtonCal} className='buttonCal' />
      </button>
    )
  )

  const handleCustomerName = async () => {
    setViewShow(true)
  }
  const handleCustomerName1 = async () => {
    setProjectShow(true)
  }
  const handleCustomerName7 = async () => {
    setEmployeeStatusShow(true)
  }

  const handleCustomerName8 = () => {
    setEmployeeComplianceShow(true)
  }
  const handleCustomerName9 = () => {
    setProjectComplianceShow(true)
  }
  const handleCustomerName10 = () => {
    setOrganizationComplianceShow(true)
  }
  const handleCustomerName11 = () => {
    setOrganizationNonComplianceShow(true)
  }
  const handleCustomerName15 = () => {
    setTimesheetComplianceShow(true)
  }
  const handleCustomerName16 = () => {
    setUtilizationComplianceShow(true)
  }
  const handleCustomerName20 = () => {
    setWeeklyComplianceShow(true)
  }
  const handleCustomerName21 = () => {
    setEmployeeCountShow(true)
  }
  const handleCustomerName22 = () => {
    setProjectCountShow(true)
  }
  const handleCustomerName12 = () => {
    setRevenueComplianceShow(true)
  }
  const handleCustomerName17 = () => {
    setMonthlyRevenueComplianceShow(true)
  }
  const handleCustomerName13 = () => {
    setCogsComplianceShow(true)
  }
  const handleCustomerName18 = () => {
    setCogsMonthlyComplianceShow(true)
  }
  const handleCustomerName14 = () => {
    setMarginComplianceShow(true)
  }
  const handleCustomerName19 = () => {
    setMarginMonthlyComplianceShow(true)
  }
  const handleCustomerName23 = () => {
    setWorkLocationShow(true)
  }
  const handleCustomerName24 = () => {
    setUnBilled(true)
  }
  const handleCustomerName2 = async () => {
    setUserLogShow(true)
  }
  const handleCustomerName3 = async () => {
    setQuickBookShow(true)
  }
  const handleCustomerName4 = async () => {
    setTimeShow(true)
  }
  const handleCustomerName5 = async () => {
    seProjecttViewShow(true)
  }
  const handleCustomerName6 = async () => {
    setTimeExportShow(true)
  }

  const intl = useIntl()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const [startingDisabled, setStartingDisabled] = useState(false)
  const [viewshow, setViewShow] = useState(false)
  const [timeExportShow, setTimeExportShow] = useState(false)
  const [projectViewshow, seProjecttViewShow] = useState(false)
  const [quickBookshow, setQuickBookShow] = useState(false)
  const [projectshow, setProjectShow] = useState(false)
  const [EmployeeStatusShow, setEmployeeStatusShow] = useState(false)
  const [EmployeeComplianceShow, setEmployeeComplianceShow] = useState(false)
  const [ProjectComplianceShow, setProjectComplianceShow] = useState(false)
  const [OrganizationComplianceShow, setOrganizationComplianceShow] = useState(false)
  const [OrganizationNonComplianceShow, setOrganizationNonComplianceShow] = useState(false)
  const [RevenueComplianceShow, setRevenueComplianceShow] = useState(false)
  const [MonthlyRevenueComplianceShow, setMonthlyRevenueComplianceShow] = useState(false)
  const [CogsComplianceShow, setCogsComplianceShow] = useState(false)
  const [CogsMonthlyComplianceShow, setCogsMonthlyComplianceShow] = useState(false)
  const [MarginComplianceShow, setMarginComplianceShow] = useState(false)
  const [MarginMonthlyComplianceShow, setMarginMonthlyComplianceShow] = useState(false)
  const [WorkLocationShow, setWorkLocationShow] = useState(false)
  const [UnBilled, setUnBilled] = useState(false)
  const [TimesheetComplianceShow, setTimesheetComplianceShow] = useState(false)
  const [UtilizationComplianceShow, setUtilizationComplianceShow] = useState(false)
  const [WeeklyComplianceShow, setWeeklyComplianceShow] = useState(false)
  const [EmployeeCountShow, setEmployeeCountShow] = useState(false)
  const [ProjectCountShow, setProjectCountShow] = useState(false)

  const [userLogshow, setUserLogShow] = useState(false)
  const [timeShow, setTimeShow] = useState(false)
  const [MonthSetting, setMonthSetting] = useState(false)
  const handleCancel = () => {
    setViewShow(false)
    setProjectShow(false)
    setUserLogShow(false)
    setQuickBookShow(false)
    setTimeShow(false)
    seProjecttViewShow(false)
    setTimeExportShow(false)
    setEmployeeStatusShow(false)
    setEmployeeComplianceShow(false)
    setProjectComplianceShow(false)
    setOrganizationComplianceShow(false)
    setOrganizationNonComplianceShow(false)
    setRevenueComplianceShow(false)
    setMonthlyRevenueComplianceShow(false)
    setCogsComplianceShow(false)
    setCogsMonthlyComplianceShow(false)
    setMarginComplianceShow(false)
    setMarginMonthlyComplianceShow(false)
    setTimesheetComplianceShow(false)
    setUtilizationComplianceShow(false)
    setWeeklyComplianceShow(false)
    setEmployeeCountShow(false)
    setProjectCountShow(false)
    setWorkLocationShow(false)
    setUnBilled(false)
  }

  const getOrganizationSetting = async () => {
    let get_org_id = localStorage.getItem('org_id')
    const res: any = await Apiservices.getOrganizationSetting({org_id: get_org_id})
    if (res && res?.data?.isError == false) {
      if(res&&res.data&&res.data.data){
      setDisableQuickBooks(res && res?.data?.data[0]?.enable_quickbooks)
      localStorage.setItem('enable_quickbooks', res && res?.data?.data[0]?.enable_quickbooks)
    }
  }
  }

  useEffect(() => {
    getOrganizationSetting()
  }, [])

  const onSubmit = async (data: UserSubmitForm) => {}

  const monthlySettingOpen = () => {
    setMonthSetting(true)
  }
  const monthlySettingClose = () => {
    setMonthSetting(false)
  }
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  return (
    <>
      <Toolbar1 monthlySettingOpen={monthlySettingOpen} />
      {MonthSetting && <MonthlyHoursSetting monthlySettingClose={monthlySettingClose} />}
      {viewshow && (
        <EmployeeReports
          // viewFile={viewFile}
          // view_open={view_open}
          // setViewOpen={setViewOpen}
          // view_blob={view_blob}
          // setViewBlob={setViewBlob}
          cancel={handleCancel}
        />
      )}
      {projectshow && (
        <ProjectReports
          // viewFile={viewFile}
          // view_open={view_open}
          // setViewOpen={setViewOpen}
          // view_blob={view_blob}
          // setViewBlob={setViewBlob}
          cancel={handleCancel}
        />
      )}

      {EmployeeStatusShow && (
        <EmployeeStatusReport
          // viewFile={viewFile}
          // view_open={view_open}
          // setViewOpen={setViewOpen}
          // view_blob={view_blob}
          // setViewBlob={setViewBlob}
          cancel={handleCancel}
        />
      )}

      {EmployeeComplianceShow && <EmpComplianceReports cancel={handleCancel} />}
      {ProjectComplianceShow && <ProjectComplianceReports cancel={handleCancel} />}

      {userLogshow && (
        <UsersLogsReports
          // viewFile={viewFile}
          // view_open={view_open}
          // setViewOpen={setViewOpen}
          // view_blob={view_blob}
          // setViewBlob={setViewBlob}
          cancel={handleCancel}
        />
      )}
      {quickBookshow && (
        <QuickBooksReports
          // viewFile={viewFile}
          // view_open={view_open}
          // setViewOpen={setViewOpen}
          // view_blob={view_blob}
          // setViewBlob={setViewBlob}
          cancel={handleCancel}
        />
      )}
      {timeShow && (
        <EmployeeSubmissionReport
          // viewFile={viewFile}
          // view_open={view_open}
          // setViewOpen={setViewOpen}
          // view_blob={view_blob}
          // setViewBlob={setViewBlob}
          cancel={handleCancel}
        />
      )}
      {OrganizationComplianceShow && <OrgComplianceReports cancel={handleCancel} />}
      {OrganizationNonComplianceShow && <OrgNonComplianceReports cancel={handleCancel} />}
      {RevenueComplianceShow && <RevenueComplianceReports cancel={handleCancel} />}
      {MonthlyRevenueComplianceShow && <RevenueMonthlyComplianceReports cancel={handleCancel} />}
      {CogsComplianceShow && <COGSComplianceReports cancel={handleCancel} />}
      {CogsMonthlyComplianceShow && <COGSMonthlyComplianceReports cancel={handleCancel} />}
      {MarginComplianceShow && <MarginComplianceReports cancel={handleCancel} />}
      {MarginMonthlyComplianceShow && <MarginMonthlyComplianceReports cancel={handleCancel} />}
      {WorkLocationShow && <WorkLocationReport cancel={handleCancel} />}
      {UnBilled && <UnBilledReport cancel={handleCancel} />}
      {TimesheetComplianceShow && <TimesheetComplianceReports cancel={handleCancel} />}
      {UtilizationComplianceShow && <UtilizationComplianceReports cancel={handleCancel} />}
      {WeeklyComplianceShow && <OrgWeeklyComplianceReport cancel={handleCancel} />}
      {EmployeeCountShow && <ConsultantCountReport cancel={handleCancel} />}
      {ProjectCountShow && <ProjectCountReport cancel={handleCancel} />}
      {projectViewshow && (
        <ProjectSubmissionReport
          // viewFile={viewFile}
          // view_open={view_open}
          // setViewOpen={setViewOpen}
          // view_blob={view_blob}
          // setViewBlob={setViewBlob}
          cancel={handleCancel}
        />
      )}
      {timeExportShow && (
        <TimesheetSubmissionReport
          // viewFile={viewFile}
          // view_open={view_open}
          // setViewOpen={setViewOpen}
          // view_blob={view_blob}
          // setViewBlob={setViewBlob}
          cancel={handleCancel}
        />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.REPORTS'})}</PageTitle>
        <BackDrop showBackDrop={showBackDrop} />
        {/* 
        <div>
          {localStorage.getItem('role_Name') === 'admin' && (
            <div className='row g-5'>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Basic Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName()}
                      >
                        {' '}
                        <div>Employee Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of Employees joined between selected date range
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName1()}
                      >
                        {' '}
                        <div>Project Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of Projects started between selected date range
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName7()}
                      >
                        {' '}
                        <div>Employee Status Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No. of employees joined and left between selected date range
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Timesheet Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName4()}
                      >
                        {' '}
                        <div>Employee Submission Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by Employees
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName5()}
                      >
                        {' '}
                        <div>Project Submission Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by Employee - Project wise
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName6()}
                      >
                        {' '}
                        <div>Timesheet Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by Employees - Day wise
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Compliance Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName8()}
                      >
                        {' '}
                        <div>Employee Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Monthly Total Timesheet Compliance %, Employee wise Timesheet Compliance %
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName9()}
                      >
                        {' '}
                        <div>Project Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Monthly Total Timesheet Compliance %, Employee & Project wise Timesheet
                          Compliance %
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName10()}
                      >
                        {' '}
                        <div>Organization Wise Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Yearly Timesheet Compliance % Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName11()}
                      >
                        {' '}
                        <div>Organization Wise Non Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Employee & Project wise Missed timesheet entries
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName15()}
                      >
                        {' '}
                        <div>Timesheet Compliance Report</div>
                        <div className='text-gray-600 fs-7'>
                          Organization Timesheet Compliance & Non Compliance Report - Employee,
                          Project, Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName16()}
                      >
                        {' '}
                        <div>Utilization Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Employee Utilization report - Project, Year, Month wise
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Financial Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName12()}
                      >
                        {' '}
                        <div>Revenue Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Revenue report - Employee, Project, Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName13()}
                      >
                        {' '}
                        <div>COGS Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Payable report - Employee, Project, Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName14()}
                      >
                        {' '}
                        <div>Margin Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Margin Amount & Margin % report - Employee, Project, Year,
                          Month wise
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Logs </h3>
                <div className='card card-custom card-flush'>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName2()}
                      >
                        {' '}
                        <div>User Logs</div>
                        <div className='text-gray-600 fs-7'>User wise logs</div>
                      </li>
                      {disableQuickBooks && (
                        <li
                          className='text-primary fs-5 py-2'
                          role='button'
                          onClick={() => handleCustomerName3()}
                        >
                          {' '}
                          <div>QB logs</div>
                          <div className='text-gray-600 fs-7'>Quickbook logs</div>
                        </li>
                      )}
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          )}
          {localStorage.getItem('role_Name') === 'approver' && (
            <div className='row g-5'>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Basic Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName()}
                      >
                        {' '}
                        <div>Employee Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of Employees joined between selected date range
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName1()}
                      >
                        {' '}
                        <div>Project Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of Projects started between selected date range
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName7()}
                      >
                        {' '}
                        <div>Employee Status Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No. of employees joined and left between selected date range
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Timesheet Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName4()}
                      >
                        {' '}
                        <div>Employee Submission Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by Employees
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName5()}
                      >
                        {' '}
                        <div>Project Submission Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by Employee - Project wise
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName6()}
                      >
                        {' '}
                        <div>Timesheet Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by Employees - Day wise
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Compliance Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName8()}
                      >
                        {' '}
                        <div>Employee Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Monthly Total Timesheet Compliance %, Employee wise Timesheet Compliance %
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName9()}
                      >
                        {' '}
                        <div>Project Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Monthly Total Timesheet Compliance %, Employee & Project wise Timesheet
                          Compliance %
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName10()}
                      >
                        {' '}
                        <div>Organization Wise Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Yearly Timesheet Compliance % Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName11()}
                      >
                        {' '}
                        <div>Organization Wise Non Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Employee & Project wise Missed timesheet entries
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName15()}
                      >
                        {' '}
                        <div>Timesheet Compliance Report</div>
                        <div className='text-gray-600 fs-7'>
                          Organization Timesheet Compliance & Non Compliance Report - Employee,
                          Project, Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName16()}
                      >
                        {' '}
                        <div>Utilization Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Employee Utilization report - Project, Year, Month wise
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          )}
          {localStorage.getItem('role_Name') === 'accounts' && (
            <div className='row g-5'>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Basic Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName()}
                      >
                        {' '}
                        <div>Employee Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of Employees joined between selected date range
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName1()}
                      >
                        {' '}
                        <div>Project Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of Projects started between selected date range
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName7()}
                      >
                        {' '}
                        <div>Employee Status Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No. of employees joined and left between selected date range
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Timesheet Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName4()}
                      >
                        {' '}
                        <div>Employee Submission Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by Employees
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName5()}
                      >
                        {' '}
                        <div>Project Submission Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by Employee - Project wise
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName6()}
                      >
                        {' '}
                        <div>Timesheet Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by Employees - Day wise
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Compliance Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName8()}
                      >
                        {' '}
                        <div>Employee Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Monthly Total Timesheet Compliance %, Employee wise Timesheet Compliance %
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName9()}
                      >
                        {' '}
                        <div>Project Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Monthly Total Timesheet Compliance %, Employee & Project wise Timesheet
                          Compliance %
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName10()}
                      >
                        {' '}
                        <div>Organization Wise Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Yearly Timesheet Compliance % Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName11()}
                      >
                        {' '}
                        <div>Organization Wise Non Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Employee & Project wise Missed timesheet entries
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName15()}
                      >
                        {' '}
                        <div>Timesheet Compliance Report</div>
                        <div className='text-gray-600 fs-7'>
                          Organization Timesheet Compliance & Non Compliance Report - Employee,
                          Project, Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName16()}
                      >
                        {' '}
                        <div>Utilization Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Employee Utilization report - Project, Year, Month wise
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Financial Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName12()}
                      >
                        {' '}
                        <div>Revenue Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Revenue report - Employee, Project, Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName13()}
                      >
                        {' '}
                        <div>COGS Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Payable report - Employee, Project, Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName14()}
                      >
                        {' '}
                        <div>Margin Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Margin Amount & Margin % report - Employee, Project, Year,
                          Month wise
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          )}
          {localStorage.getItem('role_Name') === 'hr' && (
            <div className='row g-5'>
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Basic Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName()}
                      >
                        {' '}
                        <div>Employee Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of Employees joined between selected date range
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName1()}
                      >
                        {' '}
                        <div>Project Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of Projects started between selected date range
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName7()}
                      >
                        {' '}
                        <div>Employee Status Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No. of employees joined and left between selected date range
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ToastContainer />
        </div>
      */}

        <div>
          {/* {localStorage.getItem('role_Name') === 'admin' && ( */}
          <div className='row g-5'>
            {(numArray?.includes('admin') ||
              numArray?.includes('approver') ||
              numArray?.includes('accounts') ||
              numArray?.includes('timesheetadmin') ||
              numArray?.includes('hr')) && (
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Basic Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName()}
                      >
                        {' '}
                        <div>Consultant Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of Consultants joined between selected date range
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName1()}
                      >
                        {' '}
                        <div>Project Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of Projects started between selected date range
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName7()}
                      >
                        {' '}
                        <div>Consultant Status Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No. of consultants joined and left between selected date range
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName21()}
                      >
                        {' '}
                        <div>Consultant Count Report</div>
                        <div className='text-gray-600 fs-7'>
                          Count of Active, Joined, Exited consultants - Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName22()}
                      >
                        {' '}
                        <div>Project Count Report</div>
                        <div className='text-gray-600 fs-7'>
                          Count of Active, New, Completed projects - Year, Month wise
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            )}
            {(numArray?.includes('admin') ||
              numArray?.includes('approver') ||
              numArray?.includes('timesheetadmin') ||
              numArray?.includes('accounts')) && (
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Timesheet Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName4()}
                      >
                        {' '}
                        <div>Consultant Submission Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by consultants
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName5()}
                      >
                        {' '}
                        <div>Project Submission Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by consultant - Project wise
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName6()}
                      >
                        {' '}
                        <div>Timesheet Report</div>
                        <div className='text-gray-600 fs-7'>
                          Total No.of hours submitted by consultants - Day wise
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            )}
            {(numArray?.includes('admin') ||
              numArray?.includes('approver') ||
              numArray?.includes('timesheetadmin') ||
              numArray?.includes('accounts')) && (
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Compliance Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName8()}
                      >
                        {' '}
                        <div>Consultant Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Monthly Total Timesheet Compliance %, Consultant wise Timesheet Compliance
                          %
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName9()}
                      >
                        {' '}
                        <div>Project Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Monthly Total Timesheet Compliance %, Consultant & Project wise Timesheet
                          Compliance %
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName10()}
                      >
                        {' '}
                        <div>Organization Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Yearly Timesheet Compliance % Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName11()}
                      >
                        {' '}
                        <div>Organization Non Compliance Report </div>
                        <div className='text-gray-600 fs-7'>
                          Consultant & Project wise Missed timesheet entries
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName15()}
                      >
                        {' '}
                        <div>Timesheet Compliance Report</div>
                        <div className='text-gray-600 fs-7'>
                          Organization Timesheet Compliance & Non Compliance Report - Employee,
                          Project, Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName16()}
                      >
                        {' '}
                        <div>Utilization Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Employee Utilization report - Project, Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName20()}
                      >
                        {' '}
                        <div>Organization Compliance Report - Weekly </div>
                        <div className='text-gray-600 fs-7'>Timesheet Compliance % week wise</div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            )}
            {(numArray?.includes('admin') || numArray?.includes('accounts')) && (
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Financial Reports </h3>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName12()}
                      >
                        {' '}
                        <div>Revenue Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Revenue report - Employee, Project, Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName17()}
                      >
                        {' '}
                        <div>Revenue Report - Monthly</div>
                        <div className='text-gray-600 fs-7'>
                          Organization Revenue report - Employee, Project wise monthly
                        </div>
                      </li>

                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName13()}
                      >
                        {' '}
                        <div>COGS Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Payable report - Employee, Project, Year, Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName18()}
                      >
                        {' '}
                        <div>COGS Report - Monthly </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Payable report - Employee, Project wise monthly
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName14()}
                      >
                        {' '}
                        <div>Margin Report </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Margin Amount & Margin % report - Employee, Project, Year,
                          Month wise
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName19()}
                      >
                        {' '}
                        <div>Margin Report - Monthly </div>
                        <div className='text-gray-600 fs-7'>
                          Organization Margin Amount & Margin % report - Employee, Project wise
                          monthly
                        </div>
                      </li>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName23()}
                      >
                        {' '}
                        <div>Work Location Report </div>
                        <div className='text-gray-600 fs-7'>
                          Revenue details based on work location – Year, Month wise
                        </div>
                      </li>
                      {localStorage.getItem('enable_unbilled') == 'true' && (
                        <li
                          className='text-primary fs-5 py-2'
                          role='button'
                          onClick={() => handleCustomerName24()}
                        >
                          {' '}
                          <div>Unbilled Report </div>
                          <div className='text-gray-600 fs-7'>
                            Expected Hours, Revenue, Payable between selected date range-
                            projectwise
                          </div>
                        </li>
                      )}
                    </ol>
                  </div>
                </div>
              </div>
            )}
            {numArray?.includes('admin') && (
              <div className='col-xs-12 col-md-12 col-lg-6'>
                <h3 className='text-gray-900 fs-3 fw-semibold  py-2'>Logs </h3>
                <div className='card card-custom card-flush'>
                  <div className='card-body p-4 py-5'>
                    <ol>
                      <li
                        className='text-primary fs-5 py-2'
                        role='button'
                        onClick={() => handleCustomerName2()}
                      >
                        {' '}
                        <div>User Logs</div>
                        <div className='text-gray-600 fs-7'>User wise logs</div>
                      </li>
                      {disableQuickBooks && (
                        <li
                          className='text-primary fs-5 py-2'
                          role='button'
                          onClick={() => handleCustomerName3()}
                        >
                          {' '}
                          <div>QB logs</div>
                          <div className='text-gray-600 fs-7'>Quickbook logs</div>
                        </li>
                      )}
                    </ol>
                  </div>
                </div>
              </div>
            )}
          </div>

          <ToastContainer />
        </div>
      </form>
    </>
  )
}
export default Reports
