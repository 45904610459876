/* eslint-disable jsx-a11y/anchor-is-valid */

import clsx from 'clsx'

import {useState, useEffect, useRef} from 'react'

import {KTSVG} from '../../../helpers'

import {CreateAppModal} from '../../../partials'

import {useLayout} from '../../core'

import {DefaultTitle} from '../header/page-title/DefaultTitle'

import SVG from 'react-inlinesvg'
import {useNavigate} from 'react-router-dom'

import {useForm} from 'react-hook-form'
import {Link} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import DatePicker from "react-datepicker";
import {useListView} from '../../../../app/modules/apps/user-management/users-list/core/ListViewProvider'
// import DatePicker1 from 'react-datepicker'
import {default as Apiservices} from '../../../../common/index'
import {UsersListFilter} from '../../../../app/modules/apps/user-management/users-list/components/header/UsersListFilter'
import Spinner from '../../../../app/pages/dashboard/Spinner'
import BackDrop from '../../../../app/modules/apps/timesheetentry/ComponentBackdrop'
import Popup from 'reactjs-popup'
import Info from '../../../../localMedia/info.svg'
import {DatePicker, Space} from 'antd'
import moment from 'moment'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import {Tooltip} from '../../../../app/modules/apps/timesheetentry/ComponentTooltip'
import VacationPop from '../../../../app/modules/apps/masters/modal/VacationPop'
const Toolbar1: React.FC<any> = (props: any) => {
  const {
    register,

    setValue,

    reset,
    getValues,
    resetField,

    handleSubmit,

    formState: {errors},
  } = useForm()
  let orgID = localStorage.getItem('logged_in_id')

  const {classes} = useLayout()

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)

  const [openFilter, setOpenFilter] = useState<any | []>(false)

  const {setItemIdForUpdate} = useListView()

  const [mainTableData, setMainTableData] = useState([])

  const [isLoaded, setLoaded] = useState(false)

  const [resetvalues, setResetvalues] = useState(true)
  const navigate = useNavigate()
  // const [dateRange, setDateRange] = useState([])
  const [dateRange, setDateRange] = useState<any>([])
  const [dates, setDates] = useState<any>([])
  const [dates1, setDates1] = useState<any>([])
  // const [dates, setDates] = useState([])
  // const [dateRange, setDateRange] = useState<any | []>();
  const [isLoading, setIsLoading] = useState<any | []>(false)
  const [ApplyPopupOpen, setApplyPopupOpen] = useState<any>(false)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)

  const onClose = (args: any) => {
    args.preventDefault()
  }
  const options1: any = [
    // {
    //   label: '',

    //   value: '',
    // },

    {
      label: 'In Progress',

      value: 'IN_PROGRESS',
    },

    {
      label: 'Completed',

      value: 'COMPLETED',
    },
    {
      label: 'Initiated',

      value: 'INITIATED',
    },
    {
      label: 'Cancelled',

      value: 'CANCELLED',
    },
    {
      label: 'Rejected',

      value: 'REJECTED',
    },
    {
      label: 'All',

      value: '',
    },
  ]

  const options2: any = [
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    {
      label: 'Internal',
      value: 'Internal',
    },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
    {
      label: 'All',

      value: '',
    },
  ]

  const ApplyVacation = async (vacation_type: any, startDate: any, endDate: any) => {
    const userID = localStorage.getItem('userid')

    var days = []
    var day: any = moment(startDate)
    while (day.isSameOrBefore(endDate)) {
      days.push(day.toDate())
      day = day.add(1, 'days')
    }
    let arr = []
    for (let each of days) {
      let week1 = moment(each, 'ddd MMM DD YYYY').format('YYYY-MM-DD')
      arr.push(week1)
    }
    let DatesArray = arr
    const article = {
      user_id: Number(userID),
      absent_type: Number(vacation_type),
      dates: DatesArray,
    }
    setApplyPopupOpen(false)
    const response: any = await Apiservices.applyLeavesHolidays(article)
    if (response && response.statuscode == 200) {
      console.log(response)
      toast.success('Leave Applied Successfully')
      setTimeout(function () {
        reloadPage()
      }, 1000)
    }
  }

  const [isProjectStatusMenuOpen, setIsProjectStatusMenuOpen] = useState(false)
  const [isMenuProjectBusinessOpen, setIsMenuProjectBusinessOpen] = useState(false)
  const [isMenuProjectConsultantTypeOpen, setIsMenuProjectConsultantTypeOpen] = useState(false)
  const [isMenuProjectTypeOpen, setIsMenuProjectTypeOpen] = useState(false)
  const [isMenuUsersRoleOpen, setIsMenuUsersRoleOpen] = useState(false)
  const [isMenuUsersBusinessTypeOpen, setIsMenuUsersBusinessOpen] = useState(false)
  const [isMenuConsultantConsultantTypeOpen, setIsMenuConsultantConsultantOpen] = useState(false)
  const [isMenuConsultantBusinessTypeOpen, setIsMenuConsultantBusinessOpen] = useState(false)
  const [isMenuBusinessBusinessTypeOpen, setIsMenuBusinessBusinessOpen] = useState(false)
  const [isMenuBranchBranchTypeOpen, setIsMenuBranchBranchOpen] = useState(false)
  const [isMenuDepartmentDepartmentTypeOpen, setIsMenuDepartmentDepartmentOpen] = useState(false)
  const [isMenuMyConsultantBUTypeOpen, setIsMenuMyConsultantBUOpen] = useState(false)
  const [isMenuAnnouncementBUTypeOpen, setIsMenuAnnouncementBUTypeOpen] = useState(false)
  const handleFocus = () => {
    setIsProjectStatusMenuOpen(true) // Open the dropdown when input is clicked
  }
  const handleProjectBusinessFocus = () => {
    setIsMenuProjectBusinessOpen(true)
  }
  const handleProjectConsultantTypeFocus = () => {
    setIsMenuProjectConsultantTypeOpen(true)
  }

  const handleConsultantConsultantTypeFocus = () => {
    setIsMenuConsultantConsultantOpen(true)
  }
  const handleConsultantBusinessFocus = () => {
    setIsMenuConsultantBusinessOpen(true)
  }
  const handleBusinessBusinessFocus = () => {
    setIsMenuBusinessBusinessOpen(true)
  }
  const handleBranchBranchFocus = () => {
    setIsMenuBranchBranchOpen(true)
  }
  const handleAnnouncementFocus = () => {
    setIsMenuAnnouncementBUTypeOpen(true)
  }
  const handleDeptDeptFocus = () => {
    setIsMenuDepartmentDepartmentOpen(true)
  }
  const handleMyConsultantFocus = () => {
    setIsMenuMyConsultantBUOpen(true)
  }
  const handleProjectTypeFocus = () => {
    setIsMenuProjectTypeOpen(true)
  }

  const handleUsersRoleFocus = () => {
    setIsMenuUsersRoleOpen(true)
  }
  const handleUsersBusinessFocus = () => {
    setIsMenuUsersBusinessOpen(true)
  }
  const handleCloseAllPopups = () => {
    setIsProjectStatusMenuOpen(false)
    setIsMenuProjectBusinessOpen(false)
    setIsMenuProjectConsultantTypeOpen(false)
    setIsMenuProjectTypeOpen(false)
    setIsMenuUsersRoleOpen(false)
    setIsMenuUsersBusinessOpen(false)
    setIsMenuConsultantConsultantOpen(false)
    setIsMenuConsultantBusinessOpen(false)
    setIsMenuBusinessBusinessOpen(false)
    setIsMenuBranchBranchOpen(false)
    setIsMenuDepartmentDepartmentOpen(false)
    setIsMenuAnnouncementBUTypeOpen(false)
    setIsMenuMyConsultantBUOpen(false)
  }
  const handleItemSelection = (selected: any) => {
    // setIsProjectStatusMenuOpen(false) // Optionally close the dropdown after selecting an item
    props.handleProjectStatus(selected)
  }
  const handleProjectTypeSelection = (selected: any) => {
    setIsProjectStatusMenuOpen(false) // Optionally close the dropdown after selecting an item
    props.handleProjectType(selected)
  }
  const handleBusinessProjectItemSelection = (selected: any) => {
    // setIsMenuProjectBusinessOpen(false)
    props.handleBusinessUnit(selected)
  }
  const handleConsultantTypeProjectItemSelection = (selected: any) => {
    props.handleConsultantType(selected)
  }
  const handleConsultantTypeConsultantItemSelection = (selected: any) => {
    props.handleConsultantType(selected)
  }

  const handleBusinessTypeConsultantItemSelection = (selected: any) => {
    props.handleBusinessUnit(selected)
  }
  const handleBusinessTypeBusinessItemSelection = (selected: any) => {
    props.handleBusinessUnit(selected)
  }
  const handleBranchTypeBranchItemSelection = (selected: any) => {
    props.handleBranchUnit(selected)
  }
  const handleDeptTypeDepartmentItemSelection = (selected: any) => {
    props.handleBusinessUnit(selected)
  }
  const handleBUTypeMyConsultantItemSelection = (selected: any) => {
    props.handleBusinessUnit(selected)
  }
  const handleProjectTypeProjectItemSelection = (selected: any) => {
    props.handleProjectType(selected)
  }
  const handleRoleUsersItemSelection = (selected: any) => {
    props.handleConsultantType(selected)
  }
  const handleBusinessUsersItemSelection = (selected: any) => {
    props.handleBusinessUnit(selected)
  }
  const handleBusinessAnnouncementItemSelection = (selected: any) => {
    props.handleBusinessUnit(selected)
  }
  const ApplyLeavePopUpOpen = () => {
    setApplyPopupOpen(true)
  }
  const ApplyLeavePopUpClose = () => {
    setApplyPopupOpen(false)
  }

  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  const Filter = () => {
    setOpenFilter(true)
  }
  const ref: any = useRef<HTMLInputElement>(null)
  const typeaheadRef6: any = useRef<HTMLInputElement>(null)
  const typeaheadRef8: any = useRef<HTMLInputElement>(null)
  if (ref.current !== null) {
    // ref.current?.focus()
  }
  const openTooltip = () => {
    ref.current.open()
    setIsProjectStatusMenuOpen(false)
    setIsMenuProjectBusinessOpen(false)
    setIsMenuProjectConsultantTypeOpen(false)
    setIsMenuProjectTypeOpen(false)
    setIsMenuUsersRoleOpen(false)
    setIsMenuUsersBusinessOpen(false)
    setIsMenuConsultantConsultantOpen(false)
    setIsMenuConsultantBusinessOpen(false)
    setIsMenuBusinessBusinessOpen(false)
    setIsMenuBranchBranchOpen(false)
    setIsMenuDepartmentDepartmentOpen(false)
    setIsMenuAnnouncementBUTypeOpen(false)
    setIsMenuMyConsultantBUOpen(false)
  }
  const closeTooltip = () => {
    ref.current.close()
    props.userFilter()
    // props.handleClick()
  }

  const closeConsultantTooltip = () => {
    ref.current.close()
    props.consultantFilter()
  }

  const closeProjectTooltip = () => {
    ref.current.close()
    props.projectFilter()
    props.setProjectFilterOpen(true)
  }
  const closeAnnouncementTooltip = () => {
    ref.current.close()
    props.userFilter()
    props.setUserFilterOpen(true)
  }
  const closeMyProjectTooltip = () => {
    ref.current.close()
    props.myProjectFilter()
  }

  const closeTimeSheetTooltip = () => {
    ref.current.close()
    handleClick6()
  }
  const closeTimesheetTooltip = () => {
    ref.current.close()
    props.timesheetFilter()
  }

  const closeTimesheetResetTooltip = () => {
    ref.current.close()
    handleClick7()
  }

  const closeClientTooltip = () => {
    ref.current.close()
    props.clientFilter()
  }

  const closeResetTooltip = () => {
    ref.current.close()
    props.resetForm()
    setValue('business_unit_id', '')
  }
  const closeDocumentTypesTooltip = () => {
    ref.current.close()
    props.manageDocumentFilter()
    props.setDocumentFilterOpen(true)
  }
  // const toggleTooltip = () => ref.current.toggle()
  const userSyncData = async () => {
    let date = props.userUpdateDate

    setIsLoading(true)
    const response: any = await Apiservices.manageUserData({org_id: orgID, date: date})
    setIsLoading(false)
    if (response && response.data && response.data.isError == false) {
      toast.success('Data Synced Successfully')
    }
    setTimeout(function () {
      reloadPage()
    }, 3000)
  }
  const token: any = localStorage.getItem('token')?.split(' ')
  const BusinessSyncData = async () => {
    let date = props.userUpdateDate
    setIsLoading(true)
    const response: any = await Apiservices.syncBusinessUnits({
      org_id: orgID,
      date: date,
      token: token[1],
    })
    setIsLoading(false)
    if (response && response.data && response.data.isError == false) {
      toast.success('Data Synced Successfully')
    }
    setTimeout(function () {
      reloadPage()
    }, 3000)
  }
  const BranchSyncData = async () => {
    let date = props.userUpdateDate
    setIsLoading(true)
    const response: any = await Apiservices.syncBranches({
      org_id: orgID,
      date: date,
      token: token[1],
    })
    setIsLoading(false)
    console.log(response.data)
    if (response && response.data && response.data.isError == false) {
      toast.success('Data Synced Successfully')
    }
    setTimeout(function () {
      reloadPage()
    }, 3000)
  }
  const DepartmentSyncData = async () => {
    let date = props.userUpdateDate
    setIsLoading(true)
    const response: any = await Apiservices.syncDepartments({
      org_id: orgID,
      date: date,
      token: token[1],
    })
    setIsLoading(false)
    console.log(response.data)
    if (response && response.data && response.data.isError == false) {
      toast.success('Data Synced Successfully')
    }
    setTimeout(function () {
      reloadPage()
    }, 3000)
  }

  const [startDate, setStartDate] = useState<any | null>(null)
  const [endDate, setEndDate] = useState<any | null>(null)
  const [focusedInput, setFocusedInput] = useState(null)
  const projectSyncData = async () => {
    let date = props.projectUpdateDate

    setIsLoading(true)
    const response: any = await Apiservices.projectUserData({date: date, org_id: orgID})
    setIsLoading(false)
    if (response && response.data && response.data.isError == false) {
      toast.success('Data Synced Successfully')
    }
    setTimeout(function () {
      reloadPage()
    }, 3000)
  }
  const {RangePicker} = DatePicker
  const reloadPage = () => {
    window.location.reload()
  }
  const consultantSyncData = async () => {
    let date = props.updateDate1
    setIsLoading(true)
    const response: any = await Apiservices.manageUserData({org_id: orgID, date: date})
    setIsLoading(false)
    if (response && response.data && response.data.message == 'Data Synced Successfully') {
      toast.success('Data Synced Successfully')
    }

    setTimeout(function () {
      reloadPage()
    }, 3000)
  }
  // const [value, setValue1] = useState(moment().format('YYYY-MM-DD'));
  const [value1, setValue1] = useState<any>([])
  const [value2, setValue2] = useState<any>([])

  const onChangeDate = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue1(newDate)
    props.setFilterDepartment(newDate)
  }
  const onChangeDate1 = (e: any) => {
    const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD')
    setValue2(newDate)
    props.setFilterStatus(newDate)
  }
  const [country, setCountry] = useState<any | []>([])
  const [country1, setCountry1] = useState<any | []>([])
  const [countries, setCountries] = useState<any | []>([])
  const [countries1, setCountries1] = useState<any | []>([])
  const [projectIdData, setProjectIdData] = useState<any | []>([])

  const greetUser = async () => {
    const response: any = await Apiservices.employeereportViewData({
      // start_date: dates,
      // end_date: dates1
      start_date: value1,
      end_date: value2,
    })
  }

  // employeeSubmissionReports

  const handleCountries = async (country: any) => {
    if (country && country.length >= 2) {
      const response: any = await Apiservices.employeeSubmissionReports()

      setCountry(response.data.data ? response.data.data : [])
    }
  }
  const selectedCountries = async (selected_country_name: any) => {
    setProjectIdData(selected_country_name[0].project_id)
    setCountries(selected_country_name)
    props.func(selected_country_name[0].project_id)
  }
  const handleCountries1 = async (country1: any) => {
    if (country1 && country1.length >= 2) {
      const response: any = await Apiservices.employeeSubmissionReports()

      if (response.data.data) {
        setCountry1(response.data.data ? response.data.data : [])
      } else {
        setCountry1([])
      }
    }
  }
  const selectedCountries1 = async (selected_country_name: any) => {
    setProjectIdData(selected_country_name[0].user_id)
    setCountries1(selected_country_name)
    props.func1(selected_country_name[0].user_id)
  }
  const handleClick6 = async () => {
    props.func2('anil')
  }
  const handleClick7 = async () => {
    setCountries([])
    setCountries1([])

    props.func3('anil')
  }

  const handleReset = async (e: any) => {
    setValue2((e.target.value = ''))
    setValue1((e.target.value = ''))
  }
  let business: any = localStorage.getItem('role_Name')
  let numArray: any = business?.split(',')
  return isLoading ? (
    <BackDrop showBackDrop={true} />
  ) : (
    <>
      {ApplyPopupOpen ? (
        <VacationPop ApplyLeavePopUpClose={ApplyLeavePopUpClose} ApplyVacation={ApplyVacation} />
      ) : (
        ''
      )}
      <div className='toolbar' id='kt_toolbar'>
        {/* begin::Container */}

        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />

          <div className='d-flex gap-3'>
            {window.location.pathname == '/timetracker/masters/manageusers' && (
              <>
                <div className='d-none d-sm-block'>
                  {' '}
                  <input
                    type='text'
                    id='form1'
                    className='form-control form-control-sm'
                    // value={props.search}
                    placeholder='Search'
                    // onChange={(e) => props?.setSearch(e.target.value)}
                    onChange={(e) => props?.userSearch(e.target.value)}
                  />
                </div>
                <div className='d-none d-sm-block'>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Status:</label>
                          <div>
                            <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterStatus(e.target.value)}
                              value={props.filterstatus}
                            >
                              {/* <option value=''></option> */}

                              <option value='ACTIVE'>Active</option>
                              <option value='InActive'>Inactive</option>
                              <option value=''>All</option>
                            </select>
                          </div>
                        </div>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Role:</label>

                          <div onClick={handleUsersRoleFocus}>
                            {/* <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterDepartment(e.target.value)}
                              value={props.filterdepartment}
                            >

                              <option value='2'>Admin</option>
                              <option value='3'>Hr</option>
                              <option value='4'>Accounts</option>
                              <option value='5'>Approver</option>
                              
                              <option value='8'>Timesheet Admin</option>
                              <option value='9'>Relationship Manager</option>
                              <option value=''>All</option>
                            </select> */}

                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              // onChange={(selected: any) => {
                              //   props.handleConsultantType(selected)
                              //   // setSelectConsultant(selected)
                              //   // Keep the menu open when making multiple selections.
                              //   // typeaheadRef.current.toggleMenu()
                              // }}
                              onChange={handleRoleUsersItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.AllOptions}
                              placeholder='Select Consultant Type'
                              // ref={typeaheadRef}
                              selected={props.filterdepartment}
                              open={isMenuUsersRoleOpen}
                            />
                          </div>
                        </div>

                        <div className='mb-5'>
                          <label className='form-label'>Business Unit</label>
                          {/* <select
                            className={`form-select ${errors.business_unit_id ? 'is-invalid' : ''}`}
                            data-control='select2'
                            {...register('business_unit_id')}
                            data-hide-search='true'
                            onChange={(event) => {
                              props.handleBusinessUnit(event.target.value)
                            }}
                            // value={props.BusinessUnitId}
                          >
                            <option value=''>Select Business Unit</option>
                            {props.BusinessUnitsList &&
                              props.BusinessUnitsList.length > 0 &&
                              props.BusinessUnitsList.map((option: any) => {
                                return (
                                  <option value={option.business_unit_id}>{option.name}</option>
                                )
                              })}
                          </select> */}
                          <div onClick={handleUsersBusinessFocus}>
                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              // onChange={(e) => {
                              //   // setBusinessUnit(e)
                              //   props.handleBusinessUnit(e)
                              //   // typeaheadRef4.current.toggleMenu()
                              // }}
                              onChange={handleBusinessUsersItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.BusinessUnitsList}
                              ref={typeaheadRef4}
                              placeholder='Select Business Unit'
                              selected={props.BusinessUnitId}
                              labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                              open={isMenuUsersBusinessTypeOpen}
                              //   inputProps={{required: true}}
                            ></Typeahead>
                          </div>
                        </div>
                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>
                <div>
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}

                      // onClick={handleClick}
                    >
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                      {/* <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted svg-icon-2hx" /> */}
                    </button>
                  </Tooltip>
                </div>
                <div className='d-none d-sm-block'>
                  <Tooltip text='View Sync'>
                    <div>
                      <Popup
                        trigger={
                          <button className='btn btn-icon btn-light  btn-active-color-primary btn-sm'>
                            <i className='fa fa-eye fs-4' aria-hidden='true'></i>
                          </button>
                        }
                        position='bottom center'
                      >
                        <div>
                          <div className='p-3'>
                            <div className='text-gray-900 fs-7 fw-semibold'>
                              Last Sync Time: {props.consultantUpdatedTime}
                            </div>
                            <div className='border-top my-3'></div>
                            <div className='text-gray-900 fs-7 fw-semibold'>
                              Last Sync Date: {props.consultantCreatedTime}
                            </div>
                          </div>
                        </div>
                      </Popup>
                    </div>
                  </Tooltip>
                </div>
                <div>
                  <button className='btn btn-sm btn-light-primary ' onClick={() => userSyncData()}>
                    Sync Users
                  </button>{' '}
                </div>
              </>
            )}{' '}
            {window.location.pathname == '/timetracker/masters/manageprojects' && (
              <>
                <div className='d-none d-sm-block'>
                  <input
                    type='text'
                    id='form1'
                    className='form-control form-control-sm'
                    // value={props.search}
                    placeholder='Search'
                    // onChange={(e) => props?.setSearch(e.target.value)}
                    onChange={(e) => props?.projectSearch(e.target.value)}
                  />
                </div>
                <div className='d-none d-sm-block'>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-2'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-2'>
                        <div className='mb-2'>
                          <label className='form-label fw-semibold'>Status:</label>

                          {/* <div> */}
                          <div onClick={handleFocus}>
                            {/* <select
                              className={`form-select ${errors.status ? 'is-invalid' : ''}`}
                              data-control='select2'
                              {...register('status')}
                              data-hide-search='true'
                              onChange={(event) => {
                                props.setFilterStatus(event.target.value)
                              }}
                              value={props.filterstatus}
                            >
                              {options1.map((option: any) => {
                                return <option value={option.value}>{option.label}</option>
                              })}
                            </select> */}

                            {/* <Typeahead
                              multiple
                              id='keep-menu-open'
                              onChange={(e) => {
                                props.handleConsultantType(e)
                                // Keep the menu open when making multiple selections.
                                // typeaheadRef6.current.toggleMenu()
                              }}
                              options={props.AllProjectStatusOptions}
                              placeholder='Select Project Status'
                              // ref={typeaheadRef6}
                              selected={props.selectProjectStatus}
                            /> */}
                            <Typeahead
                              multiple
                              id='typeahead-example'
                              ref={typeaheadRef6}
                              onChange={handleItemSelection} // Handle item selection
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.AllProjectStatusOptions}
                              placeholder='Select Project Status'
                              selected={props.selectProjectStatus}
                              open={isProjectStatusMenuOpen} // Control menu visibility
                            />
                          </div>
                        </div>
                        <div className='mb-2'>
                          <label className='form-label '>Project Type </label>
                          <div onClick={handleProjectTypeFocus}>
                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              // onChange={props.handleProjectType}
                              onChange={handleProjectTypeProjectItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.AllProjectTypeOptions}
                              placeholder='Select Project Type'
                              selected={props.selectProjectType}
                              // labelKey={(list:any) => user(list.project_type)}
                              open={isMenuProjectTypeOpen}
                            />
                          </div>
                        </div>
                        <div className='mb-2'>
                          <label className='form-label'>Consultant Name</label>
                          <Typeahead
                            // id='basic-typeahead-single'
                            multiple
                            id='keep-menu-open'
                            onChange={(e) => props.selectedConsultant(e)}
                            onInputChange={(e) => props.handleFilterConsultant(e)}
                            options={props.candidateList}
                            placeholder='Search Consultant'
                            selected={props.select_consultant_name}
                            labelKey={(candidateList: any) => user(candidateList.full_name)}
                          >
                            {/* {({onClear, selected}) => (
                              <div className='rbt-aux'>
                                {!!selected.length && <ClearButton onClick={onClear} />}
                              </div>
                            )} */}
                          </Typeahead>
                        </div>
                        <div className='mb-2'>
                          <label className='form-label'>Consultant Type:</label>
                          <div onClick={handleProjectConsultantTypeFocus}>
                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              // onChange={(e) => {
                              //   props.handleConsultantType(e)
                              //   // Keep the menu open when making multiple selections.
                              //   // typeaheadRef6.current.toggleMenu()
                              // }}
                              onChange={handleConsultantTypeProjectItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.ConsultantTypeOptions}
                              placeholder='Select Consultant Type'
                              // ref={typeaheadRef6}
                              selected={props.ConsultantType}
                              // labelKey={(AllProjectStatusOptions: any) => AllProjectStatusOptions.name}
                              open={isMenuProjectConsultantTypeOpen}
                            />
                          </div>
                        </div>
                        <div className='mb-2'>
                          {/* onClick={handleProjectBusinessFocus}> */}
                          <label className='form-label'>Business Unit</label>
                          <div onClick={handleProjectBusinessFocus}>
                            {/* <select
                            className={`form-select ${errors.business_unit_id ? 'is-invalid' : ''}`}
                            data-control='select2'
                            {...register('business_unit_id')}
                            data-hide-search='true'
                            onChange={(event) => {
                              props.handleBusinessUnit(event.target.value)
                            }}
                            value={props.BusinessUnitId}
                          >
                            <option value=''>Select Business Unit</option>
                            {props.BusinessUnitsList &&
                              props.BusinessUnitsList.length > 0 &&
                              props.BusinessUnitsList.map((option: any) => {
                                return (
                                  <option value={option.business_unit_id}>{option.name}</option>
                                )
                              })}
                          </select> */}

                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              // onChange={(e) => {
                              //   props.handleBusinessUnit(e)
                              //   // typeaheadRef4.current.toggleMenu()
                              // }}
                              onChange={handleBusinessProjectItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.BusinessUnitsList}
                              // ref={typeaheadRef4}
                              placeholder='Select Business Unit'
                              selected={props.BusinessUnitId}
                              labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                              open={isMenuProjectBusinessOpen}
                              //   inputProps={{required: true}}
                            ></Typeahead>
                          </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeProjectTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>{' '}
                <div>
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}

                      // onClick={handleClick}
                    >
                      {/* Export To Excel */}
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                    </button>
                  </Tooltip>
                </div>
                <div className='d-none d-sm-block'>
                  <Tooltip text='View Sync'>
                    <div>
                      <Popup
                        trigger={
                          <button className='btn btn-icon btn-light  btn-active-color-primary btn-sm'>
                            <i className='fa fa-eye fs-4' aria-hidden='true'></i>
                          </button>
                        }
                        position='bottom center'
                      >
                        <div>
                          <div className='p-3'>
                            <div className='text-gray-900 fs-7 fw-semibold'>
                              Last Sync Time: {props.consultantUpdatedTime}
                            </div>
                            <div className='border-top my-3'></div>
                            <div className='text-gray-900 fs-7 fw-semibold'>
                              Last Sync Date: {props.consultantCreatedTime}
                            </div>
                          </div>
                        </div>
                      </Popup>
                    </div>
                  </Tooltip>
                </div>
                <div>
                  <button
                    className='btn btn-sm btn-light-primary '
                    onClick={() => projectSyncData()}
                  >
                    Sync Projects
                  </button>{' '}
                </div>
                {/* {localStorage.getItem('role_Name') != 'accounts' ? (
                  <> */}
                <div>
                  {' '}
                  <Link to='/masters/manageprojects/addproject'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr075.svg'
                        className='svg-icon-5 '
                      />
                      Add Project
                    </button>
                  </Link>
                </div>
                {/* </>
                ) : (
                  ''
                )} */}
              </>
            )}
            {window.location.pathname == '/timetracker/masters/manageannouncements' && (
              <>
                <div className='d-none d-sm-block'>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Status</label>

                          <div>
                            <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterStatus(e.target.value)}
                              value={props.filterstatus}
                            >
                              <option value=''>Select</option>
                              <option value='Active'>Active</option>
                              <option value='Inactive'>Inactive</option>
                              <option value=''>All</option>
                            </select>
                          </div>
                        </div>
                        <div className='mb-5'>
                          <label className='form-label'>Business Unit</label>
                          {/* <select
                            className={`form-select ${errors.business_unit_id ? 'is-invalid' : ''}`}
                            data-control='select2'
                            {...register('business_unit_id')}
                            data-hide-search='true'
                            onChange={(event) => {
                              props.handleBusinessUnit(event.target.value)
                            }}
                            value={props.BusinessUnitId}
                          >
                            <option value=''>Select Business Unit</option>
                            {props.BusinessUnitsList &&
                              props.BusinessUnitsList.length > 0 &&
                              props.BusinessUnitsList.map((option: any) => {
                                return (
                                  <option value={option.business_unit_id}>{option.name}</option>
                                )
                              })}
                          </select> */}
                          <div onClick={handleAnnouncementFocus}>
                            <Typeahead
                              multiple
                              // id='keep-menu-open'
                              id='typeahead-example'
                              ref={typeaheadRef8}
                              // onChange={(e) => {
                              //   props.handleBusinessUnit(e)
                              //   // typeaheadRef4.current.toggleMenu()
                              // }}
                              onChange={handleBusinessAnnouncementItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.BusinessUnitsList}
                              // ref={typeaheadRef4}
                              placeholder='Select Business Unit'
                              selected={props.BusinessUnitId}
                              labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                              open={isMenuAnnouncementBUTypeOpen}
                              //   inputProps={{required: true}}
                            ></Typeahead>
                          </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeAnnouncementTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>{' '}
                {/* <div>
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}
                    >
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                    </button>
                  </Tooltip>
                </div> */}
                <div>
                  <Link to='/masters/manageannouncements/addannouncement'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary cursor-pointer mx-4'
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-5' />
                      Add Announcement
                    </button>
                  </Link>
                </div>
              </>
            )}
            {window.location.pathname == '/timetracker/masters/businessunit' && (
              <>
                <div className='d-none d-sm-block'>
                  {' '}
                  <input
                    type='text'
                    id='form1'
                    className='form-control form-control-sm'
                    // value={props.search}
                    placeholder='Search'
                    // onChange={(e) => props?.setSearch(e.target.value)}
                    onChange={(e) => props?.userSearch(e.target.value)}
                  />
                </div>
                <div className='d-none d-sm-block'>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Status:</label>

                          <div>
                            <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterStatus(e.target.value)}
                              value={props.filterstatus}
                            >
                              {/* <option value=''></option> */}

                              <option value='ACTIVE'>Active</option>
                              <option value='InActive'>Inactive</option>
                              <option value=''>All</option>
                            </select>
                          </div>
                        </div>

                        {/* <div className='mb-5'>
                          <label className='form-label fw-semibold'>Department Name:</label>

                          <div>
                            <input
                              type='text'
                              className='form-control'
                              value={props.filterdepartment}
                              placeholder='Enter Department name'
                              {...register('name', {
                                onChange: (e) => {
                                  props.setFilterDepartment(e.target.value)
                                },
                              })}
                            />
                          </div>
                        </div> */}
                        <div className='mb-5'>
                          <label className='form-label'>Business Unit</label>
                          {/* <select
                            className={`form-select ${errors.business_unit_id ? 'is-invalid' : ''}`}
                            data-control='select2'
                            {...register('business_unit_id')}
                            data-hide-search='true'
                            onChange={(event) => {
                              props.handleBusinessUnit(event.target.value)
                            }}
                            // value={props.BusinessUnitId}
                          >
                            <option value=''>Select Business Unit</option>
                            {props.BusinessUnitsList &&
                              props.BusinessUnitsList.length > 0 &&
                              props.BusinessUnitsList.map((option: any) => {
                                return (
                                  <option value={option.business_unit_id}>{option.name}</option>
                                )
                              })}
                          </select> */}
                          <div onClick={handleBusinessBusinessFocus}>
                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              // onChange={(e) => {
                              //   props.handleBusinessUnit(e)
                              //   // typeaheadRef4.current.toggleMenu()
                              // }}
                              onChange={handleBusinessTypeBusinessItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.BusinessUnitsList}
                              // ref={typeaheadRef4}
                              placeholder='Select Business Unit'
                              selected={props.BusinessUnitId}
                              labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                              //   inputProps={{required: true}}
                              open={isMenuBusinessBusinessTypeOpen}
                            ></Typeahead>
                          </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>
                <div>
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}

                      // onClick={handleClick}
                    >
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                      {/* <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted svg-icon-2hx" /> */}
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <button
                    className='btn btn-sm btn-light-primary '
                    onClick={() => BusinessSyncData()}
                  >
                    Sync Business Units
                  </button>{' '}
                </div>
              </>
            )}{' '}
            {window.location.pathname == '/timetracker/masters/managebranches' && (
              <>
                <div className='d-none d-sm-block'>
                  {' '}
                  <input
                    type='text'
                    id='form1'
                    className='form-control form-control-sm'
                    // value={props.search}
                    placeholder='Search'
                    // onChange={(e) => props?.setSearch(e.target.value)}
                    onChange={(e) => props?.userSearch(e.target.value)}
                  />
                </div>
                <div className='d-none d-sm-block'>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Status:</label>

                          <div>
                            <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterStatus(e.target.value)}
                              value={props.filterstatus}
                            >
                              {/* <option value=''></option> */}

                              <option value='ACTIVE'>Active</option>
                              <option value='InActive'>Inactive</option>
                              <option value=''>All</option>
                            </select>
                          </div>
                        </div>

                        {/* <div className='mb-5'>
                          <label className='form-label fw-semibold'>Department Name:</label>

                          <div>
                            <input
                              type='text'
                              className='form-control'
                              value={props.filterdepartment}
                              placeholder='Enter Department name'
                              {...register('name', {
                                onChange: (e) => {
                                  props.setFilterDepartment(e.target.value)
                                },
                              })}
                            />
                          </div>
                        </div> */}
                        {/* <div className='mb-5'>
                          <label className='form-label'>Branches</label> */}
                        {/* <select
                            className={`form-select ${errors.business_unit_id ? 'is-invalid' : ''}`}
                            data-control='select2'
                            {...register('business_unit_id')}
                            data-hide-search='true'
                            onChange={(event) => {
                              props.handleBusinessUnit(event.target.value)
                            }}
                            // value={props.BusinessUnitId}
                          >
                            <option value=''>Select Branches</option>
                            {props.BranchList &&
                              props.BranchList.length > 0 &&
                              props.BranchList.map((option: any) => {
                                return <option value={option.id}>{option.name}</option>
                              })}
                          </select> */}
                        {/* <div onClick={handleBranchBranchFocus}>
                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              // onChange={(e) => {
                              //   props.handleBranchUnit(e)
                              //   // typeaheadRef4.current.toggleMenu()
                              // }}
                              onChange={handleBranchTypeBranchItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.BranchList}
                              // ref={typeaheadRef4}
                              placeholder='Select Branches'
                              selected={props.BranchUnitId}
                              labelKey={(BranchList: any) => BranchList.name}
                              //   inputProps={{required: true}}
                              open={isMenuBranchBranchTypeOpen}
                            ></Typeahead>
                          </div> */}
                        {/* </div> */}

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>
                <div>
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}
                      // onClick={handleClick}
                    >
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                      {/* <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted svg-icon-2hx" /> */}
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <button
                    className='btn btn-sm btn-light-primary '
                    onClick={() => BranchSyncData()}
                  >
                    Sync Branches
                  </button>{' '}
                </div>
              </>
            )}
            {window.location.pathname == '/timetracker/masters/managedepartments' && (
              <>
                <div className='d-none d-sm-block'>
                  {' '}
                  <input
                    type='text'
                    id='form1'
                    className='form-control form-control-sm'
                    // value={props.search}
                    placeholder='Search'
                    // onChange={(e) => props?.setSearch(e.target.value)}
                    onChange={(e) => props?.userSearch(e.target.value)}
                  />
                </div>
                <div className='d-none d-sm-block'>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Status:</label>

                          <div>
                            <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterStatus(e.target.value)}
                              value={props.filterstatus}
                            >
                              {/* <option value=''></option> */}

                              <option value='ACTIVE'>Active</option>
                              <option value='InActive'>Inactive</option>
                              <option value=''>All</option>
                            </select>
                          </div>
                        </div>

                        {/* <div className='mb-5'>
                          <label className='form-label fw-semibold'>Department Name:</label>

                          <div>
                            <input
                              type='text'
                              className='form-control'
                              value={props.filterdepartment}
                              placeholder='Enter Department name'
                              {...register('name', {
                                onChange: (e) => {
                                  props.setFilterDepartment(e.target.value)
                                },
                              })}
                            />
                          </div>
                        </div> */}
                        {/* <div className='mb-5'>
                          <label className='form-label'>Departments </label> */}
                        {/* <select
                            className={`form-select ${errors.business_unit_id ? 'is-invalid' : ''}`}
                            data-control='select2'
                            {...register('business_unit_id')}
                            data-hide-search='true'
                            onChange={(event) => {
                              props.handleBusinessUnit(event.target.value)
                            }}
                            // value={props.BusinessUnitId}
                          >
                            <option value=''>Select Departments </option>
                            {props.DepartmentList &&
                              props.DepartmentList.length > 0 &&
                              props.DepartmentList.map((option: any) => {
                                return <option value={option.id}>{option.name}</option>
                              })}
                          </select> */}
                        {/* <div onClick={handleDeptDeptFocus}>
                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              // onChange={(e) => {
                              //   props.handleBusinessUnit(e)
                              //   // typeaheadRef4.current.toggleMenu()
                              // }}
                              onChange={handleDeptTypeDepartmentItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.DepartmentList}
                              // ref={typeaheadRef4}
                              placeholder='Select Departments'
                              selected={props.BusinessUnitId}
                              labelKey={(DepartmentList: any) => DepartmentList.name}
                              //   inputProps={{required: true}}
                              open={isMenuDepartmentDepartmentTypeOpen}
                            ></Typeahead>
                          </div> */}
                        {/* </div> */}

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>
                <div>
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}

                      // onClick={handleClick}
                    >
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                      {/* <KTSVG path="/media/icons/duotune/files/fil021.svg" className="svg-icon-muted svg-icon-2hx" /> */}
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <button
                    className='btn btn-sm btn-light-primary '
                    onClick={() => DepartmentSyncData()}
                  >
                    Sync Departments
                  </button>{' '}
                </div>
              </>
            )}
            {window.location.pathname == '/timetracker/manageclients' && (
              <>
                <div className='d-none d-sm-block'>
                  <input
                    type='text'
                    id='form1'
                    className='form-control form-control-sm'
                    // value={props.search}
                    placeholder='Search'
                    // onChange={(e) => props?.setSearch(e.target.value)}
                    onChange={(e) => props?.clientSearch(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Status:</label>

                          <div>
                            <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterStatus(e.target.value)}
                              value={props.filterstatus}
                            >
                              <option value='ACTIVE'>Active</option>
                              <option value='InActive'>Inactive</option>
                              <option value=''>All</option>
                            </select>
                          </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeClientTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>{' '}
                <div>
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}

                      // onClick={handleClick}
                    >
                      {/* Export To Excel */}
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                    </button>
                  </Tooltip>
                </div>
              </>
            )}{' '}
            {window.location.pathname == '/timetracker/projectview' && (
              <>
                <div className='d-none d-sm-block'>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Project Status:</label>
                          <div>
                            <select
                              className={`form-select ${errors.status ? 'is-invalid' : ''}`}
                              data-control='select2'
                              {...register('status')}
                              data-hide-search='true'
                              onChange={(event) => {
                                props.setFilterStatus(event.target.value)
                              }}
                              value={props.filterstatus}
                            >
                              {options1.map((option: any) => {
                                return <option value={option.value}>{option.label}</option>
                              })}
                            </select>
                          </div>
                        </div>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Placement Type:</label>
                          <div>
                            <select
                              className={`form-select ${errors.placement_type ? 'is-invalid' : ''}`}
                              data-control='select2'
                              {...register('placement_type')}
                              data-hide-search='true'
                              onChange={(event) => {
                                props.setFilterPlacement(event.target.value)
                              }}
                              value={props.filterplacement}
                            >
                              {options2.map((option: any) => {
                                return <option value={option.value}>{option.label}</option>
                              })}
                            </select>
                          </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeMyProjectTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>{' '}
              </>
            )}
            {window.location.pathname == '/timetracker/MyConsultant' && (
              <>
                <div className='d-none d-sm-block'>
                  <input
                    type='text'
                    id='form1'
                    className='form-control form-control-sm'
                    // value={props.search}
                    placeholder='Search'
                    // onChange={(e) => props?.setSearch(e.target.value)}
                    onChange={(e) => props?.projectSearch(e.target.value)}
                  />
                </div>
                <div className='d-none d-sm-block'>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Status:</label>
                          {/* <div>
                            <select
                              className={`form-select ${errors.status ? 'is-invalid' : ''}`}
                              data-control='select2'
                              {...register('status')}
                              data-hide-search='true'
                              onChange={(event) => {
                                props.setFilterStatus(event.target.value)
                              }}
                              value={props.filterstatus}
                            >
                              {options1.map((option: any) => {
                                return <option value={option.value}>{option.label}</option>
                              })}
                            </select>
                          </div> */}

                          <div>
                            <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterStatus(e.target.value)}
                              value={props.filterstatus}
                            >
                              <option value='Active'>Active</option>
                              <option value='InActive'>Inactive</option>
                              <option value=''>All</option>
                            </select>
                          </div>
                        </div>
                        <div className='mb-5'>
                          <label className='form-label'>Consultant Type:</label>
                          <div>
                            <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterDepartment(e.target.value)}
                              value={props.filterdepartment}
                            >
                              <option value='W2'>W2</option>
                              <option value='C2C'>C2C</option>
                              <option value='Offshore'>Offshore</option>
                              <option value='Internal'>Internal</option>
                              <option value='1099'>1099</option>
                              <option value=''>All</option>
                            </select>
                          </div>
                        </div>
                        <div className='mb-5'>
                          <label className='form-label'>Business Unit</label>
                          <div onClick={handleMyConsultantFocus}>
                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              // onChange={(e) => {
                              //   props.handleBusinessUnit(e)
                              //   // typeaheadRef4.current.toggleMenu()
                              // }}
                              onChange={handleBUTypeMyConsultantItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.BusinessUnitsList}
                              // ref={typeaheadRef4}
                              placeholder='Select Business Unit'
                              selected={props.BusinessUnitId}
                              labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                              //   inputProps={{required: true}}
                              open={isMenuMyConsultantBUTypeOpen}
                            ></Typeahead>
                          </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeProjectTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>{' '}
                <div>
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}
                    >
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                    </button>
                  </Tooltip>
                </div>
              </>
            )}
            {window.location.pathname == '/timetracker/leaves' && (
              <>
                <div className='d-none d-sm-block'>
                  {' '}
                  <button
                    type='button'
                    className='btn btn-light-primary'
                    onClick={ApplyLeavePopUpOpen}
                  >
                    Apply Leave
                  </button>
                </div>
              </>
            )}
            {/* {localStorage.getItem('role_Name') === 'admin' ||
            localStorage.getItem('role_Name') === 'accounts' ||
            localStorage.getItem('role_Name') === 'hr' ? ( */}
            {numArray?.includes('admin') ||
            numArray?.includes('accounts') ||
            numArray?.includes('hr') ? (
              <>
                {window.location.pathname == '/timetracker/reports' && (
                  <>
                    <div>
                      {' '}
                      <button
                        type='button'
                        className='btn btn-sm btn-primary cursor-pointer '
                        id='kt_toolbar_primary_button'
                        onClick={props.monthlySettingOpen}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr075.svg'
                          className='svg-icon-5 '
                        />
                        Monthly Hours Setting
                      </button>
                    </div>
                  </>
                )}
              </>
            ) : (
              ''
            )}
            {window.location.pathname == '/timetracker/masters/manageconsultants' && (
              <>
                <div className='d-none d-sm-block'>
                  <input
                    type='text'
                    id='form1'
                    className='form-control form-control-sm'
                    // value={props.search}
                    placeholder='Search'
                    // onChange={(e) => props?.setSearch(e.target.value)}
                    onChange={(e) => props.ConsultantSearch(e.target.value)}
                  />
                </div>
                <div className='d-none d-sm-block'>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Status:</label>

                          <div>
                            <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterStatus(e.target.value)}
                              value={props.filterstatus}
                            >
                              <option value='Active'>Active</option>
                              <option value='InActive'>Inactive</option>
                              <option value=''>All</option>
                            </select>
                          </div>
                        </div>
                        <div className='mb-5'>
                          <label className='form-label'>Consultant Type:</label>
                          <div onClick={handleConsultantConsultantTypeFocus}>
                            {/* <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterDepartment(e.target.value)}
                              value={props.filterdepartment}
                            >
                              <option value='W2'>W2</option>
                              <option value='C2C'>C2C</option>
                              <option value='Offshore'>Offshore</option>
                              <option value='Internal'>Internal</option>
                              <option value='1099'>1099</option>
                              <option value=''>All</option>
                            </select> */}
                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              // onChange={(e) => {
                              //   props.handleConsultantType(e)
                              //   // Keep the menu open when making multiple selections.
                              //   // typeaheadRef6.current.toggleMenu()
                              // }}
                              onChange={handleConsultantTypeConsultantItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.ConsultantTypeOptions}
                              placeholder='Select Consultant Type'
                              // ref={typeaheadRef6}
                              selected={props.ConsultantTypeStatus}
                              // labelKey={(AllProjectStatusOptions: any) => AllProjectStatusOptions.name}
                              open={isMenuConsultantConsultantTypeOpen}
                            />
                          </div>
                        </div>
                        <div className='mb-5'>
                          <label className='form-label'>Business Unit</label>
                          {/* <select
                            className={`form-select ${errors.business_unit_id ? 'is-invalid' : ''}`}
                            data-control='select2'
                            {...register('business_unit_id')}
                            data-hide-search='true'
                            onChange={(event) => {
                              props.handleBusinessUnit(event.target.value)
                            }}
                            value={props.BusinessUnitId}
                          >
                            <option value=''>Select Business Unit</option>
                            {props.BusinessUnitsList &&
                              props.BusinessUnitsList.length > 0 &&
                              props.BusinessUnitsList.map((option: any) => {
                                return (
                                  <option value={option.business_unit_id}>{option.name}</option>
                                )
                              })}
                          </select> */}
                          <div onClick={handleConsultantBusinessFocus}>
                            <Typeahead
                              multiple
                              id='keep-menu-open'
                              // onChange={(e) => {
                              //   props.handleBusinessUnit(e)
                              //   // typeaheadRef4.current.toggleMenu()
                              // }}
                              onChange={handleBusinessTypeConsultantItemSelection}
                              onBlur={(e) => {
                                handleCloseAllPopups()
                              }}
                              options={props.BusinessUnitsList}
                              // ref={typeaheadRef4}
                              placeholder='Select Business Unit'
                              selected={props.BusinessUnitId}
                              labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                              //   inputProps={{required: true}}
                              open={isMenuConsultantBusinessTypeOpen}
                            ></Typeahead>
                          </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeConsultantTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>
                {/* <div className='d-none d-sm-block'>
                  {' '}
                  <UsersListFilter
                    setFilteredData={props.setFilteredData}
                    setPaginationData={props.setPaginationData}
                    setStatusToToolbar={props.setStatusToToolbar}
                    setConsultant={props.setConsultant}
                    setFilterOpen={props.setFilterOpen}
                  />
                </div> */}
                <div>
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}

                      // onClick={handleClick}
                    >
                      {/* Export To Excel */}
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                    </button>
                  </Tooltip>
                </div>
                {/* {localStorage.getItem('role_Name') != 'accounts' ? ( */}
                <>
                  <div className='d-none d-sm-block'>
                    <Tooltip text='View Sync'>
                      <div>
                        <Popup
                          trigger={
                            <button className='btn btn-icon btn-light  btn-active-color-primary btn-sm'>
                              <i className='fa fa-eye fs-4' aria-hidden='true'></i>
                            </button>
                          }
                          position='bottom center'
                        >
                          <div>
                            <div className='p-3'>
                              <div className='text-gray-900 fs-7 fw-semibold'>
                                Last Sync Time: {props.consultantUpdatedTime}
                              </div>
                              <div className='border-top my-3'></div>
                              <div className='text-gray-900 fs-7 fw-semibold'>
                                Last Sync Date: {props.consultantCreatedTime}
                              </div>
                            </div>
                          </div>
                        </Popup>
                      </div>
                    </Tooltip>
                  </div>
                  <div className=''>
                    <button
                      className='btn btn-sm btn-light-primary '
                      onClick={() => consultantSyncData()}
                    >
                      Sync Consultants
                    </button>{' '}
                  </div>
                  {numArray?.includes('hr') ? (
                    ''
                  ) : (
                    <div>
                      {' '}
                      <Link to='/masters/manageconsultants/addconsultant'>
                        <button
                          type='button'
                          className='btn btn-sm btn-primary cursor-pointer '
                          id='kt_toolbar_primary_button'
                          onClick={openAddUserModal}
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr075.svg'
                            className='svg-icon-5'
                          />
                          Add Consultant
                        </button>
                      </Link>
                    </div>
                  )}
                </>
                {/* ) : (
                  ''
                )} */}
              </>
            )}{' '}
            {window.location.pathname === '/timetracker/masters/managedocuments' && (
              <>
                <div className='d-none d-sm-block'>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Status</label>
                          <div>
                            <select
                              className='form-select  '
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterStatus(e.target.value)}
                              value={props.filterstatus}
                            >
                              {/* <option value=''></option> */}

                              <option value='Active'>Active</option>
                              <option value='Inactive'>Inactive</option>
                              <option value=''>All</option>
                            </select>
                          </div>
                          <div className='d-flex justify-content-end gap-5 mt-3'>
                            <button
                              type='button'
                              onClick={closeResetTooltip}
                              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                            >
                              Reset
                            </button>

                            <button
                              type='submit'
                              className='btn btn-sm btn-primary btn-sm'
                              onClick={closeDocumentTypesTooltip}
                              data-kt-menu-dismiss='true'
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>{' '}
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary cursor-pointer mx-4'
                    id='kt_toolbar_primary_button'
                    onClick={props.handleManageDocumentPop}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-5' />
                    Add Document Type
                  </button>
                </div>
              </>
            )}
            {window.location.pathname === '/timetracker/mobilesettings' && (
              <div>
                <Link to='/addmobilesettings'>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary cursor-pointer mx-4'
                    id='kt_toolbar_primary_button'
                    onClick={openAddUserModal}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-5' />
                    Add MobileSetting
                  </button>
                </Link>
              </div>
            )}
            {window.location.pathname == '/timetracker/employeereports' && (
              <>
                <div>
                  <a
                    href='#'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={Filter}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </a>

                  {props.fromuser && (
                    <div
                      className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                      data-kt-menu='true'
                    >
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>

                      <div className='separator border-gray-200'></div>

                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Start Date:</label>
                          <input
                            className={clsx(
                              errors.joining_date ? 'is-invalid' : '',
                              'form-control'
                            )}
                            type='date'
                            value={props.filterdepartment}
                            onChange={onChangeDate}
                            // onChange={(e: any) => props.setFilterDepartment(e.target.value)}
                          />
                          <div></div>
                          <div></div>
                        </div>

                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>End Date:</label>
                          <input
                            className={clsx(
                              errors.joining_date ? 'is-invalid' : '',
                              'form-control'
                            )}
                            type='date'
                            value={props.filterstatus}
                            onChange={onChangeDate1}
                          />

                          {/* <Space direction="vertical" size={12}> */}
                          {/* <RangePicker

                            //  onChange={(x: any) => setDateRange(x)}
                            onChange={(values: any) => {
                              // const value1 = moment(values[0]).format('DD-MM-YYYY')
                              let firstDate = moment(values[0].$d).format('YYYY-MM-DD')
                              let secondDate = moment(values[1].$d).format('YYYY-MM-DD')
                              setDates(firstDate)
                              setDates1(secondDate)
                            }}
                          // value={dateRange} 
                          /> */}
                          {/* </Space> */}
                        </div>

                        {/* <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Date Of Joining *</label>
                          <input
                            className={clsx(errors.joining_date ? 'is-invalid' : '', 'form-control')}
                            type='date'
                            onChange={onChangeDate}
                          />
                        </div> */}

                        {/* <div style={{ margin: 12 }}>
                          <RangePicker
                            onChange={(values: any) => {
                              setDates(values.map((item: any) => {
                                return moment(item).format('DD-MM-YYYY')
                              }))
                            }} />
                        </div> */}

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            // onClick={() => props.resetForm()}
                            onClick={props.resetForm}
                            // onClick={handleReset}
                            // onClick={closeResetTooltip}
                            // onClick={props.userFilter}
                            // className='btn btn-secondary btn-sm'
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='reset'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={props.userFilter}
                            // onClick={greetUser}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>{' '}
                <div>
                  {' '}
                  <input
                    type='text'
                    id='form1'
                    className='form-control form-control-sm'
                    // value={props.search}
                    placeholder='Search'
                    // onChange={(e) => props?.setSearch(e.target.value)}
                    onChange={(e) => props?.userSearch(e.target.value)}
                  />
                </div>
                <div>
                  {' '}
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}

                      // onClick={handleClick}
                    >
                      {/* Export To Excel */}
                      {/* Export To Excel */}
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                    </button>
                  </Tooltip>
                </div>
                <div>
                  {/* <button
                    type='button'
                    className='btn btn-sm btn-primary'
                    id='kt_toolbar_primary_button'
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button> */}
                  <button
                    type='button'
                    className='btn btn-light-primary btn-sm cursor-pointer '
                    id='kt_toolbar_primary_button'
                    onClick={() => navigate(-1)}
                  >
                    <i className='bi bi-arrow-left-short fs-2'></i>
                    Back
                  </button>
                </div>
              </>
            )}{' '}
            {window.location.pathname == '/timetracker/projectreports' && (
              <>
                <div>
                  <a
                    href='#'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={Filter}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </a>

                  {props.fromuser && (
                    <div
                      className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                      data-kt-menu='true'
                    >
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>

                      <div className='separator border-gray-200'></div>

                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Start Date:</label>
                          <input
                            className={clsx(
                              errors.joining_date ? 'is-invalid' : '',
                              'form-control'
                            )}
                            type='date'
                            value={props.filterdepartment}
                            onChange={onChangeDate}
                            // onChange={(e: any) => props.setFilterDepartment(e.target.value)}
                          />
                          <div></div>
                          <div></div>
                        </div>

                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>End Date:</label>
                          <input
                            className={clsx(
                              errors.joining_date ? 'is-invalid' : '',
                              'form-control'
                            )}
                            type='date'
                            onChange={onChangeDate1}
                            value={props.filterstatus}
                          />

                          {/* <Space direction="vertical" size={12}> */}
                          {/* <RangePicker

                            //  onChange={(x: any) => setDateRange(x)}
                            onChange={(values: any) => {
                              // const value1 = moment(values[0]).format('DD-MM-YYYY')
                              let firstDate = moment(values[0].$d).format('YYYY-MM-DD')
                              let secondDate = moment(values[1].$d).format('YYYY-MM-DD')
                              setDates(firstDate)
                              setDates1(secondDate)
                            }}
                          // value={dateRange} 
                          /> */}
                          {/* </Space> */}
                        </div>

                        {/* <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Date Of Joining *</label>
                          <input
                            className={clsx(errors.joining_date ? 'is-invalid' : '', 'form-control')}
                            type='date'
                            onChange={onChangeDate}
                          />
                        </div> */}

                        {/* <div style={{ margin: 12 }}>
                          <RangePicker
                            onChange={(values: any) => {
                              setDates(values.map((item: any) => {
                                return moment(item).format('DD-MM-YYYY')
                              }))
                            }} />
                        </div> */}

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            // onClick={() => props.resetForm()}
                            onClick={props.resetForm}
                            // onClick={handleReset}
                            // onClick={closeResetTooltip}
                            // onClick={props.userFilter}
                            // className='btn btn-secondary btn-sm'
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='reset'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={props.userFilter}
                            // onClick={greetUser}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>{' '}
                <div>
                  {' '}
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}

                      // onClick={handleClick}
                    >
                      {/* Export To Excel */}
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-light-primary btn-sm cursor-pointer '
                    id='kt_toolbar_primary_button'
                    onClick={() => navigate(-1)}
                  >
                    <i className='bi bi-arrow-left-short fs-2'></i>
                    Back
                  </button>
                </div>
              </>
            )}{' '}
            {window.location.pathname == '/timetracker/timeSheetreports' && (
              <>
                <div>
                  <a
                    href='#'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={Filter}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </a>

                  {props.fromuser && (
                    <div
                      className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                      data-kt-menu='true'
                    >
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>

                      <div className='separator border-gray-200'></div>

                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Start Date:</label>
                          <input
                            className={clsx(
                              errors.joining_date ? 'is-invalid' : '',
                              'form-control'
                            )}
                            type='date'
                            value={props.filterdepartment}
                            onChange={onChangeDate}
                            // onChange={(e: any) => props.setFilterDepartment(e.target.value)}
                          />
                          <div></div>
                          <div></div>
                        </div>

                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>End Date:</label>
                          <input
                            className={clsx(
                              errors.joining_date ? 'is-invalid' : '',
                              'form-control'
                            )}
                            type='date'
                            onChange={onChangeDate1}
                            value={props.filterstatus}
                          />

                          {/* <Space direction="vertical" size={12}> */}
                          {/* <RangePicker

                            //  onChange={(x: any) => setDateRange(x)}
                            onChange={(values: any) => {
                              // const value1 = moment(values[0]).format('DD-MM-YYYY')
                              let firstDate = moment(values[0].$d).format('YYYY-MM-DD')
                              let secondDate = moment(values[1].$d).format('YYYY-MM-DD')
                              setDates(firstDate)
                              setDates1(secondDate)
                            }}
                          // value={dateRange} 
                          /> */}
                          {/* </Space> */}
                        </div>

                        {/* <div className='col-md-3 col-xs-12'>
                          <label className='form-label '>Date Of Joining *</label>
                          <input
                            className={clsx(errors.joining_date ? 'is-invalid' : '', 'form-control')}
                            type='date'
                            onChange={onChangeDate}
                          />
                        </div> */}

                        {/* <div style={{ margin: 12 }}>
                          <RangePicker
                            onChange={(values: any) => {
                              setDates(values.map((item: any) => {
                                return moment(item).format('DD-MM-YYYY')
                              }))
                            }} />
                        </div> */}

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            // onClick={() => props.resetForm()}
                            onClick={props.resetForm}
                            // onClick={handleReset}
                            // onClick={closeResetTooltip}
                            // onClick={props.userFilter}
                            // className='btn btn-secondary btn-sm'
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                            data-kt-menu-dismiss='true'
                            data-kt-user-table-filter='reset'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={props.userFilter}
                            // onClick={greetUser}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>{' '}
                <div>
                  {' '}
                  <Tooltip text='Export To Excel'>
                    <button
                      className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      onClick={props.handleClick}

                      // onClick={handleClick}
                    >
                      {/* Export To Excel */}
                      <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                    </button>
                  </Tooltip>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-light-primary btn-sm cursor-pointer '
                    id='kt_toolbar_primary_button'
                    onClick={() => navigate(-1)}
                  >
                    <i className='bi bi-arrow-left-short fs-2'></i>
                    Back
                  </button>
                </div>
              </>
            )}{' '}
            {window.location.pathname == '/timetracker/timesheetlist' && (
              <>
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                    // keepTooltipInside='.tooltipBoundary'
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Client Name:</label>

                          <div>
                            <input
                              type='text'
                              className='form-control'
                              value={props.filterdepartment}
                              placeholder='Enter Client Name'
                              {...register('name', {
                                onChange: (e) => {
                                  props.setFilterDepartment(e.target.value)
                                },
                              })}
                            />
                          </div>
                        </div>

                        <div className='mb-10'>
                          <label className='form-label fw-bold'>Status:</label>

                          <div>
                            <select
                              className='form-select  fw-bolder'
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                              data-kt-user-table-filter='role'
                              data-hide-search='true'
                              onChange={(e) => props.setFilterStatus(e.target.value)}
                              value={props.filterstatus}
                            >
                              {/* <option value=''></option> */}

                              <option value='SUBMITTED'>Pending</option>
                              <option value='APPROVED'>Approved</option>
                              <option value='REJECTED'>Rejected</option>
                              <option value='SAVED'>Draft</option>
                              <option value=''>All</option>
                            </select>
                          </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeTimesheetTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>
                <div>
                  <Link to='/timesheetlist/addtimesheet'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary cursor-pointer mx-4'
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-5' />
                      Add TimeSheet
                    </button>
                  </Link>
                </div>
              </>
            )}
            {window.location.pathname == '/timetracker/timeSheetsubmissionreports' && (
              <>
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-flex btn-light btn-active-primary  '
                    onClick={openTooltip}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen031.svg'
                      className='svg-icon-5 svg-icon-gray-500 me-1'
                    />
                    Filter
                  </button>

                  <Popup
                    ref={ref}
                    trigger={<div></div>}
                    position={['top center', 'bottom right', 'bottom left']}
                    closeOnDocumentClick
                    arrow={false}
                  >
                    <div className='w-250px w-md-300px'>
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-gray-700 fw-semibold'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        {/* <div className='mb-5'> */}
                        <div className='col-md-3 col-xs-12 d-none'>
                          <label className='form-label'>Project Status</label>
                          <Typeahead
                            id='basic-typeahead-single'
                            // {...register('work_country')}
                            onChange={(e) => selectedCountries(e)}
                            onInputChange={(e) => handleCountries(e)}
                            placeholder='Enter Project Name'
                            options={country}
                            selected={countries}
                            labelKey={(country: any) => country.project_name || ''}
                            // inputProps={{ required: true }}
                          >
                            {({onClear, selected}) => (
                              <div className='rbt-aux'>
                                {!!selected.length && <ClearButton onClick={onClear} />}
                              </div>
                            )}
                          </Typeahead>
                        </div>

                        <div className='mb-5'>
                          <label className='form-label'>Project Name</label>
                          <Typeahead
                            id='basic-typeahead-single'
                            // {...register('work_country')}
                            onChange={(e) => selectedCountries(e)}
                            onInputChange={(e) => handleCountries(e)}
                            placeholder='Enter Project Name'
                            options={country}
                            selected={countries}
                            labelKey={(country: any) => country.project_name || ''}
                            // inputProps={{ required: true }}
                          >
                            {({onClear, selected}) => (
                              <div className='rbt-aux'>
                                {!!selected.length && <ClearButton onClick={onClear} />}
                              </div>
                            )}
                          </Typeahead>
                        </div>

                        <div className='mb-5'>
                          <label className='form-label fw-semibold'>Employee name:</label>

                          <div>
                            <Typeahead
                              id='basic-typeahead-single'
                              // disabled={isView}
                              onChange={(e) => selectedCountries1(e)}
                              onInputChange={(e) => handleCountries1(e)}
                              options={country1}
                              placeholder='Enter Employee Name'
                              // disabled={AllFieldsToDisabled}
                              selected={countries1}
                              labelKey={(country1: any) => country1.full_name}
                            >
                              {({onClear, selected}) => (
                                <div className='rbt-aux'>
                                  {!!selected.length && <ClearButton onClick={onClear} />}
                                </div>
                              )}
                            </Typeahead>
                          </div>
                        </div>

                        <div className='d-flex justify-content-end gap-3'>
                          <button
                            type='button'
                            onClick={closeTimesheetResetTooltip}
                            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          >
                            Reset
                          </button>
                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-sm'
                            onClick={closeTimeSheetTooltip}
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </Popup>
                </div>{' '}
                <Tooltip text='Export To Excel'>
                  <button
                    className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                    onClick={props.handleClick}

                    // onClick={handleClick}
                  >
                    {/* Export To Excel */}
                    <i className='bi bi-cloud-arrow-down-fill fs-2'></i>
                  </button>
                </Tooltip>
                <div>
                  <button
                    type='button'
                    className='btn btn-light-primary btn-sm cursor-pointer '
                    id='kt_toolbar_primary_button'
                    onClick={() => navigate(-1)}
                  >
                    <i className='bi bi-arrow-left-short fs-2'></i>
                    Back
                  </button>
                </div>
              </>
            )}
            <>
              {window.location.pathname === '/timetracker/masters/manageprojects/addproject' ||
              window.location.pathname === '/timetracker/masters/manageprojects/updateproject' ? (
                <div>
                  <Link to='/masters/manageprojects'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <i className='bi bi-arrow-left-short fs-2'></i>
                      Back
                    </button>
                  </Link>
                </div>
              ) : window.location.pathname === '/timetracker/integrations/netSuiteSettings' ? (
                <div>
                  <Link to='/integrations'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <i className='bi bi-arrow-left-short fs-2'></i>
                      Back
                    </button>
                  </Link>
                </div>
              ) : window.location.pathname === '/timetracker/integrations/qbSettings' ? (
                <div>
                  <Link to='/integrations'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <i className='bi bi-arrow-left-short fs-2'></i>
                      Back
                    </button>
                  </Link>
                </div>
              ) : window.location.pathname === '/timetracker/MyConsultant/correspondentDetails' ? (
                <div>
                  <Link to='/MyConsultant'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <i className='bi bi-arrow-left-short fs-2'></i>
                      Back
                    </button>
                  </Link>
                </div>
              ) : window.location.pathname === '/timetracker/updateclients' ? (
                <div>
                  <Link to='/manageclients'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <i className='bi bi-arrow-left-short fs-2'></i>
                      Back
                    </button>
                  </Link>
                </div>
              ) : !numArray?.includes('relationshipmanager') &&
                (window.location.pathname ===
                  '/timetracker/masters/manageconsultants/addconsultant' ||
                  window.location.pathname ===
                    '/timetracker/masters/manageconsultants/updateconsultant' ||
                  window.location.pathname ===
                    '/timetracker/masters/manageconsultants/viewconsultant') &&
                props.isLogin != 'Approver' ? (
                <div>
                  <Link to='/masters/manageconsultants'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <i className='bi bi-arrow-left-short fs-2'></i>
                      Back
                    </button>
                  </Link>
                </div>
              ) : numArray?.includes('relationshipmanager') &&
                window.location.pathname ===
                  '/timetracker/masters/manageconsultants/viewconsultant' ? (
                <div>
                  <Link to='/MyConsultant'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <i className='bi bi-arrow-left-short fs-2'></i>
                      Back
                    </button>
                  </Link>
                </div>
              ) : window.location.pathname ===
                  '/timetracker/masters/manageconsultants/updateconsultant' &&
                props.isLogin == 'Approver' ? (
                <div>
                  <Link to='/approver'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <i className='bi bi-arrow-left-short fs-2'></i>
                      Back
                    </button>
                  </Link>
                </div>
              ) : window.location.pathname ===
                  '/timetracker/masters/manageannouncements/addannouncement' ||
                window.location.pathname ===
                  '/timetracker/masters/manageannouncements/updateannouncement' ? (
                <div>
                  <Link to='/masters/manageannouncements'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <i className='bi bi-arrow-left-short fs-2'></i>
                      Back
                    </button>
                  </Link>
                </div>
              ) : window.location.pathname === '/timetracker/addmobilesettings' ||
                window.location.pathname === '/timetracker/updatemobilesettings' ? (
                <div>
                  <Link to='/mobilesettings'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <i className='bi bi-arrow-left-short fs-2'></i>
                      Back
                    </button>
                  </Link>
                </div>
              ) : // ) : localStorage.getItem('role_Name') === 'timesheetadmin' &&
              numArray?.includes('timesheetadmin') && props.users != 'users' ? (
                window.location.pathname === '/timetracker/timesheetlist/addtimesheet' && (
                  <div>
                    <Link to='/masters/manageconsultants'>
                      <button
                        type='button'
                        className='btn btn-light-primary btn-sm cursor-pointer '
                        id='kt_toolbar_primary_button'
                        onClick={openAddUserModal}
                      >
                        <i className='bi bi-arrow-left-short fs-2'></i>
                        Back
                      </button>
                    </Link>
                  </div>
                )
              ) : // ) : localStorage.getItem('role_Name') === 'timesheetadmin' &&
              numArray?.includes('timesheetadmin') && props.users == 'users' ? (
                window.location.pathname === '/timetracker/timesheetlist/addtimesheet' && (
                  <div>
                    <Link to='/masters/manageusers'>
                      <button
                        type='button'
                        className='btn btn-light-primary btn-sm cursor-pointer '
                        id='kt_toolbar_primary_button'
                        onClick={openAddUserModal}
                      >
                        <i className='bi bi-arrow-left-short fs-2'></i>
                        Back
                      </button>
                    </Link>
                  </div>
                )
              ) : window.location.pathname === '/timetracker/timesheetlist/addtimesheet' ||
                window.location.pathname === '/timetracker/timesheetlist/updatetimesheet' ? (
                <div>
                  <Link to='/timesheetlist'>
                    <button
                      type='button'
                      className='btn btn-light-primary btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={openAddUserModal}
                    >
                      <i className='bi bi-arrow-left-short fs-2'></i>
                      Back
                    </button>
                  </Link>
                </div>
              ) : (
                ''
              )}

              {/* {localStorage.getItem('role_Name') === 'employee' ? ( */}
              {numArray?.includes('employee') ? (
                <>
                  {window.location.pathname === '/timetracker/profile' && (
                    <div>
                      <button
                        type='button'
                        className='btn btn-light-primary btn-sm cursor-pointer '
                        id='kt_toolbar_primary_button'
                        onClick={() => navigate(-1)}
                      >
                        <i className='bi bi-arrow-left-short fs-2'></i>
                        Back
                      </button>
                      {/* </Link> */}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {/* <button type='button' onClick={() => navigate(-1)} className='btn btn-danger'></button> */}
                  {window.location.pathname === '/timetracker/profile' && (
                    <div>
                      <button
                        type='button'
                        className='btn btn-light-primary btn-sm cursor-pointer '
                        id='kt_toolbar_primary_button'
                        onClick={() => navigate(-1)}
                      >
                        <i className='bi bi-arrow-left-short fs-2'></i>
                        Back
                      </button>
                      {/* </Link> */}
                    </div>
                  )}
                </>
              )}
            </>{' '}
            {(window.location.pathname === '/timetracker/dashboard' ||
              window.location.pathname === '/timetracker/apps/user-management/users') && (
              <div>
                {' '}
                <a
                  className='btn btn-sm btn-primary cursor-pointer'
                  id='kt_toolbar_primary_button'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_create_app'
                  onClick={() => setShowCreateAppModal(true)}
                >
                  Create
                </a>
              </div>
            )}
          </div>

          {/* end::Container */}
        </div>
      </div>
      <ToastContainer />
      <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
    </>
  )
}

export {Toolbar1}
