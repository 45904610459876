import React, {useState} from 'react'
import {default as Apiservices} from '../../../../src/common/index'
import {useNavigate} from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Cookies from 'universal-cookie'

export default function SkillsRequiredPopup(props: any) {
  const navigate = useNavigate()
  const [show, setShow] = useState(true)
  const cookies = new Cookies()

  const navigateProfile = async () => {
    setTimeout(function () {
      window.open('https://launchpad-uat.msrcosmos.com/#/home/profile/about', '_blank')
    }, 1000)
logout()
  }

  function removeAll() {
    let lRemove: any = [
      'token',
      'user_email',
      'org_list',
      'role_Name',
      'org_prefix',
      'org_id',
      'org_name',
      'tool_name',
      'ecdb_user_id',
      'userid',
      'Authorization',
      'NetSuiteToken',
      'enable_netsuite',
      'subsidary_name',
      'logged_in_id',
      'Announcement',
      'refresh_Token',
      'enable_quickbooks',
      'org_icon',
      'time_sheet_admin_screen_user_id',
      'MobileForceUpdateAccessbyEmail',
      'allowed_business_unit_id',
      'bu_list',
      'enable_unbilled',
      'invoice_access',
    ]
    for (let each of lRemove) {
      localStorage.removeItem(each)
    }
  }

  const logout = async () => {
      const data: any = await Apiservices.getUserLogout()
      const data1: any = await Apiservices.getUserLogout1()
      if (data.data.statusCode == 200 || data1.status == 200) {
        removeAll()
        props.widgetLogout()
        cookies.remove('JWT-TOkEN')
        cookies.remove('JWT_TOKEN')
        cookies.remove('org_id')
        cookies.remove('torg_id')
        cookies.remove('profileView')  
        cookies.remove('JWT-TOkEN', {path: '/', domain: '.msrcosmos.com'})
        cookies.remove('JWT_TOKEN', {path: '/', domain: '.msrcosmos.com'})
        cookies.remove('org_id', {path: '/', domain: '.msrcosmos.com'})
        cookies.remove('torg_id', {path: '/', domain: '.msrcosmos.com'})
        cookies.remove('profileView', {path: '/', domain: '.msrcosmos.com'})  
        return (
          toast.success('Logout Successful'),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          },
          setTimeout(function () {
            navigate('/login')
          }, 1000)
        )
      }}

  return (
    <>
      <Modal show={show} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Body>
          <p className='text-center text-gray-800 fs-6 text-transform-unset'>
          Your profile is incomplete. Please take a moment to complete your profile by clicking OK.
          </p>
          <div className='row justify-content-center gap-2 py-2'>
            <div className='col-auto'>
              <button className='btn btn-primary' onClick={() => navigateProfile()}>
                Ok
              </button>
            </div>
            <div className='col-auto'>
              {/* <button className='btn btn-danger' onClick={props.cancel}>
                Cancel
              </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  )
}
