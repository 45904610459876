import React, {useMemo, useState, useEffect, useContext, useRef} from 'react'
import ThingsContext from '../../../modules/apps/masters/commonicons/GlobalContext'
import ValidContext from '../../../modules/apps/masters/commonicons/ValidateContext'
import {default as Apiservices} from '../../../../common/index'
import {useIntl} from 'react-intl'
import ReactPaginate from 'react-paginate'
import {useForm} from 'react-hook-form'
import {Container, Row, Col} from 'react-bootstrap'
import 'reactjs-popup/dist/index.css'
import clsx from 'clsx'
import BackDrop from '../../../modules/apps/timesheetentry/ComponentBackdrop'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useTable, ColumnInstance} from 'react-table'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {ManageListClients} from '../../../modules/apps/user-management/users-list/table/columns/_columns'
import {
  useQueryResponseData,
  useQueryResponseLoading,
} from '../../../modules/apps/user-management/users-list/core/QueryResponseProvider'
import {ClearButton, Typeahead} from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
// import DatePicker from 'react-datepicker'
import DatePicker, {Calendar, getAllDatesInRange} from 'react-multi-date-picker'
import 'react-datepicker/dist/react-datepicker.css'
import {Tooltip} from '../../../modules/apps/timesheetentry/ComponentTooltip'
import Modal from 'react-bootstrap/Modal'
import {ToastContainer, toast} from 'react-toastify'

type FormData = {
  client: string
  dba: string
  ccdb_id: string
  qb_client_name: string
  qb_client_id: string
  id: number
  user_id: number
  user: number
  select_Year: any
  org_name: any
  employee_type: string
  project_type: string
  work_city: string
}

const UnBilledReport = (props: any) => {
  const methods = useForm()
  const values = [true]
  const [fullscreen, setFullscreen] = useState<any>(true)
  const [show, setShow] = useState(true)

  const {
    register,
    setValue,
    getValues,
    reset,
    handleSubmit,
    formState: {errors},
  } = useForm<FormData>()

  const handleClose = () => {
    setFullscreen(false)
    setShow(false)
    props.cancel(false)
    // props.view_open(false)
    // props.setViewOpen(false)
  }

  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const columns = useMemo(() => ManageListClients, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })

  const [order5, setOrder5] = useState('ASC')
  const [order6, setOrder6] = useState('ASC')
  const [order7, setOrder7] = useState('ASC')
  const [order8, setOrder8] = useState('ASC')
  const [order9, setOrder9] = useState('ASC')
  const intl = useIntl()
  const [fromclient, setFromClient] = useState<any | []>(true)
  const [perPage, setPerPage] = useState(25)
  const [page, setPage] = useState(1)
  const [showBackDrop, setShowBackDrop] = useState(false)
  const [pages, setPages] = useState(1)
  const [selected, setSelected] = useState([])
  const [selectConsultant, setSelectConsultant] = useState([])
  const [select_consultant, setSelectconsultant] = useState<any | []>([])
  const typeaheadRef: any = useRef<HTMLInputElement>(null)
  const typeaheadRef1: any = useRef<HTMLInputElement>(null)
  const typeaheadRef2: any = useRef<HTMLInputElement>(null)
  const typeaheadRef3: any = useRef<HTMLInputElement>(null)
  const typeaheadRef4: any = useRef<HTMLInputElement>(null)
  const typeaheadRef5: any = useRef<HTMLInputElement>(null)
  const typeaheadRef6: any = useRef<HTMLInputElement>(null)
  const typeaheadRef7: any = useRef<HTMLInputElement>(null)
  const typeaheadRef8: any = useRef<HTMLInputElement>(null)
  const [ComplianceBusinessUnit, setComplianceBusinessUnit] = useState<any | []>([])
  const [country1, setCountry1] = useState<any | []>([])
  const [dateField, setdateField] = useState<any>(false)
  const [candidateList, setCandidateList] = useState<any | []>([])
  // ref.current.close()
  // const [dateRange, setDateRange] = useState<any | []>([null, null])
  // const [startDate, endDate] = dateRange
  const [dateRange, setDateRange] = useState<any | []>([])
  const [MonthYearDynamicallyChange, setMonthYearDynamicallyChange] = useState<any>([])
  const [DateValidation, setDateValidation] = useState(false)
  const [search, setSearch] = useState('')
  // const typeaheadRef = useRef(null)
  const [sort, setSort] = useState<any | []>(false)
  const [date, setDate] = useState('')
  const [selectYear, setSelectYear] = useState<any>(new Date())
  const [ProjectReportData, setProjectReportData] = useState<any | []>([])
  const [ProjectReportCountData, setProjectReportCountData] = useState<any | []>([])
  const [candidate, setCandidate] = useState<any | []>([])
  const [selectMonth, setSelectMonth] = useState<any>([])
  const [countries1, setCountries1] = useState<any | []>([])
  const [BusinessUnitsList, setBusinessUnitsList] = useState<any | []>([])
  const [BusinessUnit, setBusinessUnit] = useState<any | []>([])
  const [flag, setFlag] = useState(false)
  const [AllOptions, setAllOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'W2',
      value: 'W2',
    },
    {
      label: 'C2C',
      value: 'C2C',
    },
    // {
    //   label: 'Internal',
    //   value: 'Internal',
    // },
    {
      label: '1099',
      value: '1099',
    },
    {
      label: 'Offshore',
      value: 'Offshore',
    },
  ])

  const [selectProjectType, setSelectProjectType] = useState<any>([])
  const [AllProjectTypeOptions, setAllProjectTypeOptions] = useState<any | []>([
    {
      label: 'Select All',
      value: 'All',
    },
    {
      label: 'Placement',
      value: '1',
    },
    {
      label: 'Bench',
      value: '2',
    },
    {
      label: 'Internal',
      value: '3',
    },
  ])
  const tokenHeader: any = useContext(ThingsContext)
  const renderThings = async () => {
    tokenHeader.getCookie()
  }

  const tokenHeader1: any = useContext(ValidContext)
  const renderThings1 = async () => {
    tokenHeader1.getValidate()
  }

  useEffect(() => {
    renderThings()
    // AllOrgList()
    // handleConsultant()
    // setSelectYear(selectYear)
    setSelectYear(new Date(moment().subtract(1, 'month').format('YYYY')))
    const MonthNumberDefault = [
      {
        label: moment(new Date()).subtract(1, 'month').format('MMMM'),
        value: moment(new Date()).subtract(1, 'month').format('M'),
      },
    ]
    setSelectMonth(MonthNumberDefault)
    // let currYr: any = new Date().getFullYear()
    // setSelectYear(currYr)
    // let pastYr = 2013
    // while (currYr >= pastYr) {
    //   years.push(currYr)
    //   currYr -= 1
  }, [])
  useEffect(() => {
    get_business_units_list()
    getAllProjects()
  }, [])
  const getAllProjects = async () => {
    const res: any = await Apiservices.getprojectbyname({})
  }
  const get_business_units_list = async () => {
    let y = JSON.parse(localStorage.getItem('bu_list') || '[]')

    let n = y.filter((element: any) => element.roleName?.toLowerCase() == 'accounts')
    let business: any = localStorage.getItem('role_Name')
    let numArray: any = business?.split(',')
    let uniqueArr: any = []

    const res: any = await Apiservices.get_business_units_list()
    if (res && res.status == 200) {
      if (res.data.data.length > 0) {
        let obj = {name: 'Select All', value: 'All'}
        if (numArray?.includes('admin')) {
          let x = res.data.data.filter((test: any) => uniqueArr.push(test))
          setBusinessUnitsList([obj, ...uniqueArr])
        } else {
          for (let each of res.data.data) {
            for (let item of n) {
              if (each.business_unit_id == item.buId) {
                uniqueArr.push(each)
              }
            }
          }
          setBusinessUnitsList([obj, ...uniqueArr])
        }
      }
    }
  }
  const handleConsultant = async (consult_Name?: any) => {
    const response: any = await Apiservices.getconsultantbyname()
    setCandidate(response?.data?.data)
  }

  const user = (candidate: any) => {
    if (candidate === null) return ''
    else {
      let str = ''

      candidate.split(' ').forEach((ele: any, i: any) => {
        str += (i == 0 ? '' : ' ') + ele.charAt(0).toUpperCase() + ele.slice(1)
      })

      return str
    }
  }

  const sorting5 = (col: any) => {
    if (order5 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => (a[col] > b[col] ? 1 : -1))

      setProjectReportData(sorted)
      setOrder5('DSC')
      setSort(false)
    }

    if (order5 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => (a[col] < b[col] ? 1 : -1))

      setProjectReportData(sorted)

      setOrder5('ASC')
      setSort(true)
    }
  }

  const sorting6 = (col: any) => {
    if (order6 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        const numA = parseInt(a[col].match(/\d+/), 10) || 0
        const numB = parseInt(b[col].match(/\d+/), 10) || 0

        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerA.localeCompare(lowerB)
      })

      setProjectReportData(sorted)
      setOrder6('DSC')
      setSort(false)
    }

    if (order6 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        const numA = parseInt(a[col].match(/\d+/), 10) || 0
        const numB = parseInt(b[col].match(/\d+/), 10) || 0

        const lowerA = a[col].replace(/\s+/g, '').toLowerCase()
        const lowerB = b[col].replace(/\s+/g, '').toLowerCase()
        return lowerB.localeCompare(lowerA)
      })
      setProjectReportData(sorted)
      setOrder6('ASC')
      setSort(true)
    }
  }

  const sorting7 = (col1: any, col2: any) => {
    if (order7 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() > val2?.toLowerCase() ? 1 : -1
      })

      setProjectReportData(sorted)
      setOrder7('DSC')
      setSort(false)
    }

    if (order7 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let val1 = a[col1] ? a[col1] : a[col2]
        let val2 = b[col1] ? b[col1] : b[col2]
        return val1?.toLowerCase() < val2?.toLowerCase() ? 1 : -1
      })

      setProjectReportData(sorted)
      setOrder7('ASC')
      setSort(true)
    }
  }

  const sorting8 = (col: any, col1: any, col2: any) => {
    //direct_customer,end_customer,client
    if (order8 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let a1 = a[col] ? a[col1] : a[col2]
        let b1 = b[col] ? b[col1] : b[col2]

        return a1?.toLowerCase() > b1?.toLowerCase() ? 1 : -1
      })
      setProjectReportData(sorted)
      setOrder8('DSC')
      setSort(false)
    }

    if (order8 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a: any, b: any) => {
        let a1 = a[col] ? a[col1] : a[col2]
        let b1 = b[col] ? b[col1] : b[col2]
        return a1?.toLowerCase() < b1?.toLowerCase() ? 1 : -1
      })
      setProjectReportData(sorted)
      setOrder8('ASC')
      setSort(true)
    }
  }

  const sorting9 = (col: any) => {
    if (order9 === 'ASC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => a[col] - b[col])

      setProjectReportData(sorted)
      setOrder9('DSC')
      setSort(false)
    }

    if (order9 === 'DSC') {
      const sorted = [...ProjectReportData]?.sort((a, b) => b[col] - a[col])
      setProjectReportData(sorted)
      setOrder9('ASC')
      setSort(true)
    }
  }
  // const handleProjectType = (e: any) => {
  //   setValue('project_type', e.target.value)
  // }
  const handleProjectType = (selected: any) => {
    setSelectProjectType(selected)
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllProjectTypeOptions.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setSelectProjectType(x)
          AllProjectTypeOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
        } else if (each.value == 'empty') {
          setSelectProjectType([])
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          AllProjectTypeOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectProjectType(selected)
          let abc = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
          let cde = AllProjectTypeOptions.find((item: any) => item.value == 'All')

          if (!abc) {
            AllProjectTypeOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }
          if (!cde) {
            AllProjectTypeOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllProjectTypeOptions(AllProjectTypeOptions)
          }

          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllProjectTypeOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllProjectTypeOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectProjectType(selected)
      AllProjectTypeOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
      if (!projectTypeReset) {
        AllProjectTypeOptions.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const handlePageClick = (event: any) => {
    let page = event.selected
    let selected = page + 1
    // setPage(selected)
    // getallprojects(mainTableData, selected, perPage)
    handleClick1(selected, perPage, true)
  }
  const handleRowChange = async (e: any) => {
    const {name, value} = e.target
    if (name == 'perPage') {
      setPerPage(value)
      setPage(1)
      handleClick1(1, value, false)
    }
  }

  const resetData = async (e: any) => {
    // setDateRange([null])
    setProjectReportData([])
    setDateValidation(false)
    // setDateRange(null)
    setDateRange([])
    setMonthYearDynamicallyChange([])
    setFlag(false)
    setSearch('')
    // setConsultantType('')
    setValue('employee_type', '')
    setValue('work_city', '')
    // typeaheadRef1.current.clear()
    setSelected([])
    let dataSet = {
      project_type: '',
    }
    reset(dataSet)
    // typeaheadRef.current.clear()
    setSelectConsultant([])
    setSelectProjectType([])

    // typeaheadRef2.current.clear()
    setSelectconsultant([])
    // typeaheadRef3.current.clear()
    setCountries1([])
    setBusinessUnit([])
    typeaheadRef4.current.clear()

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    // let projectTypeReset = AllProjectTypeOptions.find((item: any) => item.value == 'All')
    // if (!projectTypeReset) {
    //   AllProjectTypeOptions.unshift({
    //     label: 'Select All',
    //     value: 'All',
    //   })
    //   // setAllProjectTypeOptions(AllProjectTypeOptions)
    // }
    // AllProjectTypeOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    // let projectTypeReset1 = AllProjectTypeOptions.find((item: any) => item.value == 'empty')
    // if (projectTypeReset1) {
    //   AllProjectTypeOptions.splice(
    //     AllProjectTypeOptions.findIndex((item: any) => item.value == 'empty'),
    //     1
    //   )
    // }

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    // AllOptions.pop({
    //   label: 'Deselect All',
    //   value: 'empty',
    // })
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }
  }
  const handleChange1 = async (value: any) => {
    setSearch(value)
    setDateValidation(true)
    // setSearch_Open(true)
    // setSelectConsultant([])
    // setBusinessUnit([])
    // setSelectconsultant([])
    // setCountries1([])

    let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
    if (!businessReset) {
      BusinessUnitsList.unshift({
        name: 'Select All',
        value: 'All',
      })
    }

    let businessReset1 = BusinessUnitsList.find((item: any) => item.value == 'empty')
    if (businessReset1) {
      BusinessUnitsList.splice(
        BusinessUnitsList.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let consultantReset = AllOptions.find((item: any) => item.value == 'All')
    if (!consultantReset) {
      AllOptions.unshift({
        label: 'Select All',
        value: 'All',
      })
    }
    let consultantReset1 = AllOptions.find((item: any) => item.value == 'empty')
    if (consultantReset1) {
      AllOptions.splice(
        AllOptions.findIndex((item: any) => item.value == 'empty'),
        1
      )
    }

    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')

    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')

    const article1 = {
      // year: moment(startDate, ' DD/MM/YYYY').format('YYYY'),
      // month: moment(startDate, ' DD/MM/YYYY').format('M'),
      year: `${MonthYearDynamicallyChange.y1}`,
      month: `${MonthYearDynamicallyChange.m1}`,
      // start_date: startDate2,
      // end_date: endDate2,
      start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
      end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
      placement_type: stat2 ? stat1 : null,
      keyword: value,
      user_id: employee1 ? employee : null, // optional
      project_id: project1 ? project : null, // optional
      // is_placement_project: project_type2 ? project_type1 : null,
      business_unit_id: business2 ? business1 : '',
      // pagenumber: page,
      pagenumber: 1,
      pagesize: Number(perPage),
    }

    // if (startDate) {
    //   if (endDate) {
    if (dateRange?.length == 2) {
      setDateValidation(false)
      setShowBackDrop(true)
      const response: any = await Apiservices.get_project_wise_unbilled_report(article1)
      if (response && response.isError == false) {
        setPage(1)
        setFlag(true)
        setProjectReportCountData(response.count)
        setPages(Math.ceil(response.count / Number(perPage)))
        setProjectReportData(response.data)
      }
      setShowBackDrop(false)
      // }
    }
  }
  // const handleClick1 = async () => {
  const handleClick1 = async (page: any, perPage: any, change: boolean) => {
    // if (startDate == null || endDate == null) {
    setDateValidation(true)
    setProjectReportData([])
    if (dateRange?.length != 2) {
      setdateField(true)
    } else {
      setdateField(false)
    }

    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    // let project_type1 = selectProjectType.map((temp: any) => {
    //   return Number(temp.value)
    // })
    // let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')

    const article1 = {
      // year: moment(startDate, ' DD/MM/YYYY').format('YYYY'),
      // month: moment(startDate, ' DD/MM/YYYY').format('M'),
      year: `${MonthYearDynamicallyChange.y1}`,
      month: `${MonthYearDynamicallyChange.m1}`,
      // start_date: startDate2,
      // end_date: endDate2,
      start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
      end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
      placement_type: stat2 ? stat1 : null,
      keyword: search ? search : null,
      user_id: employee1 ? employee : null, // optional
      project_id: project1 ? project : null, // optional
      // is_placement_project: project_type2 ? project_type1 : null,
      business_unit_id: business2 ? business1 : '',
      // pagenumber: page,
      pagenumber: change ? page : 1,
      pagesize: Number(perPage),
    }
    // if (startDate) {
    //   if (endDate) {
    if (dateRange?.length == 2) {
      setDateValidation(false)
      setShowBackDrop(true)
      const response: any = await Apiservices.get_project_wise_unbilled_report(article1)
      if (response && response.isError == false) {
        setFlag(true)
        setPage(change ? page : 1)
        setProjectReportCountData(response.count)
        setPages(Math.ceil(response.count / Number(perPage)))
        setProjectReportData(response.data)
      } else {
        toast.warn(response.error)
      }
      setShowBackDrop(false)
    }
    // }
  }

  const exporthandleClick1 = async (e: any) => {
    setDateValidation(true)
    let stat1 = selectConsultant.map((val: any) => {
      return val.value
    })
    let stat2 = stat1.filter((temp: any) => `${temp}`).join(',')
    let business1 = BusinessUnit.map((val: any) => {
      return val.business_unit_id
    })
    let business2 = business1.filter((temp: any) => `${temp}`).join(',')
    let employee = select_consultant.map((value: any) => {
      return Number(value.id)
    })
    let employee1 = employee.filter((temp: any) => `${temp}`).join(',')
    let project = countries1.map((value: any) => {
      return Number(value.id)
    })
    let project1 = project.filter((temp: any) => `${temp}`).join(',')
    // let project_type1 = selectProjectType.map((temp: any) => {
    //   return Number(temp.value)
    // })
    // let project_type2 = project_type1.filter((temp: any) => `${temp}`).join(',')

    // let startDate2 = moment(startDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    // let endDate2 = moment(endDate, ' DD/MM/YYYY').format('YYYY-MM-DD')
    if (dateRange?.length == 2) {
      setDateValidation(false)
      setShowBackDrop(true)
      const article1 = {
        // year: moment(startDate, ' DD/MM/YYYY').format('YYYY'),
        // month: moment(startDate, ' DD/MM/YYYY').format('M'),
        year: `${MonthYearDynamicallyChange.y1}`,
        month: `${MonthYearDynamicallyChange.m1}`,
        // start_date: startDate2,
        // end_date: endDate2,
        start_date: `${MonthYearDynamicallyChange.y1}-${MonthYearDynamicallyChange.m1}-${MonthYearDynamicallyChange.d1}`,
        end_date: `${MonthYearDynamicallyChange.y2}-${MonthYearDynamicallyChange.m2}-${MonthYearDynamicallyChange.d2}`,
        placement_type: stat2 ? stat1 : null,
        user_id: employee1 ? employee : null, // optional
        project_id: project1 ? project : null, // optional
        // is_placement_project: project_type2 ? project_type1 : null,
        business_unit_id: business2 ? business1 : '',
      }
      const response: any = await Apiservices.export_project_wise_unbilled_report(article1)
      var link = document.createElement('a')
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'Downloaded File')
      link.href = response?.data?.blobUrl
      document.body.appendChild(link)
      link.click()
      link.remove()
      setShowBackDrop(false)
    }
  }
  const handleCountries1 = async (country1: any) => {
    // if (country1 && country1.length >= 2) {
    const response: any = await Apiservices.getprojectbyname({country_name: country1})
    if (response?.data?.data !== null) {
      setCountry1(response && response.data && response.data.data ? response?.data?.data : '')
    }
    // }
  }

  const selectedCountries1 = async (state: any) => {
    setCountries1(state)
  }
  const handleFilterConsultant = async (consult_Name: any) => {
    // let filteredConsultant = candidate?.filter((each: any) =>
    //   each?.full_name?.toLowerCase()?.includes(consult_Name?.toLowerCase())
    // )
    // setCandidateList(filteredConsultant)
    const response: any = await Apiservices.getconsultantbyname()
    setCandidateList(response?.data?.data)
  }

  const selectedConsultant = async (consult_name: any) => {
    setSelectconsultant(consult_name)
  }

  const handleBusinessUnit = (selected: any) => {
    // setBusinessUnit(selected)

    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = BusinessUnitsList.filter(
            (temp: any) => temp.value != 'All' && temp.value != 'empty'
          )
          setBusinessUnit(x)
          BusinessUnitsList.shift({
            name: 'Select All',
            value: 'All',
          })
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
        } else if (each.value == 'empty') {
          setBusinessUnit([])
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            // setBusinessUnitsList(BusinessUnitsList)
          }
          BusinessUnitsList.pop({
            name: 'Deselect All',
            value: 'empty',
          })
        } else {
          setBusinessUnit(selected)
          let abc = BusinessUnitsList.find((item: any) => item.value == 'empty')
          let cde = BusinessUnitsList.find((item: any) => item.value == 'All')

          if (!abc) {
            BusinessUnitsList.push({
              name: 'Deselect All',
              value: 'empty',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }
          if (!cde) {
            BusinessUnitsList.unshift({
              name: 'Select All',
              value: 'All',
            })
            setBusinessUnitsList(BusinessUnitsList)
          }

          let check_drop = selected.find((item: any) => item && item.value && item.value == 'All')
          let check_drop1 = selected.find(
            (item: any) => item && item.value && item.value == 'empty'
          )
          if (!check_drop && !check_drop1) {
            let test = BusinessUnitsList.filter(
              (id: any) => !selected.some((x: any) => x.name == id.name)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              BusinessUnitsList.shift({
                name: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setBusinessUnit(selected)
      BusinessUnitsList.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let businessReset = BusinessUnitsList.find((item: any) => item.value == 'All')
      if (!businessReset) {
        BusinessUnitsList.unshift({
          name: 'Select All',
          value: 'All',
        })
      }
    }
  }
  const handleConsultantType = (selected: any) => {
    if (selected.length > 0) {
      for (let each of selected) {
        if (each.value == 'All') {
          let x = AllOptions.filter((temp: any) => temp.value != 'All' && temp.value != 'empty')
          setSelectConsultant(x)
          AllOptions.shift({
            label: 'Select All',
            value: 'All',
          })
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            setAllOptions(AllOptions)
          }
        } else if (each.value == 'empty') {
          setSelectConsultant([])
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            // setAllOptions(AllOptions)
          }
          AllOptions.pop({
            label: 'Deselect All',
            value: 'empty',
          })
        } else {
          setSelectConsultant(selected)
          let abc = AllOptions.find((item: any) => item.value == 'empty')
          let cde = AllOptions.find((item: any) => item.value == 'All')
          if (!abc) {
            AllOptions.push({
              label: 'Deselect All',
              value: 'empty',
            })
            // setAllOptions(AllOptions)
          }
          if (!cde) {
            AllOptions.unshift({
              label: 'Select All',
              value: 'All',
            })
            setAllOptions(AllOptions)
          }
          let check_drop = selected.find((item: any) => item.value == 'All')
          let check_drop1 = selected.find((item: any) => item.value == 'empty')

          if (!check_drop && !check_drop1) {
            let test = AllOptions.filter(
              (id: any) => !selected.some((x: any) => x.value == id.value)
            )
            if (test.length == 2) {
              let sample = test.filter((res: any) => res.value == 'All' || res.value == 'empty')
              AllOptions.shift({
                label: 'Select All',
                value: 'All',
              })
            }
          }
        }
      }
    } else {
      setSelectConsultant(selected)
      AllOptions.pop({
        label: 'Deselect All',
        value: 'empty',
      })

      let consultantReset = AllOptions.find((item: any) => item.value == 'All')
      if (!consultantReset) {
        AllOptions.unshift({
          label: 'Select All',
          value: 'All',
        })
      }
    }
    // setSelectConsultant(selected)
  }

  const getMaxDate = (startDate: any) => {
    const today = new Date(startDate)
    today.setMonth(today.getMonth() + 1)
    today.setDate(0) // Set to the last day of the target month
    return today
  }

  const maxDate: any = React.useMemo(() => getMaxDate(dateRange && dateRange[0]), [dateRange])

  const handleDate = (update: any) => {
    setDateRange(update)
    if (update[0]) {
      if (update[1]) {
        setdateField(false)
      }
    }
  }

  const handleDateMulti = (date: any, val: any) => {
    // let allDates: any = getAllDatesInRange(date, true)
    if (date) {
      setDateRange(date)
      let monthYear: any = {
        d1: date[0]?.day < 10 ? '0' + date[0]?.day : date[0]?.day,
        d2: date[1]?.day < 10 ? '0' + date[1]?.day : date[1]?.day,
        // m1: date[0]?.month.number,
        // m1: date[0]?.month.name,
        // m1: moment(date[0]?.month.name, 'MMMM').format('MM'),
        // m2: date[1]?.month.number,
        // m2: date[1]?.month.name,
        // m2: moment(date[1]?.month.name, 'MMMM').format('MM'),
        m1: moment(date[0]?.month.name, 'MMMM').format('M'),
        m2: moment(date[1]?.month.name, 'MMMM').format('M'),
        M1: moment(date[0]?.month.name, 'MMMM').format('MM'),
        M2: moment(date[1]?.month.name, 'MMMM').format('MM'),
        y1: date[0]?.year,
        y2: date[1]?.year,
      }
      setDateValidation(false)
      setMonthYearDynamicallyChange(monthYear)
    } else {
      setDateRange([])
    }
  }

  return (
    <>
      <Modal show={show} fullscreen={fullscreen} onHide={() => handleClose()}>
        <Modal.Header closeButton className='p-3 bg-light'>
          <Modal.Title>Unbilled Report </Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-3 bg-light'>
          <div className='d-flex justify-content-between align-items-center'>
            <div> </div>
          </div>
          <BackDrop showBackDrop={showBackDrop} />
          <div className='py-1'>
            <div className='card card-custom card-flush '>
              <div className='card-body  py-1 px-5'>
                <div className='row gy-3 align-items-center py-2'>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>
                      Date Range * <span className='fs-9'>(DD-MMM-YYYY)</span>
                    </label>
                    {/* <DatePicker
                      // showYearDropdown
                      placeholderText='Select Date Range'
                      selectsRange={true}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat='dd-MMM-yyyy'
                      onChange={(update: any) => {
                        // setDateRange(update)
                        handleDate(update)
                      }}
                      maxDate={maxDate}
                      isClearable={true}
                      monthsShown={2}
                      className={clsx('form-control')}
                      // required
                    /> */}
                    <DatePicker
                      range
                      numberOfMonths={2}
                      dateSeparator=' - '
                      format='DD-MMM-YYYY'
                      // className={clsx('form-control')}
                      inputClass='form-control'
                      minDate={dateRange && dateRange[0]}
                      maxDate={maxDate}
                      placeholder='Select Date Range'
                      value={dateRange}
                      onChange={(dateObjects: any) => {
                        handleDateMulti(dateObjects, true)
                      }}
                    />
                    {DateValidation && (
                      <span className='error text-danger mb-0'>Date Range is required</span>
                    )}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Placement Type</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        handleConsultantType(selected)
                        // setSelectConsultant(selected)
                        // Keep the menu open when making multiple selections.
                        typeaheadRef2.current.toggleMenu()
                      }}
                      options={AllOptions}
                      placeholder='Select Placement Type'
                      ref={typeaheadRef2}
                      selected={selectConsultant}
                    />
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Business Unit</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        // setBusinessUnit(e)
                        handleBusinessUnit(e)
                        typeaheadRef7.current.toggleMenu()
                      }}
                      options={BusinessUnitsList}
                      ref={typeaheadRef7}
                      placeholder='Select Business Unit'
                      selected={BusinessUnit}
                      labelKey={(BusinessUnitsList: any) => BusinessUnitsList.name}
                      //   inputProps={{required: true}}
                    ></Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  {/* <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Project Type </label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(selected: any) => {
                        handleProjectType(selected)
                        typeaheadRef5.current.toggleMenu()
                      }}
                      options={AllProjectTypeOptions}
                      placeholder='Select Project Type'
                      ref={typeaheadRef5}
                      selected={selectProjectType}
                    />
                  </div>
               */}
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Employee Name</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      // disabled={isView}
                      onChange={(e) => {
                        selectedConsultant(e)
                        typeaheadRef3.current.toggleMenu()
                      }}
                      onInputChange={(e) => handleFilterConsultant(e)}
                      options={candidateList}
                      ref={typeaheadRef3}
                      placeholder='Search Employee'
                      selected={select_consultant}
                      labelKey={(candidateList: any) => user(candidateList.full_name)}
                      //   inputProps={{required: true}}
                    ></Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>
                  <div className='col-xs-12 col-md-3 col-lg'>
                    <label className='form-label '>Project Name</label>
                    <Typeahead
                      multiple
                      id='keep-menu-open'
                      onChange={(e) => {
                        selectedCountries1(e)
                        typeaheadRef4.current.toggleMenu()
                      }}
                      onInputChange={(e) => {
                        handleCountries1(e)
                      }}
                      placeholder='Please Enter Project Name'
                      ref={typeaheadRef4}
                      options={country1}
                      selected={countries1}
                      labelKey={(country1: any) => country1.project_name}
                    ></Typeahead>
                    {/* <div className='invalid-feedback'>{errors.last_name?.message}</div> */}
                  </div>

                  <div className='col-auto pt-md-8 pt-lg-8'>
                    <button
                      className='btn btn-sm btn-primary'
                      type='submit'
                      // onClick={() => handleClick1()}
                      onClick={() => handleClick1(page, perPage, false)}
                    >
                      Run
                    </button>
                  </div>
                  <div className='col-auto pt-md-0 pt-lg-8'>
                    <button
                      type='button'
                      className='btn btn-danger btn-sm cursor-pointer '
                      id='kt_toolbar_primary_button'
                      onClick={resetData}
                    >
                      Reset
                    </button>
                  </div>
                  <div className='col-auto pt-sm-0 pt-md-8'>
                    <button
                      // className='btn btn-icon btn-light  btn-active-color-primary btn-sm'
                      className='btn btn-light-primary   btn-sm'
                      onClick={exporthandleClick1}
                    >
                      Export
                      {/* <i className='bi bi-cloud-arrow-down-fill fs-2'></i> */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select any one and click run to view the report
            </div>
          )}
          {/* {flag == true && ( */}
          {flag == true ? (
            <>
              <div className='pt-3'>
                <div className='card card-custom card-flush '>
                  <div className='card-body p-3 py-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='text-gray-900 fs-5 fw-semibold text-transform-unset'>
                        Tabular Data{' '}
                      </div>
                      <div className='d-flex gap-3 '>
                        <div className='input-group mb-0'>
                          <input
                            type='text'
                            id='form1'
                            className='form-control smallTextField'
                            // value={search}
                            {...register('work_city')}
                            placeholder='Search'
                            onChange={(e) => handleChange1(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='table-responsive reports-table'>
                      <table
                        id='kt_table_users'
                        className='table table-row-bordered table-row-gray-300 gy-4 table-two-headers-sticky'
                      >
                        <>
                          <thead>
                            <tr className='fw-semibold fs-7 text-gray-600 text-uppercase'>
                              <th onClick={() => sorting5('placement_code')} className='  py-4'>
                                PLACEMENT CODE{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting5('project_status')} className='  py-4'>
                                Project Status{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting5('start_date')} className='  py-4'>
                                Start Date{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting5('start_date')} className='  py-4'>
                                End Date{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting6('employee_id')} className='  py-4'>
                                Unique ID{' '}
                                <span>
                                  {order6 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting5('project_name')} className='  py-4'>
                                Project Name{' '}
                                <span>
                                  {order5 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting6('placement_code')} className='  py-4'>
                                Placement Type{' '}
                                <span>
                                  {order6 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>

                              <th
                                onClick={() =>
                                  sorting8(
                                    'direct_customer_engagement',
                                    'end_customer',
                                    'client_name'
                                  )
                                }
                                className='  py-4'
                              >
                                Client Name{' '}
                                <span>
                                  {order8 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th
                                onClick={() => sorting6('user_business_unit_name')}
                                className='  py-4'
                              >
                                Business Unit{' '}
                                <span>
                                  {order6 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>

                              <th
                                onClick={() => sorting7('display_name', 'full_name')}
                                className='  py-4'
                              >
                                Consultant Name{' '}
                                <span>
                                  {order7 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>

                              <th
                                onClick={() => sorting7('display_name', 'full_name')}
                                className='  py-4'
                              >
                                QB Consultant Name{' '}
                                <span>
                                  {order7 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>

                              <th
                                onClick={() => sorting7('display_name', 'full_name')}
                                className='  py-4'
                              >
                                First Name{' '}
                                <span>
                                  {order7 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th
                                onClick={() => sorting7('display_name', 'full_name')}
                                className='  py-4'
                              >
                                Last Name{' '}
                                <span>
                                  {order7 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>

                              <th
                                onClick={() =>
                                  sorting8(
                                    'direct_customer_engagement',
                                    'end_customer',
                                    'client_name'
                                  )
                                }
                                className='  py-4'
                              >
                                Sub Vendor{' '}
                                <span>
                                  {order8 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting9('bill_rate')} className='  py-4'>
                                Pay Rate{' '}
                                <span>
                                  {order9 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting9('bill_rate')} className='  py-4'>
                                Bill Rate{' '}
                                <span>
                                  {order9 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>

                              <th onClick={() => sorting9('bill_rate')} className='  py-4'>
                                Expected hrs/day{' '}
                                <span>
                                  {order9 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting9('bill_rate')} className='  py-4'>
                                # working days{' '}
                                <span>
                                  {order9 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting9('bill_rate')} className='  py-4'>
                                Total Expected Hrs{' '}
                                <span>
                                  {order9 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting9('bill_rate')} className='  py-4'>
                                Expected Revenue{' '}
                                <span>
                                  {order9 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                              <th onClick={() => sorting9('bill_rate')} className='  py-4'>
                                Expected Pay{' '}
                                <span>
                                  {order9 === 'ASC' ? (
                                    <i className='bi bi-chevron-down ms-1 ' role='button'></i>
                                  ) : (
                                    <i className='bi bi-chevron-up ms-1 ' role='button'></i>
                                  )}
                                </span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {ProjectReportData?.length > 0 ? (
                              <>
                                {ProjectReportData?.map((each: any, index: any) => {
                                  return (
                                    <tr
                                      className='namepage text-dark fw-semibold  fs-6 '
                                      key={index}
                                    >
                                      <td className=''>
                                        {each.placement_code
                                          ? each.placement_code
                                          : each.employee_id}
                                      </td>
                                      <td className=''>
                                        {each.project_status
                                          ? each.project_status == 'IN_PROGRESS'
                                            ? 'In Progress'
                                            : each.project_status == 'INITIATED'
                                            ? 'Initiated'
                                            : each.project_status == 'CANCELLED'
                                            ? 'Cancelled'
                                            : each.project_status == 'COMPLETED'
                                            ? 'Completed'
                                            : each.project_status == 'REJECTED'
                                            ? 'Rejected'
                                            : '-'
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.start_date
                                          ? moment(each.start_date).format('DD-MMM-YYYY')
                                          : '-'}
                                      </td>
                                      <td className='text-nowrap'>
                                        {each.end_date
                                          ? moment(each.end_date).format('DD-MMM-YYYY')
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.placement_code
                                          ? each.placement_code
                                          : each.employee_id}
                                        {'_'}
                                        {/* {moment(startDate, ' DD/MM/YYYY').format('MMYYYY')} */}
                                        {MonthYearDynamicallyChange.M1}
                                        {MonthYearDynamicallyChange.y1}
                                      </td>
                                      <td className='text-capitalize'>
                                        {each.project_name ? each.project_name : '-'}
                                      </td>
                                      <td className=''>
                                        {each.placement_type ? each.placement_type : '-'}
                                      </td>

                                      <td className='text-capitalize'>
                                        {each.direct_customer_engagement == true
                                          ? each.end_customer_name
                                            ? each.end_customer_name
                                            : '-'
                                          : each.client_name
                                          ? each.client_name
                                          : '-'}
                                      </td>

                                      <td className=''>
                                        {each.user_business_unit_name
                                          ? each.user_business_unit_name
                                          : '-'}
                                      </td>

                                      <td className='text-capitalize'>
                                        {each.qb_consultant_name ? each.qb_consultant_name : '-'}
                                      </td>

                                      <td className='text-capitalize'>
                                        {each.display_name
                                          ? each.display_name
                                          : each.full_name
                                          ? each.full_name
                                          : '-'}
                                      </td>

                                      <td className='text-capitalize'>
                                        {each.first_name ? each.first_name : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {each.last_name ? each.last_name : '-'}
                                      </td>

                                      <td className='text-capitalize'>
                                        {/* {each.project_subvendors
                                          ? each.project_subvendors[0]?.subvendor_name
                                          : '-'} */}
                                        {each.subvendor_name ? each.subvendor_name : '-'}
                                      </td>
                                      <td className=''>
                                        {each.pay_rate}
                                        {each.pay_rate != 0 && each.is_pay_rate_same == false ? (
                                          <Tooltip text={'Please check bill rate details'}>
                                            <span>
                                              <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          ''
                                        )}
                                      </td>

                                      <td className=''>
                                        {each.bill_rate}
                                        {each.bill_rate != 0 && each.is_bill_rate_same == false ? (
                                          <Tooltip text={'Please check bill rate details'}>
                                            <span>
                                              <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          ''
                                        )}
                                      </td>

                                      <td className=''>
                                        {each.expected_hours_per_day
                                          ? each.expected_hours_per_day
                                          : '-'}
                                        {each.expected_hours_per_day != 0 &&
                                        each.is_same_expected_hours == false ? (
                                          <Tooltip text={'Please check expected hours details'}>
                                            <span>
                                              <i className='bi bi-info-lg  fs-4 bg-light-danger rounded-circle text-gray-700'></i>
                                            </span>
                                          </Tooltip>
                                        ) : (
                                          ''
                                        )}
                                      </td>
                                      <td className='text-capitalize'>
                                        {each.no_of_working_days ? each.no_of_working_days : '-'}
                                      </td>
                                      <td className='text-capitalize'>
                                        {each.project_working_hours
                                          ? each.project_working_hours
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.total_project_revenue
                                          ? '$' +
                                            Number(each.total_project_revenue).toLocaleString(
                                              undefined,
                                              {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              }
                                            )
                                          : '-'}
                                      </td>
                                      <td className=''>
                                        {each.expected_pay
                                          ? '$' +
                                            Number(each.expected_pay).toLocaleString(undefined, {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })
                                          : '-'}
                                      </td>
                                    </tr>
                                  )
                                })}
                              </>
                            ) : (
                              ''
                            )}
                          </tbody>
                        </>
                      </table>
                      {(ProjectReportData?.length == null || ProjectReportData?.length == 0) && (
                        <div className='text-center  fw-bold text-gray-800'>No Records found </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack py-2'>
                {/* {ProjectReportData?.length > 0 ? ( */}
                <div>
                  <Container className='p-0'>
                    <Row className='align-items-center'>
                      <Col xs='auto' className='pe-0'>
                        {' '}
                        <label className='form-label fw-semibold'>No. of projects</label>
                      </Col>

                      <Col>
                        <select
                          name='perPage'
                          className='form-select'
                          onChange={handleRowChange}
                          value={perPage}
                        >
                          {/* <option value='10'>10</option> */}
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                          <option value='75'>75</option>
                          <option value='100'>100</option>
                          {/* <option value={ProjectReportCountData}>All</option> */}
                        </select>
                      </Col>
                    </Row>
                  </Container>
                </div>
                {/* ) : null} */}

                <div>
                  {/* {ProjectReportData?.length > 0 ? ( */}
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    pageCount={pages}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-right'}
                    activeClassName={'active'}
                    breakLabel={'...'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    forcePage={page - 1}
                  />
                  {/* ) : null} */}
                </div>
              </div>
            </>
          ) : (
            ''
          )}
          {/* )} */}
          {/* {flag == false && (
            <div className='fs-6 text-gray-500 text-transform-unset'>
              Note: Please select any one and click run to view the result
            </div>
          )} */}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default UnBilledReport
