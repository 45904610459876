let host1 =
  window.location.hostname === 'timesheets-uat.msrcosmos.com'
    ? 'UAT'
    : window.location.hostname === 'timesheets.msrcosmos.com'
    ? 'Production'
    : 'development'

const BuildURL: any = {
  // Type: process.env.NODE_ENV, //Production OR Staging OR Local OR Development OR Integration OR IntegrationDev
  Environment: {
    Production: {
      IPADDRESS: 'https://timesheets.msrcosmos.com',
      TIMESHEET_URL: 'https://timesheets.msrcosmos.com/api/v1/',
      SEARCH_URL: 'https://timesheets.msrcosmos.com/api/v1/',
      TIMESHEETTHIRDPARTY_URL: 'https://onboarding.msrcosmos.com/api/user/login',
      TIMESHEETTHIRDPARTY_URL_UAT: 'https://onboarding-uat.msrcosmos.com/api/user',
      TIMESHEETTHIRDPARTY_URL_LOGOUT: 'https://onboarding.msrcosmos.com/api/',
      // TIMESHEETTHIRDPARTY_URL_CONFIG: 'https://onboarding.msrcosmos.com/api/app/config',
      TIMESHEETTHIRDPARTY_URL_CONFIG:
        'https://timesheets.msrcosmos.com/api/v1/maintenance_config/get_maintenance_config',
      TIMESHEETTHIRDPARTYCONTINUE_URL: 'https://onboarding.msrcosmos.com/api/user/login/continue',
      TOKEN_URL: 'https://timesheets.msrcosmos.com/api/v1/index/token',
      PLACEMENT_URL: 'https://placements.msrcosmos.com/placements/reasons/activeReasons',
      VERIFY_URL: 'https://onboarding.msrcosmos.com/api/userECDB/validateEmployee/',
      ADDUSER_URL: 'https://onboarding.msrcosmos.com/api/user/addUser',
      ADDUSER_Submit_URL: 'https://onboarding.msrcosmos.com/api/user/save',
      CHECKEMAIL_URL: 'https://onboarding.msrcosmos.com/api/list/checkEmail',
      ECDB_URL: 'https://onboarding.msrcosmos.com/api/user/updateUser',
      ECDB_User_Search_URL: 'https://onboarding.msrcosmos.com/api/ecdb/user/search',
      // ECDB_STATUS_URL: 'https://onboarding.msrcosmos.com/api/user/updateUserStatus',
      ECDB_STATUS_URL: 'https://onboarding.msrcosmos.com/api/userECDB/editUserStatus',
      TICKETING_TOOL_ACCESS_URL:
        'https://onboarding.msrcosmos.com/api/tools/getDetails/TicketingTool',
      VISA_URL: 'https://prospects.msrcosmos.com/prospective/country/listCountry',
      VISASTATUS_URL:
        'https://placements.msrcosmos.com/placements/work/authorization/workAuthorization/',
      USER_URL: 'https://qacorporateecdbv2.msrcosmos.com:14237/api/userECDB/editUserStatus',
      PROS_URL: ' https://prospects.msrcosmos.com/prospective/profile/updateCandidateHrms',
      SEARCHCANDIDATE_URL: ' https://prospects.msrcosmos.com/prospective/profile/search',
      UPDATESEARCHCANDIDATE_URL:
        'https://prospects.msrcosmos.com/prospective/candidate/search/orgId',
      ONBOARDING_URL: 'https://onboarding.msrcosmos.com/api/userECDB/validateEmployeeExternal',
      PLACEMENT_URL_GetPlacement:
        'https://placements.msrcosmos.com/placements/placements/getPlacement',
      Placement_Completed:
        'https://placements.msrcosmos.com/placements/placements/updatePlacementHrms',
      Placement_Cancel: 'https://placements.msrcosmos.com/placements/placements/cancel',
      PLACEMENT_URL_GetProjectByPlacementCode:
        'https://timesheets.msrcosmos.com/api/v1/projects2/get_project_by_placement_code',
      WIDGET_URL: 'https://ticketingwidget.msrcosmos.com',
    },
    UAT: {
      IPADDRESS: 'https://timesheets-uat.msrcosmos.com',
      TIMESHEET_URL: 'https://timesheets-uat.msrcosmos.com/api/v1/',
      SEARCH_URL: 'https://timesheets-uat.msrcosmos.com/api/v1/',
      TIMESHEETTHIRDPARTY_URL: 'https://onboarding-uat.msrcosmos.com/api/user/login',
      TIMESHEETTHIRDPARTY_URL_UAT: 'https://onboarding-uat.msrcosmos.com/api/user',
      NET_SUITE_TOKEN_URL: 'https://web-toolsintegration-uat.azurewebsites.net/api',
      TIMESHEETTHIRDPARTY_URL_LOGOUT: 'https://onboarding-uat.msrcosmos.com/api/',
      // TIMESHEETTHIRDPARTY_URL_CONFIG: 'https://onboarding-uat.msrcosmos.com/api/app/config',
      TIMESHEETTHIRDPARTY_URL_CONFIG:
        'https://timesheets-uat.msrcosmos.com/api/v1/maintenance_config/get_maintenance_config',
      TIMESHEETTHIRDPARTYCONTINUE_URL:
        'https://onboarding-uat.msrcosmos.com/api/user/login/continue',
      TOKEN_URL: 'https://timesheets-uat.msrcosmos.com/api/v1/index/token',
      PLACEMENT_URL: 'https://placements-uat.msrcosmos.com/placements/reasons/activeReasons',
      VERIFY_URL: 'https://onboarding-uat.msrcosmos.com/api/userECDB/validateEmployee/',
      ADDUSER_URL: 'https://onboarding-uat.msrcosmos.com/api/user/addUser',
      ADDUSER_Submit_URL: 'https://onboarding-uat.msrcosmos.com/api/user/save',
      CHECKEMAIL_URL: 'https://onboarding-uat.msrcosmos.com/api/list/checkEmail',
      ECDB_URL: 'https://onboarding-uat.msrcosmos.com/api/user/updateUser',
      ECDB_User_Search_URL: 'https://onboarding-uat.msrcosmos.com/api/ecdb/user/search',
      // ECDB_STATUS_URL: 'https://onboarding-uat.msrcosmos.com/api/user/updateUserStatus',
      ECDB_STATUS_URL: 'https://onboarding-uat.msrcosmos.com/api/userECDB/editUserStatus',
      TICKETING_TOOL_ACCESS_URL:
        'https://onboarding-uat.msrcosmos.com/api/tools/getDetails/TicketingTool',

      VISA_URL: 'https://prospects-uat.msrcosmos.com/prospective/country/listCountry',
      VISASTATUS_URL:
        'https://placements-uat.msrcosmos.com/placements/work/authorization/workAuthorization/',
      USER_URL: 'https://qacorporateecdbv2.msrcosmos.com:14237/api/userECDB/editUserStatus',
      PROS_URL: ' https://prospects-uat.msrcosmos.com/prospective/profile/updateCandidateHrms',
      SEARCHCANDIDATE_URL: ' https://prospects-uat.msrcosmos.com/prospective/profile/search',
      UPDATESEARCHCANDIDATE_URL:
        'https://prospects-uat.msrcosmos.com/prospective/candidate/search/orgId',
      ONBOARDING_URL: 'https://onboarding-uat.msrcosmos.com/api/userECDB/validateEmployeeExternal',
      PLACEMENT_URL_GetPlacement:
        'https://placements-uat.msrcosmos.com/placements/placements/getPlacement',
      Placement_Completed:
        'https://placements-uat.msrcosmos.com/placements/placements/updatePlacementHrms',
      Placement_Cancel: 'https://placements-uat.msrcosmos.com/placements/placements/cancel',
      PLACEMENT_URL_GetProjectByPlacementCode:
        'https://timesheets-uat.msrcosmos.com/api/v1/projects2/get_project_by_placement_code',
      WIDGET_URL: 'https://ticketingwidget-uat.msrcosmos.com',
    },
    development: {
      IPADDRESS: 'localhost',
      APPDEFAULTURL: 'timesheet',
      ISSECURE: false,
      PORT: 8897,
      EMAIL_SERVICE_END_POINT: 'http://192.168.92.253:8897',
      // SAP_CONNECTION_DETAILS: {
      //   END_POINT: "http://52.172.220.250:8087",
      // },
      AD_AUTHENTICATION: {
        CLIENT_ID: '0e4e7359-9efb-4eaa-b092-a4e79db15c65',
        TENANT_ID: '1a736ba6-2f25-4dae-96db-4d3aef897a60',
        // redirectURI: 'http://localhost:3000/freightforwarding/dashboard'
        redirectURI: 'http://localhost:3011metronic8/react/demo1/dashboard',
      },
      // MDM_URL: "https://qamdm.azurewebsites.net/masterDataOperationsapi",
      // PORT_URL: "https://portoperations.azurewebsites.net/portapi/v1/",
      // FRONTLINE_URL: "https://frontlinefunctions.azurewebsites.net/frontlinefunctions/"

      TIMESHEET_URL: 'http://192.168.92.253:8897/api/v1/',
      SEARCH_URL: 'http://192.168.92.253:8897/api/v1/',
      TIMESHEETTHIRDPARTY_URL: 'https://qacorporateecdb.msrcosmos.com:8080/api/user/login',
      NET_SUITE_TOKEN_URL: 'http://192.168.94.11:8080/api',
      TIMESHEETTHIRDPARTY_URL_LOGOUT: 'https://qacorporateecdb.msrcosmos.com:8080/api/',
      // TIMESHEETTHIRDPARTY_URL_CONFIG: 'https://qacorporateecdb.msrcosmos.com:8080/api/app/config',
      TIMESHEETTHIRDPARTY_URL_CONFIG:
        'http://192.168.92.253:8897/api/v1/maintenance_config/get_maintenance_config',

      TIMESHEETTHIRDPARTYCONTINUE_URL:
        'https://qacorporateecdb.msrcosmos.com:8080/api/user/login/continue',
      TOKEN_URL: 'http://192.168.92.253:8897/api/v1/index/token',
      PLACEMENT_URL:
        'https://qacorporateplacements.msrcosmos.com:8081/placements/reasons/activeReasons',
      VERIFY_URL: 'https://qacorporateecdb.msrcosmos.com:8080/api/userECDB/validateEmployee/',
      ADDUSER_URL: 'https://qacorporateecdb.msrcosmos.com:8080/api/user/addUser',
      ADDUSER_Submit_URL: 'https://qacorporateecdb.msrcosmos.com:8080/api/user/save',
      CHECKEMAIL_URL: 'https://qacorporateecdb.msrcosmos.com:8080/api/list/checkEmail',
      ECDB_URL: 'https://qacorporateecdb.msrcosmos.com:8080/api/user/updateUser',
      ECDB_User_Search_URL: 'https://qacorporateecdb.msrcosmos.com:8080/api/ecdb/user/search',
      // ECDB_STATUS_URL: 'https://qacorporateecdb.msrcosmos.com:8080/api/user/updateUserStatus',
      ECDB_STATUS_URL: 'https://qacorporateecdb.msrcosmos.com:8080/api/userECDB/editUserStatus',
      TICKETING_TOOL_ACCESS_URL:
        'https://qacorporateecdb.msrcosmos.com:8080/api/tools/getDetails/TicketingTool',
      VISA_URL: 'https://qacorporateprospects.msrcosmos.com:8080/prospective/country/listCountry',

      VISASTATUS_URL:
        'https://qacorporateplacements.msrcosmos.com:8081/placements/work/authorization/workAuthorization/',
      USER_URL: 'https://qacorporateecdb.msrcosmos.com:8080/api/userECDB/editUserStatus',
      PROS_URL:
        ' https://qacorporateprospects.msrcosmos.com:8080/prospective/profile/updateCandidateHrms',
      SEARCHCANDIDATE_URL:
        ' https://qacorporateprospects.msrcosmos.com:8080/prospective/profile/search',
      UPDATESEARCHCANDIDATE_URL:
        'https://qacorporateprospects.msrcosmos.com:8080/prospective/candidate/search/orgId',
      ONBOARDING_URL:
        'https://qacorporateecdb.msrcosmos.com:8080/api/userECDB/validateEmployeeExternal',
      PLACEMENT_URL_GetPlacement:
        'https://qacorporateplacements.msrcosmos.com:8081/placements/placements/getPlacement',
      Placement_Completed:
        'https://qacorporateplacements.msrcosmos.com:8081/placements/placements/updatePlacementHrms',
      Placement_Cancel:
        'https://qacorporateplacements.msrcosmos.com:8081/placements/placements/cancel',
      PLACEMENT_URL_GetProjectByPlacementCode:
        'http://192.168.92.253:8897/api/v1/projects2/get_project_by_placement_code',
      WIDGET_URL: 'https://ticketingwidget-uat.msrcosmos.com',
    },
  },
  getServiceURL: function () {
    var SERVICEADDRESS
    var port = BuildURL.Environment[host1].PORT
    var IPADDRESS = BuildURL.Environment[host1].IPADDRESS
    var isSecure = BuildURL.Environment[host1].ISSECURE
    var httpText

    if (isSecure) httpText = 'https://'
    else httpText = 'http://'

    if (port !== '') {
      //HOSTADDRESS =  'http://' + IPADDRESS + ':' + PORT + '/' + APPDEFAULTURL;
      SERVICEADDRESS =
        httpText + IPADDRESS + ':' + port + '/' + BuildURL.Environment[host1].APPDEFAULTURL
    } else {
      //HOSTADDRESS = 'http://' + IPADDRESS + '/' + APPDEFAULTURL;
      SERVICEADDRESS = httpText + IPADDRESS + '/' + BuildURL.Environment[host1].APPDEFAULTURL
    }
    return SERVICEADDRESS
  },
  // getEmailServiceEndpoint: function () {
  //     return BuildURL.Environment[BuildURL.Type].EMAIL_SERVICE_END_POINT;
  // },
  // getSapServiceEndpointConfig: function () {
  //     return BuildURL.Environment[BuildURL.Type].SAP_CONNECTION_DETAILS;
  // },
  // geAdAuthConfig: function () {
  //     return BuildURL.Environment[BuildURL.Type].AD_AUTHENTICATION;
  // },
  // getMasterServiceURL: function () {
  //     return BuildURL.Environment[BuildURL.Type].MDM_URL;
  // },
  // getPortServiceURL: function () {
  //     return BuildURL.Environment[BuildURL.Type].PORT_URL;
  // },
  getTimesheetUatURL: function () {
    return BuildURL.Environment[host1].AZURE_UAT_URL
  },
  getTimesheetServiceURL: function () {
    return BuildURL.Environment[host1].TIMESHEET_URL
  },
  getEmployeeServiceURL: function () {
    return BuildURL.Environment[host1].SEARCH_URL
  },
  getTimesheetServicethirdpartyURL: function () {
    return BuildURL.Environment[host1].TIMESHEETTHIRDPARTY_URL
  },
  getTimesheetServiceUatthirdpartyURL: function () {
    return BuildURL.Environment[host1].TIMESHEETTHIRDPARTY_URL_UAT
  },
  getNetSuiteServiceTokenURL: function () {
    return BuildURL.Environment[host1].NET_SUITE_TOKEN_URL
  },
  getTicketingWidgetURL: function () {
    return BuildURL.Environment[host1].WIDGET_URL
  },

  getTimesheetServicethirdpartyURLLogout: function () {
    return BuildURL.Environment[host1].TIMESHEETTHIRDPARTY_URL_LOGOUT
  },

  getTimesheetServicethirdpartyURLConfig: function () {
    return BuildURL.Environment[host1].TIMESHEETTHIRDPARTY_URL_CONFIG
  },

  getTimesheetServicethirdpartycontinueURL: function () {
    return BuildURL.Environment[host1].TIMESHEETTHIRDPARTYCONTINUE_URL
  },
  getTokenURL: function () {
    return BuildURL.Environment[host1].TOKEN_URL
  },
  getPlacementURL: function () {
    return BuildURL.Environment[host1].PLACEMENT_URL
  },
  getPLACEMENT_URL_GetPlacement: function () {
    return BuildURL.Environment[host1].PLACEMENT_URL_GetPlacement
  },

  getPlacement_Completed: function () {
    return BuildURL.Environment[host1].Placement_Completed
  },
  getPlacement_Cancel: function () {
    return BuildURL.Environment[host1].Placement_Cancel
  },

  getPLACEMENT_URL_GetProjectByPlacementCode: function () {
    return BuildURL.Environment[host1].PLACEMENT_URL_GetProjectByPlacementCode
  },

  getVerifyURL: function () {
    return BuildURL.Environment[host1].VERIFY_URL
  },
  getAddUserURL: function () {
    return BuildURL.Environment[host1].ADDUSER_URL
  },
  getAddUserSubmitURL: function () {
    return BuildURL.Environment[host1].ADDUSER_Submit_URL
  },

  getCheckEmailURL: function () {
    return BuildURL.Environment[host1].CHECKEMAIL_URL
  },
  getOnBoardingURL: function () {
    return BuildURL.Environment[host1].ONBOARDING_URL
  },
  getECDBURL: function () {
    return BuildURL.Environment[host1].ECDB_URL
  },
  getECDBUSERSEARCHURL: function () {
    return BuildURL.Environment[host1].ECDB_User_Search_URL
  },
  getECDBSTATUSURL: function () {
    return BuildURL.Environment[host1].ECDB_STATUS_URL
  },
  getTICKETINGTOOLACCESSURL: function () {
    return BuildURL.Environment[host1].TICKETING_TOOL_ACCESS_URL
  },

  getUSERURL: function () {
    return BuildURL.Environment[host1].USER_URL
  },
  getVISAURL: function () {
    return BuildURL.Environment[host1].VISA_URL
  },
  getVISASTATUSURL: function () {
    return BuildURL.Environment[host1].VISASTATUS_URL
  },
  getPROSURL: function () {
    return BuildURL.Environment[host1].PROS_URL
  },
  getSearchCandidateURL: function () {
    return BuildURL.Environment[host1].SEARCHCANDIDATE_URL
  },

  getUpdateSearchCandidateURL: function () {
    return BuildURL.Environment[host1].UPDATESEARCHCANDIDATE_URL
  },

  // getPROSURLSEARCH: function () {
  //   return BuildURL.Environment[BuildURL.Type].PROS_URL_EMAIL_SEARCH_LOCAL
  // },
}

export default {BuildURL}
